import React, { useContext, useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import {
  PlusIcon,
  TrashIcon,
  PrinterIcon,
  DuplicateIcon,
  EyeIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  PencilIcon,
  QrcodeIcon,
} from "@heroicons/react/outline";
import CustomPopover from "#newUiComponents/commons/CustomPopover";
import PrimaryButton from "#newUiComponents/commons/PrimaryButton";
import { AppStateContext } from "#contexts/appState";
import CustomNotification from "#newUiComponents/commons/CustomNotification";
import SlideOverPanel from "#components/common/SlideOverPanel";
import HeaderWithArrow from "#newUiComponents/commons/HeaderWithArrow";
import AddItemsInBox from "./AddItemsInBox";
import CustomPopconfirm from "#newUiComponents/commons/CustomPopConfirm";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import ViewPackedItems from "./ViewPackedItems";
import EditBoxItems from "./EditBoxItems";
import NewModal from "#newUiComponents/commons/NewModal";
import { print2DBarcodeLabel } from "./print2DBarcodeLabel";
import { TENANT_SHIPPING_LABEL_PRINTER_ENUM } from "#constants/printer-settings";
import {
  useDimensionUnitAbbreviation,
  useWeightUnitAbbreviation,
} from "#utils/Metrics";
import CustomAlert from "#newUiComponents/commons/CustomAlert";
import {
  confirmPackItem,
  packItems,
  scanBarcode,
  setPackerBoxes,
  unPackBox,
  unPackItems,
  duplicateBox,
} from "#redux/FbaPacking/fbaPackingActions";
import { useDispatch } from "react-redux";
import { Popover, Tooltip } from "antd";
import CustomSwitch from "#newUiComponents/commons/CustomSwitch";

const FBA_BOX_WEIGHT_LIMIT = 50;
const FBA_BOX_MECHANICAL_LIFT_WARNING_WEIGHT_LIMIT = 100;

const PackingBoxInformation = ({
  packingOptions,
  order,
  selectedPackingOption,
  boxTypes,
  isLtlFlow,
  currentBatch,
  getCurrentBatch,
  formattedGroups,
  setFormattedGroups,
}) => {
  const appState = useContext(AppStateContext);
  const dispatch = useDispatch();
  const notify = CustomNotification();

  const preferredDimensionUnit = useDimensionUnitAbbreviation() || "in";
  const preferredWeightUnit = useWeightUnitAbbreviation() || "lbs";

  const [expandedGroups, setExpandedGroups] = useState([]);
  const [expandedBoxes, setExpandedBoxes] = useState({});
  const [isDymoEnabled, setIsDymoEnabled] = useState(true); // Enabled by default
  //   const [formattedGroups, setFormattedGroups] = useState([]);
  const [originalFormattedGroups, setOriginalFormattedGroups] = useState([]);
  const [groupSelectedToManualAddItems, setGroupSelectedToManualAddItems] =
    useState(null);
  const [
    openManualAddItemsSlideOverPanel,
    setOpenManualAddItemsSlideOverPanel,
  ] = useState(false);
  const [openViewItemsSlideOverPanel, setOpenViewItemsSlideOverPanel] =
    useState(false);
  const [entitySelectedForViewItems, setEntitySelectedForViewItems] = useState({
    header: "",
    description: "",
    entity: "",
    items: [],
  });
  const [openEditBoxItemsPanel, setOpenEditBoxItemsPanel] = useState(false);
  const [selectedBoxForEdit, setSelectedBoxForEdit] = useState(null);
  const [isBoxDimensionsModalOpen, setIsBoxDimensionsModalOpen] =
    useState(false);
  const [isScannerModalOpen, setIsScannerModalOpen] = useState(false);
  const [boxDimensionsDetails, setBoxDimensionsDetails] = useState({
    height: "",
    width: "",
    length: "",
  });
  const [weightErrors, setWeightErrors] = useState({});
  const [dimensionErrors, setDimensionErrors] = useState({
    length: "",
    width: "",
    height: "",
  });
  const [scannedItem, setScannedItem] = useState(null);
  const [scannerQuantity, setScannerQuantity] = useState(1);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedBox, setSelectedBox] = useState(null);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [boxToDuplicate, setBoxToDuplicate] = useState(null);
  const [duplicateQuantity, setDuplicateQuantity] = useState(1);
  const [activePopover, setActivePopover] = useState(null); // Tracks the open popover by groupName
  const [activeEditPopover, setActiveEditPopover] = useState(null); // Tracks the active Edit Popover

  // Transform workingList to formatted groups
  const transformWorkingListToGroups = (
    workingList,
    currentBoxes,
    packingOptions,
  ) => {
    if (!workingList?.length || !packingOptions?.length) return [];
    const selectedOption = packingOptions.find((option) => {
      if (isLtlFlow) return option.placementOptionId === selectedPackingOption;
      else return option.packingOptionId === selectedPackingOption;
    });

    if (!selectedOption) return [];

    // Keep track of used items to avoid duplicates
    let usedItemIds = new Set();
    const groups = (
      isLtlFlow
        ? selectedOption.shipmentsWithBoxesAndItems
        : selectedOption.packingGroupsWithGroupItems
    ).map((packingGroup, index) => {
      const groupName = `Group-${index + 1}`;

      // First group workingList items by SKU
      const groupedWorkingItems = workingList.reduce((acc, item) => {
        // Skip if item is already used in another group
        if (usedItemIds.has(item.id)) return acc;

        // Check if this item belongs to this group
        const belongsToGroup = packingGroup.items.some(
          (groupItem) =>
            groupItem.msku === item.sku || groupItem.sku === item.sku,
        );

        if (!belongsToGroup) return acc;

        const packingGroupItem = packingGroup.items.find(
          (groupItem) =>
            groupItem.msku === item.sku || groupItem.sku === item.sku,
        );

        const targetQuantity = packingGroupItem.quantity;

        const key = item.sku;
        if (!acc[key]) {
          acc[key] = {
            groupedItem: {
              ...item,
              asin: item.asin,
              msku: packingGroupItem.msku,
              sku: item.sku,
              fnsku: item.fnSku,
              name: item.productName,
              image: packingGroupItem?.image,
              prepInstructions: packingGroupItem?.prepInstructions,
              quantity: 0,
              totalQuantity: 0,
              confirmedQuantity: 0,
              items: [],
              packingGroupItem,
            },
            individualItems: [],
            currentCount: 0,
          };
        }

        // Only add item if we haven't reached the target quantity
        if (acc[key].currentCount < targetQuantity) {
          acc[key].individualItems.push({
            ...item,
            image: packingGroupItem?.image,
            prepInstructions: packingGroupItem?.prepInstructions,
            msku: packingGroupItem.msku,
            packingGroupItem,
          });

          if (item.status === "UNPROCESSED") {
            acc[key].groupedItem.quantity += 1;
          }
          acc[key].groupedItem.totalQuantity += 1;
          if (item.status === "CONFIRMED") {
            acc[key].groupedItem.confirmedQuantity += 1;
          }
          acc[key].groupedItem.items.push(item);
          acc[key].currentCount += 1;
          usedItemIds.add(item.id);
        }

        return acc;
      }, {});

      // Get boxes for this group
      currentBoxes = currentBoxes || [];
      const groupBoxes = currentBoxes
        .filter((box) => box.name.split("_")[0] === groupName)
        .map((box) => {
          const boxItems = workingList.filter(
            (item) => item.boxName === box.name && item.status === "CONFIRMED",
          );

          return {
            boxName: box.name.split("_")[1],
            items: boxItems.map((item) => {
              const itemInPackingGroup = packingGroup.items.find(
                (groupItem) => groupItem.msku === item.sku,
              );
              return {
                ...item,
                image: itemInPackingGroup?.image,
                msku: itemInPackingGroup?.msku,
                prepInstructions: itemInPackingGroup?.prepInstructions,
                itemInPackingGroup,
              };
            }),
            dimensions: {
              length: box.length || 0,
              width: box.width || 0,
              height: box.height || 0,
            },
            weight: box.weight || box.maxWeight || 0,
            assignedQuantity: boxItems.length,
            source: "BOX_CONTENT_PROVIDED",
          };
        });

      // Convert grouped items to array format
      const unassignedItems = Object.values(groupedWorkingItems).map(
        ({ groupedItem }) => ({
          ...groupedItem,
          quantity: groupedItem.quantity,
          asin: groupedItem.asin,
          msku: groupedItem.msku,
          fnsku: groupedItem.fnsku,
          name: groupedItem.name,
          image: groupedItem.image,
          id: groupedItem.id,
          originalItems: groupedItem.items,
        }),
      );

      // In transformWorkingListToGroups function, modify the return statement:
      return {
        groupName,
        totalItems: Object.values(groupedWorkingItems).reduce(
          (sum, { groupedItem }) => sum + groupedItem.totalQuantity,
          0,
        ),
        items: unassignedItems.filter((item) => item.quantity > 0),
        packingGroupId: isLtlFlow
          ? packingGroup.shipmentId
          : packingGroup.packingGroupId,
        assignedItemsNo: Object.values(groupedWorkingItems).reduce(
          (sum, { groupedItem }) => sum + groupedItem.confirmedQuantity,
          0,
        ),
        assignedItems: Object.values(groupedWorkingItems).flatMap(
          ({ individualItems }) =>
            individualItems.filter((item) => item.status === "CONFIRMED"),
        ),
        // Sort boxes before assigning to ensure consistent order
        boxes: sortBoxesByNumber(groupBoxes),
        currentSource: "BOX_CONTENT_PROVIDED",
      };
    });

    return groups;
  };

  const generateBoxName = useCallback((group) => {
    const existingNumbers = group.boxes
      .map((box) => {
        const match = box.boxName.match(/^Box-(\d+)$/);
        return match ? parseInt(match[1]) : 0;
      })
      .filter((num) => !isNaN(num));

    let newNumber = 1;
    while (existingNumbers.includes(newNumber)) {
      newNumber++;
    }

    return `Box-${newNumber}`;
  }, []);

  // Handler for adding new box
  const handleBoxInGroups = async (type, group, allItemsCanFitIn = false) => {
    const newBoxName = generateBoxName(group);
    const fullBoxName = `${group.groupName}_${newBoxName}`;
    // Get existing boxes but ensure no duplicates by using names as unique identifiers
    const boxesToUse = currentBatch.boxes || [];
    const existingBoxes = Array.from(
      new Set(boxesToUse.map((box) => box.name)),
    ).map((name) => {
      const box = boxesToUse.find((b) => b.name === name);
      return {
        name: box.name,
        height: box.height,
        width: box.width,
        length: box.length,
        weight: box.maxWeight || box.weight,
      };
    });

    const variables = {
      id: currentBatch.id,
      boxes: [
        ...existingBoxes,
        {
          name: fullBoxName,
          height: type.height,
          width: type.width,
          length: type.length,
          weight: 0,
        },
      ],
      orderId: order.id,
      commodityContent: [],
    };

    try {
      await dispatch(
        setPackerBoxes({
          variables,
          successCallback: async (response) => {
            if (response?.message?.toLowerCase().includes("success")) {
              const batchResponse = await getCurrentBatch();
              if (batchResponse?.data?.getCurrentPackerBatch) {
                notify.success("Box added successfully");
                if (allItemsCanFitIn) {
                  const originalUnprocessedItems =
                    group.items
                      .map((item) => item.originalItems || [])
                      ?.flat()
                      .filter((i) => i?.status === "UNPROCESSED") || [];

                  const itemsToAdd = originalUnprocessedItems.map((item) => ({
                    id: item.id,
                    quantity: 1,
                  }));

                  handleManualItemAddition(itemsToAdd, group, -1, {
                    boxName: fullBoxName.split("_")[1],
                    height: type.height,
                    width: type.width,
                    length: type.length,
                    maxWeight: type.maxWeight || 50,
                  });
                }
              }
            }
          },
          errorCallback: (error) => {
            notify.error(error?.message || "Failed to add box");
          },
        }),
      );
    } catch (error) {
      notify.error("Failed to add box");
    }
  };

  const sortBoxesByNumber = (boxes) => {
    return [...boxes].sort((a, b) => {
      const aNumber = parseInt(a.boxName.match(/Box-(\d+)/)[1]);
      const bNumber = parseInt(b.boxName.match(/Box-(\d+)/)[1]);
      return aNumber - bNumber;
    });
  };

  // Handler for updating the box

  const handleUpdateBoxDimensions = async (group, box, newDimensions) => {
    const remainingBoxes = currentBatch.boxes.filter(
      (b) => b.name !== `${group.groupName}_${box.boxName}`,
    );

    const variables = {
      id: currentBatch.id,
      boxes: [
        ...remainingBoxes.map((b) => ({
          name: b.name,
          height: b.height,
          width: b.width,
          length: b.length,
          weight: b.weight || b.maxWeight,
        })),
        {
          name: `${group.groupName}_${box.boxName}`,
          height: newDimensions.height,
          width: newDimensions.width,
          length: newDimensions.length,
          weight: box.weight || box.maxWeight,
        },
      ],
      orderId: order.id,
      commodityContent: [],
    };

    try {
      await dispatch(
        setPackerBoxes({
          variables,
          successCallback: async (response) => {
            if (response?.message?.toLowerCase().includes("success")) {
              const batchResponse = await getCurrentBatch();
              if (batchResponse?.data?.getCurrentPackerBatch) {
                notify.success("Box dimensions updated successfully");
              }
            }
          },
          errorCallback: (error) => {
            notify.error(error?.message || "Failed to update box dimensions");
          },
        }),
      );
    } catch (error) {
      notify.error("Failed to update box dimensions");
    }
  };
  const getNextAvailableBoxNumber = (existingBoxes, startingNumber = 1) => {
    // Extract all existing box numbers
    const existingNumbers = existingBoxes
      .map((box) => {
        const match = box.boxName.match(/^Box-(\d+)$/);
        return match ? parseInt(match[1]) : 0;
      })
      .filter((num) => !isNaN(num));

    const sortedNumbers = [...existingNumbers].sort((a, b) => a - b);

    // Find the first available number starting from startingNumber
    let nextNumber = startingNumber;
    while (sortedNumbers.includes(nextNumber)) {
      nextNumber++;
    }

    return nextNumber;
  };

  const generateMultipleBoxNames = (group, count) => {
    const newBoxNames = [];
    let currentNumber = 1;

    const existingBoxes = group.boxes || [];

    for (let i = 0; i < count; i++) {
      // Get the next available number for each new box
      const nextNumber = getNextAvailableBoxNumber(
        existingBoxes.concat(newBoxNames),
        currentNumber,
      );
      const newBoxName = `Box-${nextNumber}`;
      newBoxNames.push({ boxName: newBoxName });
      currentNumber = nextNumber + 1;
    }

    return newBoxNames;
  };

  const handleDuplicateBox = async (group, boxToDuplicate) => {
    // Generate unique box names for all duplicates
    const newBoxes = generateMultipleBoxNames(group, duplicateQuantity);
    const newBoxNames = newBoxes.map(
      (box) => `${group.groupName}_${box.boxName}`,
    );
    const sourceBoxFullName = `${group.groupName}_${boxToDuplicate.boxName}`;

    const existingBoxes = currentBatch.boxes || [];

    const setBoxVariables = {
      id: currentBatch.id,
      boxes: [
        ...existingBoxes.map((box) => ({
          name: box.name,
          height: box.height,
          width: box.width,
          length: box.length,
          weight:
            (group?.boxes || [])?.find(
              (b) => `${group.groupName}_${b.boxName}` === box.name,
            )?.weight || 0,
        })),
      ],
      orderId: order.id,
      boxDataToDuplicate: {
        id: currentBatch.id,
        sourceBoxName: sourceBoxFullName,
        destinationBoxes: newBoxNames,
      },
      commodityContent: [],
    };

    try {
      await dispatch(
        setPackerBoxes({
          variables: setBoxVariables,
          successCallback: async () => {
            const duplicateVariables = {
              id: currentBatch.id,
              sourceBoxName: sourceBoxFullName,
              destinationBoxes: newBoxNames,
            };

            await dispatch(
              duplicateBox({
                variables: duplicateVariables,
                successCallback: async () => {
                  await getCurrentBatch();
                  notify.success("Box duplicated successfully");
                },
                errorCallback: (error) => {
                  notify.error(
                    error?.message || "Failed to duplicate box contents",
                  );
                },
              }),
            );
          },
          errorCallback: (error) => {
            notify.error(error?.message || "Failed to create duplicate box");
          },
        }),
      );
    } catch (error) {
      notify.error("Failed to duplicate box");
    }
  };
  // Handler for manually adding items
  const handleManualItemAddition = async (
    selectedItems,
    group,
    boxIndex,
    boxDetailsForAllItemsFitIn = null,
  ) => {
    const box = boxDetailsForAllItemsFitIn || group.boxes[boxIndex];
    const boxFullName = `${group.groupName}_${box.boxName}`;

    // Use packItems API instead of confirmPackItem
    const variables = {
      id: currentBatch.id,
      boxName: boxFullName,
      items: selectedItems.map((item) => ({
        id: item.id,
        quantity: item.quantity,
      })),
    };

    try {
      await dispatch(
        packItems({
          variables,
          successCallback: async () => {
            await getCurrentBatch();
            notify.success("Items added successfully");
          },
          errorCallback: (error) => {
            notify.error(error?.message || "Failed to pack items");
          },
        }),
      );
    } catch (error) {
      notify.error("Failed to pack items");
    }
  };
  // Handler for unpacking items
  const handleUnpackItems = async (items, group) => {
    const variables = {
      id: currentBatch.id,
      unPackItems: items.map((item) => ({
        id: item.id,
      })),
    };

    if (items.length) {
      try {
        await dispatch(
          unPackItems({
            variables,
            successCallback: async () => {
              await getCurrentBatch();
              notify.success("Items unpacked successfully");
            },
            errorCallback: (error) => {
              notify.error(error?.message || "Failed to unpack items");
            },
          }),
        );
      } catch (error) {
        notify.error("Failed to unpack items");
      }
    }
  };
  // Handler for unpacking all boxes in a group
  const handleUnpackAllBoxes = async (group) => {
    const allItems = group.boxes.flatMap((box) => box.items);
    if (allItems.length > 0) {
      await handleUnpackItems(allItems, group);
    } else {
      notify.warning("No items to unpack");
    }
  };

  // Handler for deleting a box
  const handleDeleteBox = async (group, box) => {
    // First unpack all items from the box
    await handleUnpackItems(box.items, group);

    // Get all boxes except the one being deleted
    const remainingBoxes = currentBatch.boxes.filter(
      (b) => b.name !== `${group.groupName}_${box.boxName}`,
    );

    const variables = {
      id: currentBatch.id,
      boxes: remainingBoxes.map((box) => ({
        name: box.name,
        height: box.height,
        width: box.width,
        length: box.length,
        maxWeight: box.maxWeight,
      })),
      orderId: order.id,
      commodityContent: [],
    };

    try {
      await dispatch(
        setPackerBoxes({
          variables,
          successCallback: async () => {
            await getCurrentBatch();
            notify.success(`Box "${box.boxName}" has been deleted.`);
          },
          errorCallback: (error) => {
            notify.error(error?.message || "Failed to delete box");
          },
        }),
      );
    } catch (error) {
      notify.error("Failed to delete box");
    }
  };
  // Handler for updating box weight
  const handleBoxWeightChange = (group, box, newWeight) => {
    setFormattedGroups((prevGroups) =>
      prevGroups.map((prevGroup) => {
        if (prevGroup.groupName !== group.groupName) return prevGroup;

        const updatedBoxes = prevGroup.boxes.map((prevBox) => {
          if (prevBox.boxName !== box.boxName) return prevBox;

          return {
            ...prevBox,
            weight: parseFloat(newWeight) || 0,
          };
        });

        return {
          ...prevGroup,
          boxes: updatedBoxes,
        };
      }),
    );
  };
  // Handler for scanner input
  const handleScannerInput = async (e, group, box, boxIndex) => {
    if (e.key === "Enter") {
      const scannedValue = e.target.value.trim();

      try {
        await dispatch(
          scanBarcode({
            variables: { code: scannedValue },
            successCallback: async (data) => {
              notify.success(data.message);
              const response = await getCurrentBatch();

              if (response?.data?.getCurrentPackerBatch?.currentItem) {
                const currentItem =
                  response.data.getCurrentPackerBatch.currentItem;
                const groupItem = group.items.find(
                  (item) =>
                    item.fnSku === currentItem.fnSku ||
                    item.asin === currentItem.asin ||
                    item.upc === currentItem.upc ||
                    item.msku === currentItem.sku,
                );

                if (!groupItem || groupItem.quantity === 0) {
                  notify.warning(
                    "Item not found or no remaining quantity available",
                  );
                  return;
                }

                setScannedItem(groupItem);
                setSelectedGroup(group);
                setSelectedBox({ ...box, boxIndex });
                setScannerQuantity(1);
                setIsScannerModalOpen(true);
                e.target.value = "";
              }
            },
            errorCallback: (error) => {
              notify.error(error);
            },
          }),
        );
      } catch (error) {
        notify.error("Failed to process scan");
      }
    }
  };
  // Handler for scanner submission
  const handleScannerSubmit = async () => {
    if (!scannedItem || !selectedGroup || !selectedBox) return;

    if (scannerQuantity > scannedItem.quantity) {
      notify.warning("Selected quantity exceeds available quantity");
      return;
    }
    // Find the original unprocessed items for the scanned item
    const originalUnprocessedItems = (
      selectedGroup.items
        .find(
          (item) =>
            item.msku === scannedItem.msku || item.sku === scannedItem.sku,
        )
        ?.originalItems?.filter((i) => i?.status === "UNPROCESSED") || []
    ).slice(0, scannerQuantity);

    const itemsToAdd = originalUnprocessedItems.map((item) => ({
      id: item.id,
      quantity: 1,
    }));

    await handleManualItemAddition(
      itemsToAdd,
      selectedGroup,
      selectedBox.boxIndex,
    );

    setIsScannerModalOpen(false);
  };
  // Box type handling
  const handleBoxTypes = (group) => {
    return boxTypes?.map((type) => ({
      name: `${type.name} ${type.length} * ${type.width} * ${type.height} (in)`,
      icon: "",
      onClick: () => handleFitInOneBoxConfirmation(type, group),
    }));
  };

  // Custom box addition handling
  const handleAddCustomBox = (group) => {
    setSelectedGroup(group);
    setBoxDimensionsDetails({ height: "", width: "", length: "" });
    setIsBoxDimensionsModalOpen(true);
  };

  const handleDimensionsSubmit = () => {
    if (
      !boxDimensionsDetails.height ||
      !boxDimensionsDetails.length ||
      !boxDimensionsDetails.width
    ) {
      notify.warning("Please enter all dimensions");
      return;
    }

    handleFitInOneBoxConfirmation(
      {
        name: "CustomBoxAdded",
        ...boxDimensionsDetails,
      },
      selectedGroup,
    );
    setIsBoxDimensionsModalOpen(false);
  };

  // Box fit confirmation handling
  const handleFitInOneBoxConfirmation = (type, group) => {
    if (type.name === "Add Custom") {
      handleAddCustomBox(group);
      return;
    }

    const totalQuantityRemaining = group.items.reduce(
      (sum, item) => sum + item.quantity,
      0,
    );

    if (group.boxes.length === 0 && totalQuantityRemaining > 1) {
      appState.showNewConfirmation(
        "Will all these Items fit into 1 box?",
        "",
        () => {
          handleBoxInGroups(type, group);
        },
        () => {
          handleBoxInGroups(type, group, true);
        },
        "Yes, all items can be added into one box",
        "No, Multiple Boxes will be needed",
      );
    } else {
      handleBoxInGroups(type, group);
    }
  };

  // Source change handling
  const handleSourceChange = (group, newSource) => {
    setFormattedGroups((prevGroups) =>
      prevGroups.map((prevGroup) => {
        if (prevGroup.groupName !== group.groupName) return prevGroup;

        const updatedBoxes = prevGroup.boxes.map((box) => ({
          ...box,
          source: newSource,
        }));

        return {
          ...prevGroup,
          currentSource: newSource,
          boxes: updatedBoxes,
        };
      }),
    );
  };

  const toggleGroup = (groupName) => {
    setExpandedGroups((prev) => {
      if (prev.includes(groupName)) {
        setExpandedBoxes({});
        return [];
      }

      const group = formattedGroups.find((g) => g.groupName === groupName);
      if (group && group.boxes.length > 0) {
        const firstBox = sortBoxesByNumber(group.boxes)[0];
        setExpandedBoxes({
          [groupName]: [firstBox.boxName],
        });
      }

      return [groupName];
    });
  };
  // Box expansion handling
  const toggleBox = (groupName, boxName) => {
    setExpandedBoxes((prev) => {
      if (prev[groupName]?.includes(boxName)) {
        return {
          ...prev,
          [groupName]: [],
        };
      }
      return {
        ...prev,
        [groupName]: [boxName],
      };
    });
  };

  const calculateTotalWeight = (boxes) => {
    return boxes
      .reduce((sum, box) => sum + (parseFloat(box.weight) || 0), 0)
      .toFixed(2);
  };

  const print2DBarcodeBoxLabel = (
    group,
    boxName = null,
    download = false,
    isProvisional = false,
  ) => {
    const boxes = boxName
      ? [group.boxes.find((item) => item.boxName === boxName)]
      : group.boxes;
    boxes.forEach((box) => {
      if (box) {
        if (!box.weight || box.weight === 0) {
          notify.warning(
            "Please enter the box weight before printing the labels.",
          );
          return;
        }
        const groupedItemsByFnSku = box.items.reduce((acc, item) => {
          if (!acc[item.fnsku]) {
            acc[item.fnsku] = {
              asin: item.itemInPackingGroup.asin,
              fnSku: item.fnsku,
              quantity: 0,
              expiryDate:
                item.bestByDate ||
                item.itemInPackingGroup.expiryDate ||
                item.expiryDate,
            };
          }
          acc[item.fnsku].quantity += item.quantity;
          return acc;
        }, {});

        let barcodeString = `AMZN,PO:${order.inboundPlanId},`;
        let barcodeStringSplitByLine = `AMZN,PO:${order.inboundPlanId},`;

        for (const fnSku in groupedItemsByFnSku) {
          const barcodeStringRaw = `ASIN:${groupedItemsByFnSku[fnSku].asin},QTY:${groupedItemsByFnSku[fnSku].quantity},EXP:${groupedItemsByFnSku[fnSku].expiryDate},`;
          barcodeString += barcodeStringRaw;
          barcodeStringSplitByLine += `\n${barcodeStringRaw}`;
        }

        print2DBarcodeLabel(
          barcodeString,
          barcodeStringSplitByLine,
          group,
          order,
          box.boxName,
          1,
          TENANT_SHIPPING_LABEL_PRINTER_ENUM[appState.subdomain] ||
            TENANT_SHIPPING_LABEL_PRINTER_ENUM.default,
          download,
          appState,
          isProvisional,
        );
      }
    });
  };

  // Weight limit checking
  const checkFbaBoxWeightLimits = (box, group, workingList) => {
    if (!box || !workingList) return { isValid: true };
    const boxFullName = `${group.groupName}_${box.boxName}`;

    const boxWeight = parseFloat(box.weight);
    const itemsInBox = workingList.filter(
      (item) => item.boxName === boxFullName,
    );

    if (boxWeight > FBA_BOX_WEIGHT_LIMIT) {
      if (itemsInBox.length > 1) {
        return {
          isValid: false,
          message: "Boxes with multiple items cannot exceed 50 pounds.",
        };
      } else {
        if (boxWeight > FBA_BOX_MECHANICAL_LIFT_WARNING_WEIGHT_LIMIT) {
          return {
            isValid: true,
            warning: "Mechanical Lift labels required on top and sides of box",
          };
        } else {
          return {
            isValid: true,
            warning: "Team Lift labels required on top and sides of box",
          };
        }
      }
    }

    return { isValid: true };
  };

  // Inventory status checking
  const hasRemainingInventory = (group) => {
    return group.items.some((item) => (Number(item.quantity) || 0) > 0);
  };

  // Effects for data initialization and updates
  useEffect(() => {
    if (currentBatch?.workingList && packingOptions?.length) {
      setFormattedGroups((prevGroups) => {
        const newGroups = transformWorkingListToGroups(
          currentBatch.workingList,
          currentBatch.boxes,
          packingOptions,
        );

        // Preserve weights from previous groups
        return newGroups.map((newGroup) => {
          const prevGroup = prevGroups.find(
            (g) => g.groupName === newGroup.groupName,
          );
          if (!prevGroup) return newGroup;

          return {
            ...newGroup,
            boxes: newGroup.boxes.map((newBox) => {
              const prevBox = prevGroup.boxes.find(
                (b) => b.boxName === newBox.boxName,
              );
              return prevBox ? { ...newBox, weight: prevBox.weight } : newBox;
            }),
          };
        });
      });
      setOriginalFormattedGroups(
        transformWorkingListToGroups(
          currentBatch.workingList,
          currentBatch.boxes,
          packingOptions,
        ),
      );
    }
  }, [currentBatch, packingOptions]);

  // Scale websocket connection
  useEffect(() => {
    if (!isDymoEnabled) return; // Don't connect if Dymo is disabled

    const client = new W3CWebSocket("ws://127.0.0.1:8456");

    client.onopen = () => {
      console.log("Connected to electron app");
    };

    client.onmessage = (message) => {
      if (!isDymoEnabled) return; // Ignore messages if Dymo is disabled

      const parsed = JSON.parse(message.data);
      let relevant = parsed.data || parsed;

      switch (relevant.key) {
        case "dymo-m10-data":
        case "dymo-data":
          try {
            const checkWeight = relevant.message.split(" ");
            const weightVar = parseInt(checkWeight[0]);

            if (!isNaN(weightVar)) {
              const expandedGroup = expandedGroups[0];
              const expandedBoxName = expandedBoxes[expandedGroup]?.[0];

              if (expandedGroup && expandedBoxName) {
                const group = formattedGroups.find(
                  (g) => g.groupName === expandedGroup,
                );
                const box = group?.boxes.find(
                  (b) => b.boxName === expandedBoxName,
                );

                if (group && box) {
                  handleBoxWeightChange(group, box, weightVar / 10);
                }
              }
            }
          } catch (err) {
            console.error(err);
          }
          break;

        default:
          break;
      }
    };

    return () => {
      client.close();
    };
  }, [
    expandedGroups,
    expandedBoxes,
    formattedGroups,
    handleBoxWeightChange,
    isDymoEnabled,
  ]);

  return (
    <div className="font-inter">
      <div className="mb-4 mr-4">
        <div className="flex items-center justify-end">
          <CustomSwitch
            leftSideText="Manual Weight Input"
            rightSideText="Dymo Scale Input"
            checked={isDymoEnabled}
            onChange={setIsDymoEnabled}
            leftColor="bg-gray-400"
            rightColor="bg-primaryAccent"
            labelClasses="text-sm font-medium text-gray-600"
            switchClasses="w-10 h-6"
            handleClasses="w-4 h-4"
          />
        </div>
      </div>
      {formattedGroups.map((group) => (
        <div key={group.groupName} className="relative mb-4">
          {/* Group Header */}{" "}
          <div
            className="sticky top-0 rounded-t-lg border border-gray-200 bg-gray-50 shadow-sm"
            style={{ zIndex: 30 }}>
            <div className="flex cursor-pointer items-center justify-between p-3 hover:bg-gray-100">
              <div className="flex items-center">
                {group.boxes.length > 0 &&
                  (expandedGroups.includes(group.groupName) ? (
                    <ChevronDownIcon
                      onClick={() => toggleGroup(group.groupName)}
                      className="mr-2 h-5 w-5 transform cursor-pointer text-gray-600 transition-transform duration-200"
                    />
                  ) : (
                    <ChevronRightIcon
                      onClick={() => toggleGroup(group.groupName)}
                      className="mr-2 h-5 w-5 transform cursor-pointer text-gray-600 transition-transform duration-200 hover:translate-x-1"
                    />
                  ))}
                <span className="text-base font-semibold text-gray-800">
                  {group.groupName}
                </span>
                <div className="ml-4 flex items-center gap-2">
                  <span className="ml-2 text-sm text-gray-500">
                    {group.boxes.length} Boxes
                  </span>
                  <Popover
                    content={
                      <div>
                        {sortBoxesByNumber(group.boxes).map((box) => (
                          <div key={box.boxName} className="mb-1">
                            <span>{box.boxName}: </span>
                            <span>
                              {box.weight || 0} {preferredWeightUnit}
                            </span>
                          </div>
                        ))}
                      </div>
                    }
                    title="Box Weights"
                    trigger="hover">
                    <span className="cursor-help text-sm text-gray-500">
                      (Total: {calculateTotalWeight(group.boxes)}{" "}
                      {preferredWeightUnit})
                    </span>
                  </Popover>
                  <Tooltip title="Download Provisional Box Labels of Group">
                    <QrcodeIcon
                      onClick={() =>
                        print2DBarcodeBoxLabel(group, null, true, true)
                      }
                      className="hover:text-primaryAccent-dark h-5 w-5 transform cursor-pointer text-primaryAccent transition-all duration-200 hover:scale-110"
                    />
                  </Tooltip>
                </div>
              </div>
              <div className="flex items-center gap-8 text-sm text-gray-500">
                <div className="flex items-center gap-2">
                  <span>
                    {group.assignedItemsNo}/{group.totalItems} Items Assigned
                  </span>
                  <EyeIcon
                    onClick={() => {
                      // Create a map of assigned quantities per SKU
                      const groupGroupedItems = group?.assignedItems?.reduce(
                        (acc, item) => {
                          const key = item.sku;
                          if (!acc[key]) {
                            // Find the original item to get total quantity
                            const originalItem = group.items.find(
                              (origItem) => origItem.sku === item.sku,
                            );

                            // Calculate available quantity (unprocessed items)
                            const availableQuantity =
                              originalItem?.quantity || 0;
                            const assignedQuantity = group.assignedItems.filter(
                              (assignedItem) => assignedItem.sku === item.sku,
                            ).length;

                            acc[key] = {
                              ...item,
                              assignedQuantity: assignedQuantity,
                              quantity: availableQuantity, // This is available quantity
                              productName: originalItem?.name || item.name,
                              image: originalItem?.image || item.image,
                              fnSku: originalItem?.fnsku || item.fnsku,
                              sku: item.sku,
                              asin: item.asin,
                              prepInstructions:
                                originalItem?.prepInstructions ||
                                item.prepInstructions,
                            };
                          }
                          return acc;
                        },
                        {},
                      );
                      group.items.forEach((item) => {
                        if (!groupGroupedItems[item.sku]) {
                          const assignedQuantity = group.assignedItems.filter(
                            (assignedItem) => assignedItem.sku === item.sku,
                          ).length;

                          groupGroupedItems[item.sku] = {
                            ...item,
                            assignedQuantity: assignedQuantity,
                            quantity: item.quantity, // Available quantity
                            productName: item.name,
                            prepInstructions: item.prepInstructions,
                          };
                        }
                      });

                      setEntitySelectedForViewItems({
                        header: `${group.groupName} : Item Details`,
                        description:
                          "Review the products and their quantities packed into this box...",
                        items: Object.values(groupGroupedItems),
                        totalItems: group.totalItems,
                        totalProducts: group.items.length,
                        isGroup: true,
                      });
                      setOpenViewItemsSlideOverPanel(true);
                    }}
                    className="hover:text-primaryAccent-dark h-5 w-5 transform cursor-pointer text-primaryAccent transition-all duration-200 hover:scale-110"
                  />{" "}
                </div>

                {/* Box Content Source Selection */}
                <div className="flex items-center gap-2">
                  <label
                    htmlFor={`source-${group.groupName}`}
                    className="text-sm text-gray-500">
                    Box Content Source:
                  </label>
                  <select
                    id={`source-${group.groupName}`}
                    value={group.boxes[0]?.source || "BOX_CONTENT_PROVIDED"}
                    onChange={(e) => handleSourceChange(group, e.target.value)}
                    className="rounded-md border border-gray-300 bg-white px-2 py-1 text-sm text-gray-700 focus:border-primaryAccent focus:outline-none focus:ring-1 focus:ring-primaryAccent">
                    <option value="BOX_CONTENT_PROVIDED">Hopstack</option>
                    <option value="BARCODE_2D">2D Barcode</option>
                  </select>
                </div>

                {/* Add Box Button */}
                <Popover
                  placement="bottomLeft"
                  content={
                    <div>
                      {boxTypes.map((type) => (
                        <div
                          key={type.name}
                          className="cursor-pointer p-2 hover:bg-gray-100"
                          onClick={() => {
                            if (type.name === "Add Custom") {
                              handleAddCustomBox(group);
                            } else {
                              handleFitInOneBoxConfirmation(type, group);
                            }
                            setActivePopover(null);
                          }}>
                          {type.name} {type.length} * {type.width} *{" "}
                          {type.height} (in)
                        </div>
                      ))}
                    </div>
                  }
                  trigger="click"
                  visible={activePopover === group.groupName} // Check if this group's popover is active
                  onVisibleChange={(visible) => {
                    setActivePopover(visible ? group.groupName : null); // Set active popover to this group's name
                  }}>
                  <PrimaryButton
                    height="1rem"
                    width="7rem"
                    disabled={!hasRemainingInventory(group)}
                    variant="secondary"
                    className={`border-none text-sm underline ${
                      !hasRemainingInventory(group)
                        ? "cursor-not-allowed opacity-50"
                        : ""
                    }`}>
                    +Add Box
                    <ChevronDownIcon className="ml-1 h-4 w-4" />
                  </PrimaryButton>
                </Popover>
              </div>
            </div>
          </div>
          {/* Group Content */}
          {expandedGroups.includes(group.groupName) && (
            <div className="rounded-b-lg border-x border-b border-gray-200 bg-white">
              <div className="space-y-4 bg-white p-4">
                {/* Unpack All Boxes Option */}
                {group.boxes.length > 0 && (
                  <div className="flex items-center gap-2">
                    <span className="text-sm text-gray-500">
                      Multiple Boxes
                    </span>
                    <button
                      onClick={() => {
                        appState.showNewConfirmation(
                          "Confirm",
                          "Are you sure you want to unpack all boxes?",
                          () => handleUnpackAllBoxes(group),
                          () => {},
                        );
                      }}
                      className="text-sm font-medium text-primaryAccent underline">
                      Unpack All Boxes
                    </button>
                  </div>
                )}

                {/* Boxes List */}
                {sortBoxesByNumber(group.boxes).map((box, boxIndex) => (
                  <div
                    key={box.boxName}
                    className="rounded-lg border border-gray-200">
                    {/* Box Header */}
                    <div className="flex cursor-pointer items-center justify-between bg-gray-50 p-4 transition-colors duration-200 hover:bg-gray-100">
                      <div className="flex items-center">
                        {expandedBoxes[group.groupName]?.includes(
                          box.boxName,
                        ) ? (
                          <ChevronDownIcon
                            onClick={() =>
                              toggleBox(group.groupName, box.boxName)
                            }
                            className="mr-2 h-5 w-5 transform cursor-pointer text-gray-600 transition-transform duration-200"
                          />
                        ) : (
                          <ChevronRightIcon
                            onClick={() =>
                              toggleBox(group.groupName, box.boxName)
                            }
                            className="mr-2 h-5 w-5 transform cursor-pointer text-gray-600 transition-transform duration-200 hover:translate-x-1"
                          />
                        )}
                        <span className="text-base font-semibold text-gray-800">
                          {box.boxName}
                        </span>
                        <div className="ml-4 flex items-center gap-2">
                          <span className="text-sm text-gray-500">
                            {box.assignedQuantity} Items Assigned
                          </span>
                          <span className="text-sm text-gray-500">
                            ({box.weight || 0} {preferredWeightUnit})
                          </span>
                        </div>
                      </div>

                      {/* Box Actions */}
                      <div className="flex items-center gap-4">
                        {box.items.length > 0 ? (
                          <>
                            <EyeIcon
                              onClick={() => {
                                const boxGroupedItems = box.items.reduce(
                                  (acc, item) => {
                                    const key = item.sku;
                                    if (!acc[key]) {
                                      acc[key] = {
                                        ...item,
                                        quantity: 1,
                                        totalQuantity: 1,
                                      };
                                    } else {
                                      acc[key].quantity += 1;
                                      acc[key].totalQuantity += 1;
                                    }
                                    return acc;
                                  },
                                  {},
                                );

                                setEntitySelectedForViewItems({
                                  header: `${group.groupName} : ${box.boxName} : Item Details`,
                                  description:
                                    "Review the products and their quantities packed into this box...",
                                  items: Object.values(boxGroupedItems),
                                  totalItems: box.assignedQuantity, // This should match the count shown in box header
                                  totalProducts:
                                    Object.keys(boxGroupedItems).length,
                                });
                                setOpenViewItemsSlideOverPanel(true);
                              }}
                              className="hover:text-primaryAccent-dark h-5 w-5 transform cursor-pointer text-primaryAccent transition-all duration-200 hover:scale-110"
                            />
                            <PencilIcon
                              onClick={() => {
                                setSelectedBoxForEdit({ box, group });
                                setOpenEditBoxItemsPanel(true);
                              }}
                              className="hover:text-primaryAccent-dark h-5 w-5 transform cursor-pointer text-primaryAccent transition-all duration-200 hover:scale-110"
                            />
                          </>
                        ) : (
                          <>
                            <EyeIcon className="h-5 w-5 cursor-not-allowed text-gray-400" />
                            <PencilIcon className="h-5 w-5 cursor-not-allowed text-gray-400" />
                          </>
                        )}
                        <Tooltip title="Download Provisional Box Labels">
                          <QrcodeIcon
                            onClick={() =>
                              print2DBarcodeBoxLabel(
                                group,
                                box.boxName,
                                true,
                                true,
                              )
                            }
                            className="hover:text-primaryAccent-dark h-5 w-5 transform cursor-pointer text-primaryAccent transition-all duration-200 hover:scale-110"
                          />
                        </Tooltip>
                        {/* 
                      <CustomPopconfirm
                        title="Are you sure to duplicate this box and its contents?"
                        onConfirm={() => {
                          if (!hasRemainingInventory(group)) {
                            notify.warning(
                              "All items in this group have already been assigned to boxes",
                            );
                            return;
                          }
                          handleDuplicateBox(group, box);
                        }}
                        onCancel={() => {}}
                        okText="Yes"
                        cancelText="No"
                        placement="bottomRight"
                        okButtonProps={{
                          className: "bg-primaryAccent text-white",
                        }}
                        cancelButtonProps={{
                          className:
                            "bg-white border rounded border-primaryAccent text-primaryAccent",
                        }}>
                        <DuplicateIcon className="h-5 w-5 cursor-pointer text-primaryAccent" />
                      </CustomPopconfirm> */}
                        <DuplicateIcon
                          className="hover:text-primaryAccent-dark h-5 w-5 transform cursor-pointer text-primaryAccent transition-all duration-200 hover:scale-110"
                          onClick={() => {
                            if (!hasRemainingInventory(group)) {
                              notify.warning(
                                "All items in this group have already been assigned to boxes",
                              );
                              return;
                            }
                            setBoxToDuplicate({ box, group });
                            setDuplicateQuantity(1);
                            setShowDuplicateModal(true);
                          }}
                        />

                        <CustomPopconfirm
                          title="Are you sure to delete this box and its contents?"
                          onConfirm={() => handleDeleteBox(group, box)}
                          onCancel={() => {}}
                          okText="Yes"
                          cancelText="No"
                          placement="bottomRight"
                          okButtonProps={{
                            className: "bg-primaryAccent text-white",
                          }}
                          cancelButtonProps={{
                            className:
                              "bg-white border rounded border-primaryAccent text-primaryAccent",
                          }}>
                          <TrashIcon
                            className={`className="h-5 hover:text-primaryAccent-dark" w-5 transform transition-all duration-200 hover:scale-110 ${
                              group.boxes.length === 1
                                ? "cursor-not-allowed text-gray-400"
                                : "cursor-pointer text-primaryAccent"
                            }`}
                            onClick={(e) => {
                              if (group.boxes.length === 1) {
                                e.stopPropagation();
                              }
                            }}
                          />
                        </CustomPopconfirm>
                      </div>
                    </div>

                    {/* Box Details */}
                    {expandedBoxes[group.groupName]?.includes(box.boxName) && (
                      <div className="bg-white p-4 px-6">
                        <div className="grid grid-cols-12 items-center gap-4">
                          {/* Dimensions */}
                          <div className="col-span-4">
                            <div className="flex items-center gap-3">
                              <span className="text-sm font-medium text-gray-500">
                                Dimensions (l*w*h) ({preferredDimensionUnit})
                              </span>
                              <Popover
                                placement="bottomLeft"
                                content={
                                  <div>
                                    {boxTypes.map((type) => (
                                      <div
                                        key={type.name}
                                        className="cursor-pointer p-2 hover:bg-gray-100"
                                        onClick={() => {
                                          if (type.name === "Add Custom") {
                                            setSelectedGroup(group);
                                            setSelectedBox(box);
                                            setBoxDimensionsDetails({
                                              height: box?.dimensions?.height,
                                              width: box?.dimensions?.width,
                                              length: box?.dimensions?.length,
                                            });
                                            setActiveEditPopover(null);
                                            setIsBoxDimensionsModalOpen(true);
                                            return;
                                          }
                                          handleUpdateBoxDimensions(
                                            group,
                                            box,
                                            {
                                              length: type.length,
                                              width: type.width,
                                              height: type.height,
                                            },
                                          );
                                          setActiveEditPopover(null); // Close the popover after clicking
                                        }}>
                                        {type.name} {type.length} * {type.width}{" "}
                                        * {type.height} (in)
                                      </div>
                                    ))}
                                  </div>
                                }
                                trigger="click"
                                visible={
                                  activeEditPopover ===
                                  `${group.groupName}-${box.boxName}`
                                } // Ensure popover is specific to this group/box
                                onVisibleChange={(visible) => {
                                  setActiveEditPopover(
                                    visible
                                      ? `${group.groupName}-${box.boxName}`
                                      : null,
                                  ); // Toggle visibility
                                }}>
                                <PencilIcon className="hover:text-primaryAccent-dark z-10 h-5 w-5 transform cursor-pointer text-primaryAccent transition-all duration-200 hover:scale-110" />
                              </Popover>
                            </div>
                            <div className="mt-2 flex flex-col p-2">
                              <span>
                                {box.dimensions.length +
                                  "*" +
                                  box.dimensions.width +
                                  "*" +
                                  box.dimensions.height}
                              </span>
                            </div>
                          </div>

                          {/* Scanner and Manual Add */}
                          <div className="col-span-6">
                            <span className="text-sm font-medium text-gray-500">
                              Scan code or Manually Add Items Into Box
                            </span>
                            <div className="mt-2 flex items-center gap-2">
                              <input
                                type="text"
                                className="w-3/4 rounded-md border border-gray-300 p-2"
                                placeholder="Enter code..."
                                onKeyDown={(e) =>
                                  handleScannerInput(e, group, box, boxIndex)
                                }
                              />
                              <button
                                className="rounded-md bg-primaryAccent p-2 text-white"
                                onClick={() => {
                                  if (!hasRemainingInventory(group)) {
                                    notify.warning(
                                      "All items in this group have already been assigned to boxes",
                                    );
                                    return;
                                  }
                                  setGroupSelectedToManualAddItems({
                                    group,
                                    boxIndex,
                                  });
                                  setOpenManualAddItemsSlideOverPanel(true);
                                }}>
                                <PlusIcon className="h-5 w-5" />
                              </button>
                            </div>
                          </div>

                          {/* Weight Input */}
                          <div className="col-span-2">
                            <span className="text-sm font-normal text-gray-500">
                              Weight ({preferredWeightUnit}){" "}
                              <span className="text-red-500">*</span>
                            </span>
                            <div className="flex flex-col">
                              <input
                                type="number"
                                className={`mt-2 w-full rounded-md border ${
                                  weightErrors[`${box.boxName}`]
                                    ? "border-red-500"
                                    : "border-gray-300"
                                } p-2 text-gray-700 transition-all duration-200 focus:border-primaryAccent focus:ring focus:ring-primaryAccent focus:ring-opacity-50`}
                                value={box.weight || ""}
                                onChange={(e) => {
                                  const newWeight = e.target.value;
                                  if (newWeight < 0) {
                                    setWeightErrors({
                                      ...weightErrors,
                                      [box.boxName]:
                                        "Weight cannot be negative",
                                    });
                                    return;
                                  }
                                  setWeightErrors({
                                    ...weightErrors,
                                    [box.boxName]: "",
                                  });
                                  handleBoxWeightChange(group, box, newWeight);
                                }}
                                min="0"
                                step="0.01"
                                placeholder="Enter weight"
                              />
                              {weightErrors[box.boxName] && (
                                <span className="mt-1 text-sm text-red-500">
                                  {weightErrors[box.boxName]}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>

                        {/* Weight Warnings */}
                        {box?.weight ? (
                          <div className="mt-3">
                            {(() => {
                              const weightCheck = checkFbaBoxWeightLimits(
                                box,
                                group,
                                currentBatch.workingList,
                              );
                              if (!weightCheck.isValid) {
                                return (
                                  <CustomAlert
                                    type="error"
                                    message={weightCheck.message}
                                    id={`weight-alert-${box.boxName}`}
                                    options={{ defaultColors: true }}
                                  />
                                );
                              }
                              if (weightCheck.warning) {
                                return (
                                  <CustomAlert
                                    type="warning"
                                    message={weightCheck.warning}
                                    id={`weight-alert-${box.boxName}`}
                                    options={{ defaultColors: true }}
                                  />
                                );
                              }
                              return null;
                            })()}
                          </div>
                        ) : null}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      ))}

      {/* Slide Over Panels and Modals */}
      <SlideOverPanel
        open={openManualAddItemsSlideOverPanel}
        containerStyle={"max-w-6xl"}
        setOpen={setOpenManualAddItemsSlideOverPanel}
        isCrossIconVisible={false}
        title={
          <HeaderWithArrow
            headerTitle={"Add Items to Box"}
            description={
              "Select the required items and enter their quantities to pack into this box. Ensure the quantities match the available stock to maintain accuracy and avoid discrepancies."
            }
            isLearnMore={false}
            arrowAction={() => setOpenManualAddItemsSlideOverPanel(false)}
            isArrow
            mainClasses="mb-0"
          />
        }>
        <AddItemsInBox
          itemsData={groupSelectedToManualAddItems?.group?.items || []}
          onCancel={() => {
            setOpenManualAddItemsSlideOverPanel(false);
          }}
          onConfirm={(addedItems) => {
            // When confirming, we need to map back to individual items
            const itemsToAdd = addedItems.flatMap((item) => {
              // Get original individual items that are unprocessed
              const availableItems = item.originalItems
                .filter((i) => i.status === "UNPROCESSED")
                .slice(0, item.quantity);

              return availableItems.map((i) => ({
                ...i,
                quantity: 1,
              }));
            });

            handleManualItemAddition(
              itemsToAdd,
              groupSelectedToManualAddItems.group,
              groupSelectedToManualAddItems.boxIndex,
            );
            setOpenManualAddItemsSlideOverPanel(false);
          }}
        />
      </SlideOverPanel>

      <SlideOverPanel
        open={openViewItemsSlideOverPanel}
        containerStyle={"max-w-6xl"}
        setOpen={setOpenViewItemsSlideOverPanel}
        isCrossIconVisible={false}
        title={
          <HeaderWithArrow
            headerTitle={entitySelectedForViewItems.header}
            description={entitySelectedForViewItems.description}
            isLearnMore={false}
            isArrow
            arrowAction={() => setOpenViewItemsSlideOverPanel(false)}
            mainClasses="mb-0"
          />
        }>
        <div className="p-6 font-inter">
          <ViewPackedItems
            items={entitySelectedForViewItems?.items}
            totalItems={entitySelectedForViewItems?.totalItems}
            totalProducts={entitySelectedForViewItems?.totalProducts}
            isGroup={entitySelectedForViewItems?.isGroup}
          />
        </div>
      </SlideOverPanel>

      <SlideOverPanel
        open={openEditBoxItemsPanel}
        containerStyle={"max-w-6xl"}
        setOpen={setOpenEditBoxItemsPanel}
        isCrossIconVisible={false}
        title={
          <HeaderWithArrow
            headerTitle={`${selectedBoxForEdit?.group.groupName} : ${selectedBoxForEdit?.box.boxName} : Edit Items`}
            description="Modify item quantities or remove items from this box. Changes will update both box contents and available group inventory."
            isLearnMore={false}
            arrowAction={() => setOpenEditBoxItemsPanel(false)}
            isArrow
            mainClasses="mb-0"
          />
        }>
        {selectedBoxForEdit && (
          // In PackingBoxInformation component, modify the EditBoxItems onSave handler
          <EditBoxItems
            box={selectedBoxForEdit.box}
            group={selectedBoxForEdit.group} // Group data is already transformed and grouped
            onCancel={() => {
              setOpenEditBoxItemsPanel(false);
              setSelectedBoxForEdit(null);
            }}
            onSave={async (changes) => {
              const { updatedItems } = changes;

              // Unpack all items from the box
              await handleUnpackItems(
                selectedBoxForEdit.box.items,
                selectedBoxForEdit.group,
              );
              // Use the original items from our transformed data
              const itemsToAdd = updatedItems;

              const boxFullName = `${selectedBoxForEdit.group.groupName}_${selectedBoxForEdit.box.boxName}`;

              if (itemsToAdd.length > 0) {
                const packVariables = {
                  id: currentBatch.id,
                  boxName: boxFullName,
                  items: itemsToAdd,
                };

                try {
                  await dispatch(
                    packItems({
                      variables: packVariables,
                      successCallback: async () => {
                        await getCurrentBatch();
                        notify.success("Box items updated successfully");
                      },
                      errorCallback: (error) => {
                        notify.error(
                          error?.message || "Failed to update box items",
                        );
                      },
                    }),
                  );
                } catch (error) {
                  notify.error("Failed to update box items");
                }
              }

              setOpenEditBoxItemsPanel(false);
              setSelectedBoxForEdit(null);
            }}
          />
        )}
      </SlideOverPanel>

      {/* Custom Box Dimensions Modal */}
      <NewModal
        isOpen={isBoxDimensionsModalOpen}
        onClose={() => setIsBoxDimensionsModalOpen(false)}
        title="Enter Custom Box Dimensions"
        maxHeight="600px">
        <div className="py-4 font-inter">
          <div className="flex flex-col gap-4">
            {["length", "width", "height"].map((dim) => (
              <div key={dim} className="flex flex-col">
                <div className="flex items-center">
                  <label className="w-24 font-medium">
                    {dim.charAt(0).toUpperCase() + dim.slice(1)} (in):
                  </label>
                  <input
                    type="number"
                    min="0"
                    className={`ml-2 w-24 rounded-md border ${
                      dimensionErrors[dim]
                        ? "border-red-500"
                        : "border-gray-300"
                    } px-2 py-1`}
                    value={boxDimensionsDetails[dim]}
                    onChange={(e) => {
                      const value = Number(e.target.value);
                      if (value < 0) {
                        setDimensionErrors((prev) => ({
                          ...prev,
                          [dim]: "Dimension cannot be negative",
                        }));
                        return;
                      }
                      setDimensionErrors((prev) => ({
                        ...prev,
                        [dim]: "",
                      }));
                      setBoxDimensionsDetails((prev) => ({
                        ...prev,
                        [dim]: parseFloat(value),
                      }));
                    }}
                  />
                </div>
                {dimensionErrors[dim] && (
                  <span className="ml-26 mt-1 text-sm text-red-500">
                    {dimensionErrors[dim]}
                  </span>
                )}
              </div>
            ))}
          </div>
          <div className="mt-6 flex justify-end gap-4">
            <PrimaryButton
              height="2.5rem"
              width="7rem"
              variant="secondary"
              onClick={() => {
                setIsBoxDimensionsModalOpen(false);
                setDimensionErrors({
                  length: "",
                  width: "",
                  height: "",
                });
              }}>
              Cancel
            </PrimaryButton>
            <PrimaryButton
              height="2.5rem"
              width="7rem"
              variant="primary"
              onClick={() => {
                if (selectedGroup && selectedBox) {
                  handleUpdateBoxDimensions(
                    selectedGroup,
                    selectedBox,
                    boxDimensionsDetails,
                  );
                  setIsBoxDimensionsModalOpen(false);
                } else {
                  handleDimensionsSubmit();
                }
              }}>
              Confirm
            </PrimaryButton>
          </div>
        </div>
      </NewModal>

      {/* Duplicate Box Modal */}
      <NewModal
        isOpen={showDuplicateModal}
        onClose={() => setShowDuplicateModal(false)}
        title={`Duplicate ${boxToDuplicate?.box?.boxName || "Box"}`}
        maxHeight="600px">
        <div className="py-4 font-inter">
          <div className="mb-4">
            <p className="mb-4">
              How many copies of this box would you like to create?
            </p>
            <div className="flex items-center gap-4">
              <label className="font-medium">Quantity:</label>
              <div className="flex items-center">
                <button
                  className="rounded-l border p-2 hover:bg-gray-100"
                  onClick={() =>
                    setDuplicateQuantity((prev) => Math.max(1, prev - 1))
                  }>
                  -
                </button>
                <input
                  type="number"
                  min="1"
                  value={duplicateQuantity}
                  onChange={(e) => {
                    const value = Math.max(1, parseInt(e.target.value) || 1);
                    setDuplicateQuantity(value);
                  }}
                  className="w-20 border-y p-2 text-center"
                />
                <button
                  className="rounded-r border p-2 hover:bg-gray-100"
                  onClick={() => setDuplicateQuantity((prev) => prev + 1)}>
                  +
                </button>
              </div>
            </div>
          </div>
          <div className="mt-6 flex justify-end gap-4">
            <PrimaryButton
              height="2.5rem"
              width="7rem"
              variant="secondary"
              onClick={() => {
                setShowDuplicateModal(false);
                setBoxToDuplicate(null);
                setDuplicateQuantity(1);
              }}>
              Cancel
            </PrimaryButton>
            <PrimaryButton
              height="2.5rem"
              width="7rem"
              variant="primary"
              onClick={async () => {
                // for (let i = 0; i < duplicateQuantity; i++) {
                await handleDuplicateBox(
                  boxToDuplicate.group,
                  boxToDuplicate.box,
                );
                // }
                setShowDuplicateModal(false);
                setBoxToDuplicate(null);
                setDuplicateQuantity(1);
              }}>
              Confirm
            </PrimaryButton>
          </div>
        </div>
      </NewModal>

      {/* Scanner Modal */}
      <NewModal
        isOpen={isScannerModalOpen}
        onClose={() => {
          setIsScannerModalOpen(false);
        }}
        title={`Add Item to ${selectedBox?.boxName}`}
        maxHeight="600px">
        <div className="font-inter">
          {scannedItem && (
            <>
              <div className="flex items-start gap-4">
                <img
                  src={scannedItem.image}
                  alt={scannedItem.msku}
                  className="h-24 w-24 rounded-md object-cover"
                />
                <div className="flex flex-1 flex-col gap-2">
                  <div className="text-base font-medium">
                    {scannedItem.fnsku}
                  </div>
                  <div className="text-sm text-gray-500">
                    SKU: {scannedItem.msku}
                  </div>
                  <div className="text-sm text-gray-500">
                    ASIN: {scannedItem.asin}
                  </div>
                  <div className="mt-4">
                    <label className="text-sm font-medium">
                      Quantity (max: {scannedItem.quantity})
                    </label>
                    <div className="mt-2 flex items-center gap-2">
                      <button
                        className="rounded border p-2 hover:bg-gray-100"
                        onClick={() =>
                          setScannerQuantity((prev) => Math.max(1, prev - 1))
                        }>
                        -
                      </button>
                      <input
                        type="number"
                        min="1"
                        max={scannedItem.quantity}
                        value={scannerQuantity}
                        onChange={(e) =>
                          setScannerQuantity(
                            Math.min(
                              scannedItem.quantity,
                              Math.max(1, Number(e.target.value)),
                            ),
                          )
                        }
                        className="w-20 rounded border p-2 text-center"
                      />
                      <button
                        className="rounded border p-2 hover:bg-gray-100"
                        onClick={() =>
                          setScannerQuantity((prev) =>
                            Math.min(scannedItem.quantity, prev + 1),
                          )
                        }>
                        +
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-6 flex justify-end gap-4">
                <PrimaryButton
                  height="3rem"
                  minWidth="7rem"
                  maxWidth="15rem"
                  variant="secondary"
                  className="ml-6 mt-2 text-lg font-medium"
                  onClick={() => setIsScannerModalOpen(false)}>
                  Cancel
                </PrimaryButton>
                <PrimaryButton
                  height="3rem"
                  minWidth="7rem"
                  maxWidth="15rem"
                  variant="primary"
                  className="ml-6 mt-2 text-lg font-medium"
                  onClick={handleScannerSubmit}>
                  Confirm
                </PrimaryButton>
              </div>
            </>
          )}
        </div>
      </NewModal>
    </div>
  );
};

PackingBoxInformation.propTypes = {
  packingOptions: PropTypes.array.isRequired,
  order: PropTypes.object.isRequired,
  selectedPackingOption: PropTypes.string.isRequired,
  boxTypes: PropTypes.array.isRequired,
  isLtlFlow: PropTypes.bool,
  currentBatch: PropTypes.object.isRequired,
  getCurrentBatch: PropTypes.func.isRequired,
};

export default PackingBoxInformation;
