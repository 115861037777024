import React, { useContext, useEffect, useState, useMemo } from "react";
import CustomTable from "#newUiComponents/commons/CustomTable";
import CheckBox from "#newUiComponents/commons/CheckBox";
import FbaProductRow from "./FbaProductRow";
import { useDispatch, useSelector } from "react-redux";
import {
  getInventoryForRelease,
  startEligibilityCheck,
  getEligibilityCheckResults,
  saveOrder,
  activateInboundPlan,
  updateOrderForInbound,
  createManualBatch,
  startFbaInboundShipmentPlan,
} from "#redux/FbaInbound/fbaInboundActions";
import PrimaryButton from "#newUiComponents/commons/PrimaryButton";
import { useHistory } from "react-router-dom";
import CustomNotification from "#newUiComponents/commons/CustomNotification";
import { AppStateContext } from "#contexts/appState";
import { AuthContext } from "#contexts/auth";
import { planStatusesEnums } from "./FbaUtils";
import FbaConfirmationModals from "./FbaConfirmationModals";
import CustomSwitch from "#newUiComponents/commons/CustomSwitch"; // Import your CustomSwitch component
import CustomAlert from "#newUiComponents/commons/CustomAlert";
import {
  resetEligibilityCheckResults,
  resetInventoryForRelease,
  resetSaveOrderResult,
  resetUpdateInboundPlanResult,
} from "#redux/FbaInbound/fbaInboundSlice";

const FbaConfirmationProducts = ({
  selectedPlan,
  client,
  handleResetFetchPlans,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const notify = CustomNotification();
  const auth = useContext(AuthContext);
  const appState = useContext(AppStateContext);
  const {
    inventoryForRelease,
    eligibilityCheckResults,
    activateInboundPlanResult,
  } = useSelector((state) => state.fbaInbound);

  const [filteredInventory, setFilteredInventory] = useState([]);
  const [selectedInventoryIds, setSelectedInventoryIds] = useState([]);
  const [eligibilityModalOpen, setEligibilityModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [proceedWithPickingModalOpen, setProceedWithPickingModalOpen] =
    useState(false);
  const [showExceptionsOnly, setShowExceptionsOnly] = useState(false); // State for the switch toggle

  const handleEligibilityCheck = (isStayOnPage) => {
    const selectedInventory = inventoryForRelease?.data?.filter((product) =>
      selectedInventoryIds.includes(product.id),
    );

    const variables = {
      input: {
        customerId: selectedPlan?.customer,
        marketPlaceCountryCodes: selectedPlan?.marketplace,
        inboundId: selectedPlan?.inboundPlanId,
        products: selectedInventory?.map(({ asin, sku, id, name }) => {
          const filteredProduct = filteredInventory.find((p) => p.id === id);
          return {
            asin,
            sku,
            quantity: filteredProduct?.unitsToShip,
            expiryDate: filteredProduct?.selectedExpirationDate,
            id,
            name,
          };
        }),
        sellerId: selectedPlan?.sellerId,
        warehouseId: selectedPlan?.warehouse,
      },
    };

    const eligibilitySuccessCallback = (data) => {
      if (data?.status === "SUCCESS") {
        notify.success(
          <span>
            Eligibility check completed successfully, click{" "}
            <span
              onClick={() => {
                history.push("/fbaInbound/" + selectedPlan.inboundPlanId);
              }}
              className="font-semibold underline">
              {selectedPlan?.inboundPlanId}
            </span>{" "}
            to visit the product confirmation screen for this plan.
          </span>,
        );
        handleResetFetchPlans();
      } else if (data?.status === "FAILED") {
        notify.error(
          `Eligibility check is failed for ${selectedPlan?.inboundPlanId}`,
        );
      }
    };

    const successCallback = ({ workflowId, errors }) => {
      if (workflowId)
        notify.success(
          `Successfully started workflow`,
          `WorkFlow ID: ${workflowId}`,
        );
      else if (errors?.length) {
        notify.error(errors[0].message, errors[0]?.details);
        appState.removeLoadingOverlay();
        return;
      }

      const eligibilityVariables = { inboundId: selectedPlan?.inboundPlanId };
      if (isStayOnPage) {
        dispatch(
          getEligibilityCheckResults({ variables: eligibilityVariables }),
        );
      } else {
        history.push(`/fbaInbound`);
        handleResetFetchPlans();
        dispatch(
          getEligibilityCheckResults({
            variables: eligibilityVariables,
            successCallback: eligibilitySuccessCallback,
            errorCallBack: notify.error,
          }),
        );
      }
    };

    if (isStayOnPage)
      appState.setLoadingOverlay(
        "Please wait getting eligibility check results.",
      );

    dispatch(
      startEligibilityCheck({
        variables,
        successCallback,
        errorCallBack: (error) => {
          notify.error(error);
          appState.removeLoadingOverlay();
        },
      }),
    );
  };

  const handleUpdateOrder = (orderId, lineItems, orderDate) => {
    const updateOrderVariables = {
      orderInput: {
        id: orderId,
        subStatus: "PREPPING_COMPLETED", // temperary change
        orderStatus: "IN-PROCESS",
        source: "Hopstack",
        orderDate: orderDate,
        orderLineItems: lineItems
          ?.map((item) => {
            if (selectedInventoryIds.includes(item.id)) {
              return {
                productId: item?.id,
                quantity: item?.unitsToShip,
                fnSku: item.fnSku,
                sku: item.sku,
                name: item.name,
                lotId: item.lotId,
              };
            }
          })
          .filter(Boolean),
      },
      user: auth?.user?.id,
    };
    dispatch(updateOrderForInbound({ variables: updateOrderVariables }));
  };

  const handleCreateManualBatch = (id) => {
    const manualBatchVariables = {
      ids: [id],
    };
    dispatch(createManualBatch({ variables: manualBatchVariables }));
  };

  const handleStartFbaInboundShipmentPlan = (markPickingCompleted) => {
    const startFbaShipmentPlanVariables = {
      input: {
        customerId: selectedPlan?.customer,
        warehouseId: selectedPlan?.warehouse,
        inboundId: selectedPlan?.inboundPlanId,
        marketPlaceCountryCodes: [selectedPlan?.marketplace],
        sellerId: selectedPlan?.sellerId,
        markPickingCompleted,
        products: filteredInventory
          ?.map((item) => {
            if (selectedInventoryIds.includes(item.id)) {
              return {
                id: item?.id,
                asin: item?.asin,
                msku: item?.sku,
                quantity: item.unitsToShip,
                expiration: item.selectedExpirationDate,
                fnSku: item.fnSku,
                name: item.name,
                image:
                  item?.images?.[0]?.display_url ||
                  item?.images?.[0]?.url ||
                  "",
              };
            }
          })
          .filter(Boolean),
      },
    };
    const successCallback = (data) => {
      if (data?.workflowId) {
        const inboundPlanVariables = {
          activateInboundPlanInput: {
            inboundPlanId: selectedPlan.inboundPlanId,
            customer: selectedPlan.customer,
            id: selectedPlan.id,
            items: filteredInventory
              ?.map((item) => {
                if (selectedInventoryIds.includes(item.id)) {
                  return {
                    asin: item.asin,
                    fnsku: item.fnSku,
                    lotId: item?.lotId || "null",
                    price: item?.price || null,
                    productId: item?.id || null,
                    quantity: item?.unitsToShip || null,
                    sku: item?.sku || null,
                    uniqueIdentifier: null,
                    name: item?.name || null,
                    expiryDate: item?.selectedExpirationDate || null,
                    images:
                      item?.images?.map((image) => {
                        return image.display_url || image.url;
                      }) || [],
                  };
                }
              })
              .filter(Boolean),
          },
        };

        dispatch(
          activateInboundPlan({
            variables: inboundPlanVariables,
            successCallback: (activateData) => {
              notify.success(
                `Successfully started workflow`,
                `WorkFlow ID: ${data?.workflowId}`,
              );
            },
            errorCallback: (err) => {
              notify.error(err);
            },
          }),
        );
      } else if (data?.errors?.length > 0) {
        notify.error(data?.errors[0]?.message, data?.errors[0]?.details);
      }
    };

    const errorCallback = (err) => {
      notify.error(err);
    };

    dispatch(
      startFbaInboundShipmentPlan({
        variables: startFbaShipmentPlanVariables,
        successCallback,
        errorCallback,
      }),
    );
  };

  // const handleSaveOrder = (markPickingCompleted) => {
  //   const orderDate = Date.now();
  //   const saveOrderVariables = {
  //     orderDate: orderDate,
  //     inboundPlanId: selectedPlan?.inboundPlanId,
  //     // reference: selectedPlan?.inboundPlanId,
  //     customer: selectedPlan?.customer,
  //     warehouse: selectedPlan?.warehouse,
  //     warehouseToBeSelected: false,
  //     workflowType: markPickingCompleted ? "MARK_PICKING_COMPLETED" : null,
  //     // dropship: markPickingCompleted ? true : false,
  //     uniqueIdentifier: selectedPlan?.uniqueIdentifier,
  //     customerToBeSelected: false,
  //     toValidAddress: true,
  //     source: "HOPSTACK_FBA",
  //     orderLineItems: filteredInventory
  //       ?.map((item) => {
  //         if (selectedInventoryIds.includes(item.id)) {
  //           return {
  //             productId: item?.id,
  //             quantity: item.unitsToShip,
  //             marketplaceAttributes: item.marketplaceAttributes ?? null,
  //             attributes: item.attributes ?? null,
  //             fnSku: item.fnSku,
  //             sku: item.sku,
  //             name: item.name,
  //             lotId: item.lotId,
  //           };
  //         }
  //       })
  //       .filter(Boolean),
  //   };

  //   dispatch(
  //     saveOrder({
  //       variables: saveOrderVariables,
  //       successCallback,
  //       errorCallback,
  //     }),
  //   );
  // };

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  useEffect(() => {
    if (activateInboundPlanResult?.isRejected) {
      notify.warning(activateInboundPlanResult?.data);
    } else if (activateInboundPlanResult?.isFulfilled) {
      history.push("/fbaInbound");
      handleResetFetchPlans();
    }
  }, [activateInboundPlanResult]);

  useEffect(() => {
    if (selectedPlan && Object.keys(selectedPlan).length) {
      const variables = {
        sellerId: selectedPlan?.sellerId || "",
        marketplace: selectedPlan?.marketplace || "",
        customer: selectedPlan?.customer || "",
        warehouse: selectedPlan?.warehouse || "",
      };

      let successCallback = () => {};

      if (
        selectedPlan?.status ===
          planStatusesEnums.ELIGIBILITY_CHECK_COMPLETED.key ||
        selectedPlan?.status === planStatusesEnums.ELIGIBILITY_CHECK_PENDING.key
      ) {
        successCallback = () => {
          const eligibilityVariables = {
            inboundId: selectedPlan?.inboundPlanId,
          };
          dispatch(
            getEligibilityCheckResults({ variables: eligibilityVariables }),
          );
        };
      }

      dispatch(getInventoryForRelease({ variables, successCallback }));
    }
  }, [selectedPlan]);

  useEffect(() => {
    if (Array.isArray(inventoryForRelease?.data)) {
      const transformedData = inventoryForRelease?.data
        ?.map((item) => {
          if (item.expirationDates?.length > 0) {
            const totalQuantity = item.expirationDates.reduce(
              (sum, date) => sum + (date.quantity || 0),
              0,
            );

            if (totalQuantity === 0) return null;

            return {
              ...item,
              selectedExpirationDate:
                item.expirationDates[0]?.expirationDate || null,
              selectedQuantity: totalQuantity,
              unitsToShip: totalQuantity,
            };
          } else {
            const availableQuantity = item.quantities?.availableToShip || 0;
            if (availableQuantity === 0) return null;

            return {
              ...item,
              selectedExpirationDate: null,
              selectedQuantity: availableQuantity,
              unitsToShip: availableQuantity,
            };
          }
        })
        .filter(Boolean);
      setFilteredInventory(transformedData);
    } else {
      setFilteredInventory([]);
    }

    if (inventoryForRelease.isPending) appState.setLoading();
    else appState.removeLoading();
  }, [inventoryForRelease]);

  useEffect(() => {
    if (
      eligibilityCheckResults.isFulfilled ||
      eligibilityCheckResults.isRejected ||
      !eligibilityCheckResults.isPending
    ) {
      appState.removeLoadingOverlay();
    }
    if (eligibilityCheckResults.isRejected) {
      notify.warning(eligibilityCheckResults?.data);
    } else if (eligibilityCheckResults.isFulfilled) {
      if (eligibilityCheckResults?.data?.status === "SUCCESS") {
        setFilteredInventory((prevInventory) => {
          const receivedItemsIds =
            eligibilityCheckResults?.data?.itemsEligibilityAndRestrictionDetails?.map(
              (item) => item.id,
            );
          const finalInventoryToShow = prevInventory
            .map((product) => {
              if (receivedItemsIds?.includes(product.id)) {
                const restrictionDetails =
                  eligibilityCheckResults?.data?.itemsEligibilityAndRestrictionDetails?.find(
                    (item) => item.id === product.id,
                  );

                const updatedProduct = { ...product };

                if (restrictionDetails?.quantity !== undefined) {
                  updatedProduct.unitsToShip = restrictionDetails.quantity;

                  if (product.expirationDates?.length > 0) {
                    const firstExpiryDate = product.expirationDates[0];
                    if (
                      restrictionDetails.quantity <= firstExpiryDate.quantity
                    ) {
                      updatedProduct.selectedExpirationDate =
                        firstExpiryDate.expirationDate;
                      updatedProduct.selectedQuantity =
                        firstExpiryDate.quantity;
                    }
                  }
                }

                return {
                  ...updatedProduct,
                  restrictionDetails,
                };
              }
            })
            .filter(Boolean);

          setSelectedInventoryIds(
            finalInventoryToShow
              .filter((product) => product.restrictionDetails?.isEligible)
              .map((product) => product.id),
          );
          return finalInventoryToShow;
        });
      } else {
        notify.error(
          eligibilityCheckResults?.data?.errors[0]?.message ||
            "Eligibility check Failed !",
          eligibilityCheckResults?.data?.errors[0]?.details || "",
        );
      }
    }
  }, [eligibilityCheckResults]);

  useEffect(() => {
    return () => {
      dispatch(resetEligibilityCheckResults());
      dispatch(resetSaveOrderResult());
      dispatch(resetUpdateInboundPlanResult());
      dispatch(resetInventoryForRelease());
    };
  }, [dispatch]);

  // Filtered inventory based on switch state (all products or only exceptions)
  const displayedInventory = showExceptionsOnly
    ? filteredInventory.filter(
        (product) => !product.restrictionDetails?.isEligible,
      )
    : filteredInventory;

  const allSelected = Array.isArray(displayedInventory)
    ? displayedInventory
        .filter(
          (product) =>
            product.restrictionDetails?.isEligible ||
            !product?.restrictionDetails,
        )
        .every((product) => selectedInventoryIds.includes(product.id))
    : false;

  const isInventoryHavingHazmatProduct = filteredInventory.some(
    (product) => product.restrictionDetails?.isEligible === false,
  );

  const filteredInventoryWithSearch = useMemo(() => {
    if (!searchTerm || searchTerm.trim() === "") {
      return displayedInventory;
    }

    const searchTermLower = searchTerm.toLowerCase().trim();

    return displayedInventory.filter((item) => {
      // Search through key product fields
      return (
        item.name?.toLowerCase().includes(searchTermLower) ||
        item.sku?.toLowerCase().includes(searchTermLower) ||
        item.fnSku?.toLowerCase().includes(searchTermLower) ||
        item.asin?.toLowerCase().includes(searchTermLower) ||
        item.lotId?.toLowerCase().includes(searchTermLower)
      );
    });
  }, [displayedInventory, searchTerm]);

  const inventoryColumns = [
    {
      key: "select",
      title: showExceptionsOnly ? null : (
        <CheckBox
          checked={allSelected}
          onChange={(e) => {
            const checked = e.target.checked;
            if (checked) {
              setSelectedInventoryIds(
                displayedInventory
                  ?.filter(
                    (product) =>
                      product.restrictionDetails?.isEligible ||
                      !product?.restrictionDetails,
                  )
                  .map((product) => product.id),
              );
            } else {
              setSelectedInventoryIds([]);
            }
          }}
        />
      ),
      stickPosition: "left",
    },
    { key: "product", title: "Product Name" },
    { key: "expiryDate", title: "Expiry Date" },
    { key: "unitsAvailable", title: "Units Available" },
    { key: "unitsToShip", title: "Units to Ship" },
  ];

  return (
    <div className="flex h-full flex-col font-inter">
      <div className="flex flex-col px-8">
        {isInventoryHavingHazmatProduct && (
          <CustomAlert
            id="warningForHazmatProducts"
            type="warning"
            message={`Attention: Some products require hazmat review before shipping. Red warnings may indicate ineligibility. Provide all necessary details.`}
            options={{
              defaultColors: false,
              bgColor: "bg-yellow-800",
              textColor: "text-green-700",
              titleColor: "text-gray-800",
              borderColor: "border-gray-100",
            }}
          />
        )}
        <div className="mb-2">
          <p className="text-base font-semibold">Client Name: {client?.name}</p>
        </div>
        {isInventoryHavingHazmatProduct && (
          <div className="mb-2 flex items-center">
            <CustomSwitch
              leftSideText="All Products"
              rightSideText="Products with Exceptions"
              checked={showExceptionsOnly}
              onChange={(checked) => {
                setShowExceptionsOnly(checked);
              }}
              leftColor="bg-green-500"
              rightColor="bg-red-500"
              labelClasses="text-gray-700 font-medium mr-2"
              switchClasses="w-10 h-6"
              handleClasses="w-4 h-4"
            />
          </div>
        )}
        {selectedInventoryIds.length ? (
          <div className="mb-2">
            <p className="text-sm text-gray-800">
              Note: Inventory for the selected products will be blocked, and a
              parent order will be created in Hopstack, which will later be
              split into shipment orders.
            </p>
          </div>
        ) : null}
        {/* <div className="flex-1"> */} {/* This will take remaining space */}{" "}
        {/* Adjust 300px as needed */}
        <CustomTable
          columns={inventoryColumns}
          data={filteredInventoryWithSearch}
          renderRow={(record) => (
            <FbaProductRow
              record={record}
              setFilteredInventory={setFilteredInventory}
              setSelectedInventoryIds={setSelectedInventoryIds}
              selectedInventoryIds={selectedInventoryIds}
            />
          )}
          isSearchable
          searchTerm={searchTerm}
          isFilters={false}
          isPagination={false}
          isInfiniteScroll
          onChangeSearchTerm={handleSearch}
          searchPlaceholder={"Search by ASIN/SKU/FNSKU/Name"}
          loadingData={false}
          // totalDataCount={""
          pageSizeOptions={["5", "10", "20"]}
          totalDataCountLable={
            <div className="flex items-center gap-6">
              <span className="text-sm font-medium">
                Total Products: {displayedInventory?.length || 0}
              </span>
              <span className="text-sm font-medium">
                Selected Products: {selectedInventoryIds.length}
              </span>
              <span className="text-sm font-medium">
                Selected Quantity:{" "}
                {filteredInventory
                  .filter((item) => selectedInventoryIds.includes(item.id))
                  .reduce((sum, item) => sum + (item.unitsToShip || 0), 0)}
              </span>
            </div>
          }
        />
        {/* </div> */}
        <div className="sticky bottom-0 border-t bg-white px-8 py-4">
          <div className="flex justify-end gap-6">
            <PrimaryButton
              height="3rem"
              width="7rem"
              className="mt-2 text-base font-medium"
              onClick={() => {
                history.push("/fbaInbound");
                handleResetFetchPlans();
              }}>
              Cancel
            </PrimaryButton>
            {selectedPlan?.status ===
              planStatusesEnums.ELIGIBILITY_CHECK_COMPLETED.key ||
            (eligibilityCheckResults.isFulfilled &&
              eligibilityCheckResults?.data?.status === "SUCCESS") ? (
              <PrimaryButton
                height="3rem"
                minWidth="13rem"
                maxWidth="20rem"
                variant="primary"
                className="mt-2 text-base font-medium"
                disabled={selectedInventoryIds.length === 0}
                onClick={() => {
                  setProceedWithPickingModalOpen(true);
                }}>
                Proceed to Picking
              </PrimaryButton>
            ) : (
              <PrimaryButton
                height="3rem"
                minWidth="13rem"
                maxWidth="20rem"
                variant="primary"
                className="mt-2 text-base font-medium"
                disabled={
                  selectedInventoryIds.length === 0 ||
                  selectedPlan?.status ===
                    planStatusesEnums.ELIGIBILITY_CHECK_PENDING.key
                }
                onClick={() => setEligibilityModalOpen(true)}>
                Run Eligibility Check
              </PrimaryButton>
            )}
          </div>
        </div>
      </div>

      <FbaConfirmationModals
        isOpen={eligibilityModalOpen}
        onClose={() => {
          setEligibilityModalOpen(false);
        }}
        title="Check Eligibility"
        subtitle=""
        description="Verifying your products for FBA eligibility. This may take a few
            minutes. You can stay here or leave; we’ll notify you when it’s
            complete."
        primaryButtonText="Stay on this Page"
        primaryButtonAction={() => {
          handleEligibilityCheck(true);
          setEligibilityModalOpen(false);
        }}
        secondaryButtonText="Notify Me Once Ready"
        secondaryButtonAction={() => {
          handleEligibilityCheck(false);
          setEligibilityModalOpen(false);
        }}
      />
      <FbaConfirmationModals
        isOpen={proceedWithPickingModalOpen}
        onClose={() => {
          setProceedWithPickingModalOpen(false);
        }}
        title="Would you like to proceed with picking each item, or mark all items as picked and continue with the shipment process?"
        subtitle=""
        description="This choice lets you either go through the detailed picking process
            for each item or quickly mark all items as ready, moving you forward
            in the shipment flow."
        primaryButtonText="Mark All Items as Picked"
        primaryButtonAction={() => {
          // Mark items as picked action
          handleStartFbaInboundShipmentPlan(true);
        }}
        secondaryButtonText="Proceed with Picking"
        secondaryButtonAction={() => {
          // Proceed with picking action
          handleStartFbaInboundShipmentPlan(false);
        }}
      />
    </div>
  );
};

export default FbaConfirmationProducts;
