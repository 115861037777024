import { useState, useEffect, useContext, useMemo, useRef } from "react";
import { useQuery } from "#hooks/useQuery";
import dayjs from "dayjs";
import {
  GET_ORDERS,
  GET_ORDERS_FULFILLMENT_DETAILS,
  GET_ORDER_MASTER_DATA,
  GET_AUDIT,
  CALCULATE_SHIPPING_RATES,
  FETCH_SPECIFIC_ORDER,
  GET_USERS,
  GET_CUSTOMERS,
  UPLOAD_ORDERS,
  GET_INVENTORY_FOR_LEDGER,
  GET_MARKETPLACE_PRODUCTS,
  GET_INTEGRATIONS,
  GET_ORDER_FIELDS,
  GET_FBA_SHIPMENTS,
  GET_CUSTOMER,
  CHECK_IF_ORDER_WAREHOUSE_CAN_BE_CHANGED,
  GET_NON_EDITABLE_FIELDS_FOR_ORDER,
  GET_BATCH_SETTING,
  GET_STATIONS,
  GET_ALL_DATA_TABLE_FILTERS,
  FETCH_SPECIFIC_ORDER_BATCH,
  GET_ORDER_CHANGES_PREVIEW,
  GET_PRODUCTS,
  GET_BACK_ORDER_DETAILS,
  GET_SPLIT_ORDER_DETAILS,
  CRM_CUSTOMERS,
  VALIDATE_PRODUCT_FOR_HARDINESS_ZONE,
  CREATE_AVALARA_TAX_INVOICE,
  GET_ORDER_FIELDS_V2,
} from "#queries";
import {
  CREATE_MANUAL_ORDER_BATCH,
  RUN_COMBINED_ORDERS,
  CREATE_AUTO_ORDER_BATCHES,
  SIMULATE_AUTO_ORDER_BATCHES,
  MANUALLY_MARK_ORDERS,
  UPDATE_ORDER_TRACKING_INFO,
  UPDATE_ORDER_CARRIER_INFO,
  UPDATE_ORDER_LINE_ITEM,
  SAVE_ORDER,
  SAVE_BULK_ORDER,
  UPDATE_ORDER,
  DELETE_ORDER,
  BULK_UPLOAD_ORDER,
  CANCEL_ORDERS,
  ASSIGN_WAREHOUSE_TO_ORDER,
  MARK_ORDER_SHIPPED_ON_MARKETPLACE,
  IMPORT_ORDERS_FROM_MARKETPLACE,
  SAVE_BATCH_SETTINGS,
  LABEL_EVENT,
  CREATE_SALES_ORDER,
  CREATE_CRM_CUSTOMER,
  GET_PAST_BULK_UPLOADS,
} from "#mutations";
import _, { set } from "lodash";
import { AppStateContext } from "#contexts/appState";
import { EntityContext } from "#contexts/entity";
import { AuthContext } from "#contexts/auth";
import { printShippingLabel } from "#utils/printShippingLabel";
import { printBol } from "#utils/printBol";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import GoodsShippedNote from "#components/orders/GoodsShippedNote";
import { CREATE_BACK_ORDER, UPDATE_FBA_SHIPMENT } from "../../mutations";
import { BulkValidationQueryGenerator } from "../../queries/bulkValidationQueryGenerator";
import { printThirtyUpLabel } from "#utils/printThirtyUpLabel";
import { printCanvasLabelPdf } from "#utils/printCanvasLabelPdf";
import {
  GET_BATCHING_ATTEMPTS,
  GET_SEARCH_FILTERS,
  GET_SKU_BIN_MAPPINGS,
  GET_WAREHOUSE,
  SEARCH_ORDERS,
  SEARCH_PRODUCTS,
} from "../../queries";
import { downloadPackingLabels } from "../../utils/printPackingLabel";
import { TENANT_SHIPPING_LABEL_PRINTER_ENUM } from "../../constants/printer-settings";
import { getPrintFiles } from "../../components/HOC/withPackerLogic";
import Papa from "papaparse";
import {
  isDelmarTenant,
  isProprepTenant,
  isVanEngelenTenant,
} from "#utils/tenantCheck";
import moment from "moment-timezone";
import { OrderSource } from "../../utils/enums/orderSourceEnum";
import CustomNotification from "#newUiComponents/commons/CustomNotification";
import {
  CheckCircleIcon,
  InformationCircleIcon,
  XCircleIcon,
} from "@heroicons/react/solid";
import PackingSlip from "#components/orders/PackingSlip";
import { ModalContext } from "#newUiComponents/bulkUploadV2/useReducer";
import { BANNER_TYPE_ENUM } from "#newUiComponents/commons/ExportBanner";
import {
  ACTION_TYPES,
  ACTIONS_TYPE_ENUM,
  POLLING_TIMER,
  STATUS_ENUM,
  BULK_UPLOAD_KEYS,
  BULK_UPLOAD_ENTITY_TYPES,
} from "#newUiComponents/bulkUploadV2/useReducer";
import { useFeatureFlags } from "#contexts/featureFlags";

const ALERT_VISIBILITY_IN_MS = 5000; // 5 seconds
const TIME_INTERVAL_FOR_POLLING = 60000; //Time after which we fetch data from the backend to check if any new orders have been added :-60 seconds//
const TIME_FOR_REMOVAL_FROM_LOCAL_STORAGE = 60000; //Time after which the "new" orders are removed fromt the local storage
const POLLING_INTERVAL_FOR_BATCHING_STATUS = 3000; // Time after which we poll the api to check the status of order batching
const POLLING_COUNTER_LIMIT = 1200; // Count after which polling will stop, time limit of 1 hour

const SALES_ORDER_MODES = {
  VIEW: "view",
  CREATE: "create",
};

export const ORDER_ACTIONS_TYPE_ENUM = {
  CREATE_SALES_ORDER: "CREATE_SALES_ORDER",
  CREATE_REGULAR_ORDER: "CREATE_REGULAR_ORDER",
  CREATE_STOCK_TRANSFER_ORDER: "CREATE_STOCK_TRANSFER_ORDER",
  EXPORT_REPORT: "EXPORT_REPORT",
  IMPORT_FBA_SHIPMENT: "IMPORT_FBA_SHIPMENT",
};
export const BULK_UPLOAD_DUPLICATE_VALIDATION = [];
export const BULK_UPLOAD_DUPLICATE_ROW_VALIDATION = [];

const withOrdersLogic = (WrappedComponent) => {
  return (props) => {
    const [dataTableFilters, setDataTableFilters] = useState({});
    const [productSkuBinMappings, setProductSkuBinMappings] = useState(null);
    const [
      productSkuBinMappingsWithExpiryDate,
      setProductSkuBinMappingsWithExpiryDate,
    ] = useState(null);
    const [productSkuBinMappingsWithLpn, setProductSkuBinMappingsWithLpn] =
      useState(null);
    const [
      productSkuBinMappingsWithSerialNumber,
      setProductSkuBinMappingsWithSerialNumber,
    ] = useState(null);
    const [productSkuBinMappingsWithPoId, setProductSkuBinMappingsWithPoId] =
      useState(null);
    const [
      productSkuBinMappingsWithPalletId,
      setProductSkuBinMappingsWithPalletId,
    ] = useState(null);
    const skuBinMappingsQuery = useQuery(GET_SKU_BIN_MAPPINGS);
    const searchProductsQuery = useQuery(SEARCH_PRODUCTS);
    const [batchSettings, setBatchSettings] = useState({});
    const [fileIndex, setFileIndex] = useState(Math.random() * 100);
    const [bolDoc, setBolDoc] = useState(null);
    const [shippingAddress, setShippingAddress] = useState(null);
    const [trackingUpdate, setTrackingUpdate] = useState(null);
    const [carrierUpdate, setCarrierUpdate] = useState(null);
    const [deliveryInfo, setDeliveryInfo] = useState(null);
    const [availableInventory, setAvailableInventory] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [isOrderBeingCreated, setOrderBeingCreated] = useState(false);
    const [isOrderBeingEdited, setOrderBeingEdited] = useState(false);
    const [newlyAddedOrders, setNewlyAddedOrders] = useState(null);
    const [nonEditableFieldsOfOrder, setNonEditableFieldsOfOrder] = useState(
      [],
    );
    const [selectedOrderLineItem, setSelectedOrderLineItem] = useState(null);
    const auth = useContext(AuthContext);
    const [shipmentPlan, setShipmentPlan] = useState(null);

    const [pickers, setPickers] = useState([]);
    const [selectedPicker, setSelectedPicker] = useState(null);
    const entity = useContext(EntityContext);
    const [simulatedBatches, setSimulatedBatches] = useState([]);
    const [showManualBatchCreationDialog, setManualBatchCreationDialog] =
      useState(null);
    const [batchDateRange, setBatchDateRange] = useState(null);
    const [rates, setSelectedRates] = useState(null);
    const [splitOrderDetails, setSplitOrderDetails] = useState(null);
    const usersQuery = useQuery(GET_USERS);
    const getPickersForWarehouseQuery = useQuery(GET_USERS);
    const getAuditUsersQuery = useQuery(GET_USERS);
    const createManualBatchQuery = useQuery(CREATE_MANUAL_ORDER_BATCH);
    const createAutoBatchesQuery = useQuery(CREATE_AUTO_ORDER_BATCHES);
    const simulateAutoBatchesQuery = useQuery(SIMULATE_AUTO_ORDER_BATCHES);
    const cancelOrdersQuery = useQuery(CANCEL_ORDERS);
    const labelEventQuery = useQuery(LABEL_EVENT);
    const runCombinedOrders = useQuery(RUN_COMBINED_ORDERS);
    const fetchSpecificOrder = useQuery(FETCH_SPECIFIC_ORDER);
    const fetchOrderForCarrierUpdate = useQuery(FETCH_SPECIFIC_ORDER);
    const getOrderForBolDownload = useQuery(FETCH_SPECIFIC_ORDER);
    const getOrderForBolPrint = useQuery(FETCH_SPECIFIC_ORDER);
    const getOrderForBolBuild = useQuery(FETCH_SPECIFIC_ORDER);
    const fetchSpecificOrderBatchQuery = useQuery(FETCH_SPECIFIC_ORDER_BATCH);
    const getSearchFiltersQuery = useQuery(GET_SEARCH_FILTERS);
    const searchOrdersQuery = useQuery(SEARCH_ORDERS);

    // Adding separate query for fetching packing batch to generate packing list csv
    const fetchOrderBatchForPackingList = useQuery(FETCH_SPECIFIC_ORDER_BATCH);
    // this product query is for fetching products to generate packing list csv
    const fetchProductsForPackingList = useQuery(GET_PRODUCTS);
    const customersQuery = useQuery(GET_CUSTOMERS);
    const getCustomerQuery = useQuery(GET_CUSTOMER);
    const [selectedOrders, setSelectedOrders] = useState([]);
    const [combinedOrders, setCombinedOrders] = useState([]);
    const [selectedAudit, setSelectedAudit] = useState(null);
    const [selectedAuditUsersInfo, setSelectedAuditUsersInfo] = useState(null);
    const [showAudit, setShowAudit] = useState(false);
    const [mostRecentOrderId, setMostRecentOrderId] = useState(null);
    const [newOrdersAvailable, setNewOrdersAvailable] = useState(false);
    const [newOrders, setNewOrders] = useState([]);
    const [lastCheckedOrderId, setLastCheckedOrderId] = useState(null);
    const [auditOrderId, setAuditOrderId] = useState(null);
    const [expandedOrderInfo, setExpandedOrderInfo] = useState(null);
    const [selectedCombinedOrder, setShowCombinedOrders] = useState(null);
    const masterDataQuery = useQuery(GET_ORDER_MASTER_DATA);
    const getAuditQuery = useQuery(GET_AUDIT);
    const getShippingRates = useQuery(CALCULATE_SHIPPING_RATES);
    const manuallyMarkOrdersQuery = useQuery(MANUALLY_MARK_ORDERS);
    const inventoryQuery = useQuery(GET_INVENTORY_FOR_LEDGER);
    const [loading, setLoading] = useState(true);
    const appState = useContext(AppStateContext);
    const ordersQuery = useQuery(GET_ORDERS);
    const uploadOrdersQuery = useQuery(UPLOAD_ORDERS);
    const updateTrackingInfoQuery = useQuery(UPDATE_ORDER_TRACKING_INFO);
    const updateCarrierInfoQuery = useQuery(UPDATE_ORDER_CARRIER_INFO);
    const updateBolDataQuery = useQuery(UPDATE_ORDER);
    const updateOrderLineItemQuery = useQuery(UPDATE_ORDER_LINE_ITEM);
    const [showFilters, setShowFilters] = useState(false);
    const saveOrderQuery = useQuery(SAVE_ORDER);
    const updateOrderQuery = useQuery(UPDATE_ORDER);
    const getMarketplaceProductsQuery = useQuery(GET_MARKETPLACE_PRODUCTS);
    const integrationsQuery = useQuery(GET_INTEGRATIONS);
    const importFbaIntegrationsQuery = useQuery(GET_INTEGRATIONS);
    const saveBulkOrderQuery = useQuery(SAVE_BULK_ORDER);
    const deleteOrderQuery = useQuery(DELETE_ORDER);
    const downloadOrderReportQuery = useQuery(FETCH_SPECIFIC_ORDER);
    const markOrderCompleteOnMarketplaceQuery = useQuery(
      MARK_ORDER_SHIPPED_ON_MARKETPLACE,
    );

    const getOrderFields = useQuery(GET_ORDER_FIELDS);
    const getNonEditableFieldsForOrderQuery = useQuery(
      GET_NON_EDITABLE_FIELDS_FOR_ORDER,
    );
    const getOrderDetailsForEditingQuery = useQuery(FETCH_SPECIFIC_ORDER);
    const uploadBulk = useQuery(BULK_UPLOAD_ORDER);
    const getFBAShipments = useQuery(GET_FBA_SHIPMENTS);
    const updateFBAShipment = useQuery(UPDATE_FBA_SHIPMENT);
    const assignWarehouseQuery = useQuery(ASSIGN_WAREHOUSE_TO_ORDER);
    const checkIfOrderWarehouseCanBeChangedQuery = useQuery(
      CHECK_IF_ORDER_WAREHOUSE_CAN_BE_CHANGED,
    );
    const getWarehouseQuery = useQuery(GET_WAREHOUSE);
    const createBackOrderQuery = useQuery(CREATE_BACK_ORDER);

    const [orderToMarkCancelled, setOrderToMarkCancelled] = useState(null);
    const [
      markCancelledOnMarketplaceCheck,
      setMarkCancelledOnMarketplaceCheck,
    ] = useState(false);
    const [orderToMarkComplete, setOrderToMarkComplete] = useState(null);
    const [bulkOrdersToMarkComplete, setBulkOrdersToMarkComplete] = useState(
      [],
    );
    const [bulkOrdersToMarkCancelled, setBulkOrdersToMarkCancelled] = useState(
      [],
    );
    const [markCompleteOnMarketplaceCheck, setMarkCompleteOnMarketplaceCheck] =
      useState(false);
    const [orderToMarkShipped, setOrderToMarkShipped] = useState(null);
    const [dashboardFields, setDashboardFields] = useState(null);
    const [finalError, setFinalError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [lastSelectedOrder, setLastSelectedOrder] = useState(null);
    const [allRowsSelected, setAllRowsSelected] = useState(false);
    const [selectedWarehouse, setSelectedWarehouse] = useState(null);
    const [availableWarehousesForChange, setAvailableWarehousesForChange] =
      useState([]);
    const [selectedSkus, setSelectedSkus] = useState([]);
    const [selectedOrderForChange, setSelectedOrderForChange] = useState(null);
    const [cannotChangeOrderWarehouse, setCannotChangeOrderWarehouse] =
      useState(null);

    const [fbaOrderSelected, setFbaOrderSelected] = useState({});
    const bulkUploadValidation = useQuery(
      BulkValidationQueryGenerator({ keyword: "Orders" }),
    );
    const [validationResult, setValidationResult] = useState(null);
    const [fnSkuOptions, setFnSkuOptions] = useState(null);

    const getFnSkuLabelsQuery = useQuery(FETCH_SPECIFIC_ORDER);
    const [showOnlySelected, setShowOnlySelected] = useState(false);
    const importOrdersFromMarketPlacesQuery = useQuery(
      IMPORT_ORDERS_FROM_MARKETPLACE,
    );
    const [importFbaShipmentModal, setImportFbaShipmentModal] = useState(false);
    const [importShipment, setImportShipment] = useState(null);
    const [shipmentImportSearch, setShipmentImportSearch] = useState(null);
    const [importFilters, setImportFilters] = useState({});
    const [showBatchSettings, setShowBatchSettings] = useState(false);

    const batchSettingsQuery = useQuery(GET_BATCH_SETTING);
    const saveBatchSettingsQuery = useQuery(SAVE_BATCH_SETTINGS);

    const fetchOrderForPrintingShippingLabel = useQuery(FETCH_SPECIFIC_ORDER);
    const fetchOrderWithLabelsQuery = useQuery(FETCH_SPECIFIC_ORDER);
    const fetchOrderForDownloadingShippingLabel =
      useQuery(FETCH_SPECIFIC_ORDER);

    const fetchOrderForPrintingBoxLabels = useQuery(FETCH_SPECIFIC_ORDER);
    const fetchOrderForDownloadingBoxLabels = useQuery(FETCH_SPECIFIC_ORDER);

    const fetchOrderForPrintingPalletLabels = useQuery(FETCH_SPECIFIC_ORDER);
    const fetchOrderForDownloadingPalletLabels = useQuery(FETCH_SPECIFIC_ORDER);
    const getOrderFulfillmentDetailsQuery = useQuery(
      GET_ORDERS_FULFILLMENT_DETAILS,
    );
    const [ordersFulfillmentDetails, setOrdersFulfillmentDetails] =
      useState(null);

    const [stations, setStations] = useState([]);
    const stationsQuery = useQuery(GET_STATIONS);
    const [manualBatchConfig, setManualBatchConfig] = useState(null);
    const [multiAccountSupportEnabled, setMultiAccountSupportEnabled] =
      useState(false);
    const [marketplaces, setMarketplaces] = useState([]);
    const [sellerIds, setSellerIds] = useState([]);
    const [amazonIntegrations, setAmazonIntegrations] = useState([]);
    const getAllDataTableFiltersQuery = useQuery(GET_ALL_DATA_TABLE_FILTERS);
    const [packingLabelAction, setPackingLabelAction] = useState(null);
    const getOrderChangesPreviewQuery = useQuery(GET_ORDER_CHANGES_PREVIEW);
    const [orderChangesPreview, setOrderChangesPreview] = useState(null);
    const [isBackOrderEnabledForTenant, setBackOrderEnabledForTenant] =
      useState(false);
    const [showBatchingPreview, setShowBatchingPreview] = useState(true);
    const [showBatchingPrioritisation, setShowBatchingPrioritisation] =
      useState(false);
    const [refetchOrderDetails, setRefetchOrderDetails] = useState(false);
    const [updatedOrderBatchingDetails, setUpdatedOrderBatchingDetails] =
      useState([]);

    const getBackOrderDetailsQuery = useQuery(GET_BACK_ORDER_DETAILS);
    const getSplitOrderDetailsQuery = useQuery(GET_SPLIT_ORDER_DETAILS);
    const [selectedOrderNotes, setSelectedOrderNotes] = useState(null);
    const [isManualBatchExecuting, setIsManualBatchExecuting] = useState(false);
    const [selectedSalesOrder, setSelectedSalesOrder] = useState(null);
    const productsQuery = useQuery(GET_PRODUCTS);
    const [products, setProducts] = useState(null);
    const [cart, setCart] = useState(null);
    const createSalesOrderQuery = useQuery(CREATE_SALES_ORDER);
    const createCRMCustomerQuery = useQuery(CREATE_CRM_CUSTOMER);
    const updateCRMCustomerQuery = useQuery(CREATE_CRM_CUSTOMER);
    const [newCustomer, setNewCustomer] = useState({
      accountAddress: { country: "US" },
    });
    const crmCustomersQuery = useQuery(CRM_CUSTOMERS);
    const [crmCustomers, setCrmCustomers] = useState(null);
    const validateProductForHZQuery = useQuery(
      VALIDATE_PRODUCT_FOR_HARDINESS_ZONE,
    );
    const [productForCart, setProductForCart] = useState(null);
    const getAvalaraTaxInvoiceQuery = useQuery(CREATE_AVALARA_TAX_INVOICE);
    const [avalaraTaxInvoice, setAvalaraTaxInvoice] = useState(null);
    const [salesOrderMode, setSalesOrderMode] = useState(null);
    const [addCustomerPanel, setAddCustomerPanel] = useState(false);
    const [salesOrderIds, setSalesOrderIds] = useState([]);

    const [currentOrder, setCurrentOrder] = useState("");

    const notify = CustomNotification();

    const getBatchingAttemptsQuery = useQuery(GET_BATCHING_ATTEMPTS);
    const [batchingAttemptIds, setBatchingAttemptIds] = useState(
      JSON.parse(localStorage.getItem("batchingAttemptIds")) || [],
    );
    const [pollCounter, setPollCounter] = useState(0);
    const [batchingAlertDetails, setBatchingAlertDetails] = useState({
      title: "",
      subTitle: "",
      bgColor: "",
      titleColor: "",
      textColor: "",
      borderColor: "",
      duration: "",
      id: "",
      icon: null,
    });
    const [searchFilters, setSearchFilters] = useState([]);
    const [searchResults, setSearchResults] = useState({});
    const [currentPage, setCurrentPage] = useState(0);
    const [enhancedSearchText, setEnhancedSearchText] = useState("");
    const [showEnhancedSearch, setShowEnhancedSearch] = useState(false);

    const bulkUploadEntity = useContext(ModalContext);
    const getOrdersFieldsV2 = useQuery(GET_ORDER_FIELDS_V2);
    const listPastBulkUploadQuery = useQuery(GET_PAST_BULK_UPLOADS);
    const [orderDashboardFieldsV2, setOrderDashboardFieldsV2] = useState(null);
    const [orderActionTypes, setOrderActionTypes] = useState(null);
    const [orderActionTypesEnum, setOrderActionTypesEnum] = useState(null);
    const [showBulkUpload, setShowBulkUpload] = useState(false);
    const [selectedActionType, setSelectedActionType] = useState(null);
    const [showBanner, setShowBanner] = useState(false);
    const bulkUploadId = localStorage.getItem(BULK_UPLOAD_KEYS?.ORDER_ID);
    const selectedBulkUploadFile = localStorage.getItem(
      BULK_UPLOAD_KEYS?.ORDER_FILE,
    );
    const [typeOfBanner, setTypeOfBanner] = useState(null);
    const [bolBuildData, setBolBuildData] = useState(null);

    const {
      salesPortalEnabled: createSalesOrderEnabled,
      batchSettingsEnabled,
      lotIdSelectionEnabled,
      bolUploadEnabled,
      fbaSkuLabelGeneration,
      csvPackingListDownloadEnabled,
      lpnSelectionEnabled,
      warehouseBulkUpload: bulkUploadV2Enabled,
    } = useFeatureFlags();

    useEffect(() => {
      const tenantSettings = appState?.tenant?.settings;
      if (
        tenantSettings?.multiAccountIntegrationSupportEnabled &&
        !multiAccountSupportEnabled
      ) {
        setMultiAccountSupportEnabled(true);
      }
      if (tenantSettings?.backOrderEnabled) {
        setBackOrderEnabledForTenant(true);
      }
      if (tenantSettings?.skipBatchingPreview) {
        setShowBatchingPreview(false);
      }
    }, [appState]);

    useEffect(() => {
      if (stationsQuery.data) {
        setStations(stationsQuery.data.stations.entities);
      }

      if (stationsQuery.error) {
        setStations([]);
      }
    }, [stationsQuery.data, stationsQuery.error, stationsQuery.loading]);

    useEffect(() => {
      if (getPickersForWarehouseQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (getPickersForWarehouseQuery.data) {
        setPickers(getPickersForWarehouseQuery.data.users.entities);
      }

      if (getPickersForWarehouseQuery.error) {
        appState.setAlert(
          getPickersForWarehouseQuery.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
        setPickers([]);
      }
    }, [
      getPickersForWarehouseQuery.loading,
      getPickersForWarehouseQuery.data,
      getPickersForWarehouseQuery.error,
    ]);

    useEffect(() => {
      if (fetchOrderForPrintingShippingLabel.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (fetchOrderForPrintingShippingLabel.data) {
        const labelsToPrint = [];
        if (
          fetchOrderForPrintingShippingLabel.data.order.shippingLabels?.length >
          0
        ) {
          labelsToPrint.push(
            ...fetchOrderForPrintingShippingLabel.data.order.shippingLabels,
          );
        } else if (
          fetchOrderForPrintingShippingLabel.data.order.shippingLabel
        ) {
          labelsToPrint.push(
            fetchOrderForPrintingShippingLabel.data.order.shippingLabel,
          );
        }

        for (const shippingLabel of labelsToPrint) {
          printShippingLabel(shippingLabel, appState);
          labelEventQuery.fetchData({
            warehouse: fetchOrderForPrintingShippingLabel.data.order.warehouse,
            customer: fetchOrderForPrintingShippingLabel.data.order.customer,
            order: fetchOrderForPrintingShippingLabel.data.order.id,
            quantity: 1,
            labelType: "SHIPPING_LABEL",
            dropship: false,
          });
        }
      }
    }, [
      fetchOrderForPrintingShippingLabel.data,
      fetchOrderForPrintingShippingLabel.loading,
    ]);

    useEffect(() => {
      if (fetchOrderForPrintingBoxLabels.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (fetchOrderForPrintingBoxLabels.data) {
        const labelsToPrint = [];
        if (fetchOrderForPrintingBoxLabels.data.order.boxLabels) {
          labelsToPrint.push(
            fetchOrderForPrintingBoxLabels.data.order.shippingLabels,
          );
        }
        for (const label of labelsToPrint) {
          printShippingLabel(label, appState);
          labelEventQuery.fetchData({
            warehouse: fetchOrderForPrintingBoxLabels.data.order.warehouse,
            customer: fetchOrderForPrintingBoxLabels.data.order.customer,
            order: fetchOrderForPrintingBoxLabels.data.order.id,
            quantity: 1,
            labelType: "SHIPPING_LABEL",
            dropship: false,
          });
        }
      }
    }, [
      fetchOrderForPrintingBoxLabels.data,
      fetchOrderForPrintingBoxLabels.loading,
    ]);
    useEffect(() => {
      if (fetchOrderForDownloadingShippingLabel.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (fetchOrderForDownloadingShippingLabel.data) {
        const labelsToDownload = [];
        if (
          fetchOrderForDownloadingShippingLabel.data.order.shippingLabels
            ?.length > 0
        ) {
          labelsToDownload.push(
            ...fetchOrderForDownloadingShippingLabel.data.order.shippingLabels,
          );
        } else if (
          fetchOrderForDownloadingShippingLabel.data.order.shippingLabel
        ) {
          labelsToDownload.push(
            fetchOrderForDownloadingShippingLabel.data.order.shippingLabel,
          );
        }

        for (const shippingLabelUrl of labelsToDownload) {
          saveAs(shippingLabelUrl);
        }
      }

      if (fetchOrderForDownloadingShippingLabel.error) {
        appState.setAlert(
          fetchOrderForDownloadingShippingLabel.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }
    }, [
      fetchOrderForDownloadingShippingLabel.data,
      fetchOrderForDownloadingShippingLabel.loading,
    ]);

    useEffect(() => {
      if (fetchOrderForDownloadingBoxLabels.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (fetchOrderForDownloadingBoxLabels.data) {
        const labelsToDownload = [];
        if (fetchOrderForDownloadingBoxLabels.data.order.boxLabels) {
          labelsToDownload.push(
            fetchOrderForDownloadingBoxLabels.data.order.boxLabels,
          );
        }

        for (const label of labelsToDownload) {
          saveAs(label);
        }
      }

      if (fetchOrderForDownloadingBoxLabels.error) {
        appState.setAlert(
          fetchOrderForDownloadingBoxLabels.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }
    }, [
      fetchOrderForDownloadingBoxLabels.data,
      fetchOrderForDownloadingBoxLabels.loading,
    ]);

    useEffect(() => {
      if (fetchOrderForPrintingPalletLabels.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (fetchOrderForPrintingPalletLabels.data) {
        const labelsToDownload = [];
        if (fetchOrderForPrintingPalletLabels.data.order.palletLabels) {
          labelsToDownload.push(
            fetchOrderForPrintingPalletLabels.data.order.palletLabels,
          );
        }

        for (const label of labelsToDownload) {
          saveAs(label);
        }
      }

      if (fetchOrderForPrintingPalletLabels.error) {
        appState.setAlert(
          fetchOrderForPrintingPalletLabels.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }
    }, [
      fetchOrderForPrintingPalletLabels.data,
      fetchOrderForPrintingPalletLabels.loading,
    ]);

    useEffect(() => {
      if (fetchOrderForDownloadingPalletLabels.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (fetchOrderForDownloadingPalletLabels.data) {
        const labelsToDownload = [];
        if (fetchOrderForDownloadingPalletLabels.data.order.palletLabels) {
          labelsToDownload.push(
            fetchOrderForDownloadingPalletLabels.data.order.palletLabels,
          );
        }

        for (const label of labelsToDownload) {
          saveAs(label);
        }
      }

      if (fetchOrderForDownloadingPalletLabels.error) {
        appState.setAlert(
          fetchOrderForDownloadingPalletLabels.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }
    }, [
      fetchOrderForDownloadingPalletLabels.data,
      fetchOrderForDownloadingPalletLabels.loading,
    ]);

    useEffect(() => {
      if (skuBinMappingsQuery.data) {
        const groupedByLotId = _.groupBy(
          skuBinMappingsQuery.data.skuBinMappings.entities.filter(
            (item) => !!item.lotId,
          ),
          "lotId",
        );
        const toSetProductSkuBinMappings = Object.keys(groupedByLotId).map(
          (lotId) => ({
            lotId,
            unallocatedQuantity: _.sumBy(
              groupedByLotId[lotId],
              "unallocatedQuantity",
            ),
            product: groupedByLotId[lotId][0].product,
          }),
        );
        setProductSkuBinMappings(toSetProductSkuBinMappings);

        const groupedByExpiryDate = _.groupBy(
          skuBinMappingsQuery.data.skuBinMappings.entities.filter(
            (item) => !!item.bestByDate,
          ),
          "bestByDate",
        );

        const toSetProductSkuBinMappingsWithExpiryDate = Object.keys(
          groupedByExpiryDate,
        )
          .map((expiryDate) => ({
            expiryDate,
            unallocatedQuantity: _.sumBy(
              groupedByExpiryDate[expiryDate],
              "unallocatedQuantity",
            ),
            product: groupedByExpiryDate[expiryDate][0].product,
          }))
          .filter((item) => item.unallocatedQuantity > 0);

        setProductSkuBinMappingsWithExpiryDate(
          toSetProductSkuBinMappingsWithExpiryDate,
        );

        const groupedByNestedFormFactorId = _.groupBy(
          skuBinMappingsQuery.data.skuBinMappings.entities.filter(
            (item) => !!item.nestedFormFactorId,
          ),
          "nestedFormFactorId",
        );
        const toSetProductSkuBinMappingsWithLpn = Object.keys(
          groupedByNestedFormFactorId,
        ).map((nestedFormFactorId) => ({
          nestedFormFactorId,
          unallocatedQuantity: _.sumBy(
            groupedByNestedFormFactorId[nestedFormFactorId],
            "unallocatedQuantity",
          ),
          product: groupedByNestedFormFactorId[nestedFormFactorId][0].product,
        }));
        setProductSkuBinMappingsWithLpn(toSetProductSkuBinMappingsWithLpn);

        const groupedBySerialNumber = _.groupBy(
          skuBinMappingsQuery.data.skuBinMappings.entities.filter(
            (item) => !!item.serialNumber,
          ),
          "serialNumber",
        );
        const toSetProductSkuBinMappingsWithSerialNumber = Object.keys(
          groupedBySerialNumber,
        ).map((serialNumber) => ({
          serialNumber,
          unallocatedQuantity: _.sumBy(
            groupedBySerialNumber[serialNumber],
            "unallocatedQuantity",
          ),
          product: groupedBySerialNumber[serialNumber][0].product,
        }));
        setProductSkuBinMappingsWithSerialNumber(
          toSetProductSkuBinMappingsWithSerialNumber,
        );

        const groupedByPoId = _.groupBy(
          skuBinMappingsQuery.data.skuBinMappings.entities.filter(
            (item) => !!item.poId,
          ),
          "poId",
        );
        const toSetProductSkuBinMappingsWithPoId = Object.keys(
          groupedByPoId,
        ).map((poId) => ({
          poId,
          unallocatedQuantity: _.sumBy(
            groupedByPoId[poId],
            "unallocatedQuantity",
          ),
          product: groupedByPoId[poId][0].product,
        }));
        setProductSkuBinMappingsWithPoId(toSetProductSkuBinMappingsWithPoId);

        const groupedByPalletId = _.groupBy(
          skuBinMappingsQuery.data.skuBinMappings.entities.filter(
            (item) => !!item.palletId,
          ),
          "palletId",
        );
        const toSetProductSkuBinMappingsWithPalletId = Object.keys(
          groupedByPalletId,
        ).map((palletId) => ({
          palletId,
          unallocatedQuantity: _.sumBy(
            groupedByPalletId[palletId],
            "unallocatedQuantity",
          ),
          product: groupedByPalletId[palletId][0].product,
        }));
        setProductSkuBinMappingsWithPalletId(
          toSetProductSkuBinMappingsWithPalletId,
        );
      }

      if (skuBinMappingsQuery.error) {
        appState.setAlert(
          skuBinMappingsQuery.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
        setProductSkuBinMappings(null);
      }
    }, [skuBinMappingsQuery.data, skuBinMappingsQuery.error]);

    useEffect(() => {
      if (selectedSkus?.length > 0) {
        entity.filters["sku"] = selectedSkus?.map(
          (sku) => sku.uniqueIdentifier,
        );
      } else {
        delete entity.filters["sku"];
      }
    }, [selectedSkus]);

    useEffect(() => {
      if (saveBatchSettingsQuery.data) {
        setShowBatchSettings(false);
        batchSettingsQuery.fetchData();
        appState.setAlert(
          saveBatchSettingsQuery.data.saveBatchSettings.message,
          "success",
          ALERT_VISIBILITY_IN_MS,
        );
      }

      if (saveBatchSettingsQuery.error) {
        appState.setAlert(
          saveBatchSettingsQuery.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }

      if (saveBatchSettingsQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
    }, [
      saveBatchSettingsQuery.loading,
      saveBatchSettingsQuery.data,
      saveBatchSettingsQuery.error,
    ]);

    /**
     * Perform side effects based on the state of `productsQuery`.
     * @param {Object} productsQuery - The object containing the query state.
     */
    useEffect(() => {
      if (productsQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
      if (productsQuery.data && productsQuery.data.products) {
        setProducts(productsQuery.data.products);
      }
    }, [productsQuery.error, productsQuery.data, productsQuery.loading]);

    useEffect(() => {
      batchSettingsQuery.fetchData();
    }, []);

    useEffect(() => {
      if (batchSettingsQuery.data) {
        setBatchSettings(batchSettingsQuery.data.getBatchSetting);
      }
    }, [batchSettingsQuery.data]);

    useEffect(() => {
      if (importOrdersFromMarketPlacesQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (importOrdersFromMarketPlacesQuery.data) {
        const responseData =
          importOrdersFromMarketPlacesQuery.data.importOrdersFromMarketplace[0];
        const status = responseData.isSavedSuccessfully;
        appState.setAlert(
          responseData.message,
          status ? "success" : "error",
          ALERT_VISIBILITY_IN_MS,
        );
        appState.hideConfirmation();
      }

      if (importOrdersFromMarketPlacesQuery.error) {
        appState.setAlert(
          importOrdersFromMarketPlacesQuery.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }
    }, [
      importOrdersFromMarketPlacesQuery.data,
      importOrdersFromMarketPlacesQuery.error,
      importOrdersFromMarketPlacesQuery.loading,
    ]);

    useEffect(() => {
      if (cancelOrdersQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (cancelOrdersQuery.data) {
        ordersQuery.fetchData({
          perPage: entity.perPage,
          filters: entity.filters,
          paginated: false,
          pageNumber: entity.pageNumber,
          sort: entity.sort,
        });
        appState.setAlert(
          cancelOrdersQuery.data.cancelOrders.message,
          "success",
          ALERT_VISIBILITY_IN_MS,
        );
        appState.hideConfirmation();
      }

      if (cancelOrdersQuery.error) {
        appState.setAlert(
          cancelOrdersQuery.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }
    }, [
      cancelOrdersQuery.data,
      cancelOrdersQuery.error,
      cancelOrdersQuery.loading,
    ]);

    useEffect(() => {
      if (getOrderChangesPreviewQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (getOrderChangesPreviewQuery.data) {
        setOrderChangesPreview(
          getOrderChangesPreviewQuery.data?.getOrderChangesPreview,
        );
      }

      if (getOrderChangesPreviewQuery.error) {
        appState.setAlert(
          getOrderChangesPreviewQuery.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }
    }, [
      getOrderChangesPreviewQuery.data,
      getOrderChangesPreviewQuery.error,
      getOrderChangesPreviewQuery.loading,
    ]);

    /**
     * Cancels the order with the provided ID by fetching it.
     * Also, marks the order to be cancelled for subsequent actions.
     *
     * @param {string|number} id - The ID of the order to be cancelled.
     */
    const cancelOrders = (id) => {
      fetchSpecificOrder.fetchData({ orderId: id });
      getOrderChangesPreviewQuery.fetchData({
        getOrderChangesPreviewId: id,
        operationType: "CANCELLED",
      });
      setOrderToMarkCancelled(id);
    };

    /**
     * Confirms the cancellation of the marked order.
     * Fetches data to cancel the order, resets related states,
     * and optionally marks the order as cancelled in the marketplace.
     */

    const fetchEnhancedSearchProductResults = (keyword) => {
      const payload = {
        keyword,
        field: "sku",
        filters: {
          customers: [],
          sources: [],
          typeOfProduct: "",
        },
      };
      return searchProductsQuery.fetchData(payload);
    };
    const confirmMarkCancelled = () => {
      cancelOrdersQuery.fetchData({
        ids: [orderToMarkCancelled],
        reason: `Order cancelled manually by ${auth.user.name}`,
        markCancelOnMarketplace: markCancelledOnMarketplaceCheck,
      });
      setOrderToMarkCancelled(null);
      setMarkCancelledOnMarketplaceCheck(false);
      setExpandedOrderInfo(null);
    };

    useEffect(() => {
      if (deleteOrderQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (deleteOrderQuery.data) {
        appState.setAlert(
          deleteOrderQuery.data.deleteOrder.message,
          "success",
          ALERT_VISIBILITY_IN_MS,
        );
        appState.hideConfirmation();
        ordersQuery.fetchData({
          perPage: entity.perPage,
          pageNumber: 1,
          filters: { ...entity.filters },
          sort: entity.sort,
        });
      }

      if (deleteOrderQuery.error) {
        appState.setAlert(
          deleteOrderQuery.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }
    }, [
      deleteOrderQuery.data,
      deleteOrderQuery.error,
      deleteOrderQuery.loading,
    ]);

    useEffect(() => {
      if (appState.subdomain === "boxette") {
        integrationsQuery.fetchData({
          filters: {
            integrationType: ["Easypost"],
          },
        });
      } else if (
        appState.subdomain === "prepfort" ||
        appState.subdomain === "uat"
      ) {
        integrationsQuery.fetchData({
          filters: {
            integrationType: ["eHub"],
          },
        });
      } else if (
        appState.subdomain === "tosprep" ||
        appState.subdomain === "e53"
      ) {
        integrationsQuery.fetchData({
          filters: {
            integrationType: ["Shipstation"],
          },
        });
      } else {
        integrationsQuery.fetchData({
          filters: {
            integrationType: ["Easypost"],
          },
        });
      }
    }, []);

    useEffect(() => {
      if (getMarketplaceProductsQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (getMarketplaceProductsQuery.data) {
        appState.setAlert(
          getMarketplaceProductsQuery.data.marketplaceProducts.message,
          "success",
          ALERT_VISIBILITY_IN_MS,
        );
      }

      if (getMarketplaceProductsQuery.error) {
        appState.setAlert(
          getMarketplaceProductsQuery.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }
    }, [
      getMarketplaceProductsQuery.data,
      getMarketplaceProductsQuery.error,
      getMarketplaceProductsQuery.loading,
    ]);

    useEffect(() => {
      if (updateTrackingInfoQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (
        updateTrackingInfoQuery.data &&
        updateTrackingInfoQuery.data.updateTrackingInfo
      ) {
        appState.setAlert(
          updateTrackingInfoQuery.data.updateTrackingInfo.message,
          "success",
          ALERT_VISIBILITY_IN_MS,
        );
        setTrackingUpdate(null);
        ordersQuery.fetchData({
          perPage: entity.perPage,
          pageNumber: 1,
          filters: { ...entity.filters },
          sort: entity.sort,
        });
      }

      if (updateTrackingInfoQuery.error) {
        appState.setAlert(
          updateTrackingInfoQuery.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }
    }, [
      updateTrackingInfoQuery.loading,
      updateTrackingInfoQuery.error,
      updateTrackingInfoQuery.data,
    ]);

    useEffect(() => {
      if (updateCarrierInfoQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (
        updateCarrierInfoQuery.data &&
        updateCarrierInfoQuery.data.updateCarrierInfo
      ) {
        appState.setAlert(
          updateCarrierInfoQuery.data.updateCarrierInfo.message,
          "success",
          ALERT_VISIBILITY_IN_MS,
        );
        setCarrierUpdate(null);
        ordersQuery.fetchData({
          perPage: entity.perPage,
          pageNumber: 1,
          filters: { ...entity.filters },
          sort: entity.sort,
        });
      }

      if (updateCarrierInfoQuery.error) {
        appState.setAlert(
          updateCarrierInfoQuery.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }
    }, [
      updateCarrierInfoQuery.loading,
      updateCarrierInfoQuery.error,
      updateCarrierInfoQuery.data,
    ]);

    useEffect(() => {
      if (checkIfOrderWarehouseCanBeChangedQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (checkIfOrderWarehouseCanBeChangedQuery.data) {
        setCannotChangeOrderWarehouse(
          checkIfOrderWarehouseCanBeChangedQuery.data
            .checkIfOrderWarehouseCanBeChanged
            ? false
            : "This order cannot be assigned to another warehouse",
        );
        return;
      }

      if (checkIfOrderWarehouseCanBeChangedQuery.error) {
        setCannotChangeOrderWarehouse(
          checkIfOrderWarehouseCanBeChangedQuery.error.message,
        );
      }
    }, [
      checkIfOrderWarehouseCanBeChangedQuery.data,
      checkIfOrderWarehouseCanBeChangedQuery.error,
      checkIfOrderWarehouseCanBeChangedQuery.loading,
    ]);

    useEffect(() => {
      if (assignWarehouseQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (assignWarehouseQuery.data) {
        appState.setAlert(
          assignWarehouseQuery.data.assignWarehouseToOrder.message,
          "success",
          ALERT_VISIBILITY_IN_MS,
        );
        setSelectedWarehouse(null);
      }

      if (assignWarehouseQuery.error) {
        appState.setAlert(
          assignWarehouseQuery.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }
    }, [
      assignWarehouseQuery.data,
      assignWarehouseQuery.error,
      assignWarehouseQuery.loading,
    ]);

    useEffect(() => {
      if (updateOrderLineItemQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (
        updateOrderLineItemQuery.data &&
        updateOrderLineItemQuery.data.updateOrderLineItem
      ) {
        appState.setAlert(
          updateOrderLineItemQuery.data.updateOrderLineItem.message,
          "success",
          ALERT_VISIBILITY_IN_MS,
        );
        const order = { ...expandedOrderInfo.order };
        const idx = order.orderLineItems.findIndex(
          (item) => item.id === selectedOrderLineItem.id,
        );
        order.orderLineItems[idx] = selectedOrderLineItem;
        setExpandedOrderInfo({ ...expandedOrderInfo, order: { ...order } });
        setSelectedOrderLineItem(null);
      }

      if (updateOrderLineItemQuery.error) {
        appState.setAlert(
          updateOrderLineItemQuery.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }
    }, [
      updateOrderLineItemQuery.loading,
      updateOrderLineItemQuery.error,
      updateOrderLineItemQuery.data,
    ]);

    useEffect(() => {
      if (inventoryQuery.loading) {
        // appState.setLoading();
      } else {
        appState.removeLoading();
      }
      if (inventoryQuery.data) {
        setAvailableInventory(
          inventoryQuery.data.inventoryLedger.entities.filter(
            (item) => !!item.sku,
          ),
        );
      }

      if (inventoryQuery.error) {
        setAvailableInventory([]);
      }
    }, [inventoryQuery.loading, inventoryQuery.error, inventoryQuery.data]);

    useEffect(() => {
      return () => {
        entity.resetEntities();
        entity.setFilters({});
      };
    }, []);

    useEffect(() => {
      if (getAllDataTableFiltersQuery.data?.getAllDataTableFilters) {
        setDataTableFilters(
          getAllDataTableFiltersQuery.data.getAllDataTableFilters,
        );
      } else {
        setDataTableFilters({});
      }
    }, [getAllDataTableFiltersQuery.data]);

    useEffect(() => {
      loadFirstTimeData();
      getOrderFields.fetchData();
      getAllDataTableFiltersQuery.fetchData({ entity: "ORDER" });
    }, []);

    useEffect(() => {
      if (auth && auth.user) {
        let filters = {};

        if (auth.user.warehousesList && auth.user.warehousesList.length === 1) {
          const warehouses = auth.user.warehousesList;
          filters.warehouse = { value: warehouses.map((i) => i.id)[0] };
        }

        if (auth.user.customersList && auth.user.customersList.length === 1) {
          const customers = auth.user.customersList;
          filters.customer = { value: customers.map((i) => i.id)[0] };
        }

        if (Object.keys(filters).length > 0) {
          setImportFilters(filters);
        }
      }
    }, [importFbaShipmentModal, auth]);

    useEffect(() => {
      if (importFbaIntegrationsQuery.data) {
        const integrations = importFbaIntegrationsQuery.data.integrations;
        setAmazonIntegrations(integrations);
        const sellerIdsFetched = _.uniq(integrations.map((i) => i.sellerId));
        const marketplacesFetched = _.uniq(
          integrations
            .map((i) => i.marketplaces)
            .flat()
            .concat("US"),
        );
        setMarketplaces(marketplacesFetched);
        setSellerIds(sellerIdsFetched);
      }
    }, [importFbaIntegrationsQuery.data]);

    useEffect(() => {
      if (importFilters?.customer?.value && importFilters?.warehouse?.value) {
        importFbaIntegrationsQuery.fetchData({
          filters: {
            integrationType: ["Amazon"],
            customer: importFilters.customer.value,
            warehouse: importFilters.warehouse.value,
          },
        });
      }
    }, [importFilters.warehouse, importFilters.customer]);

    useEffect(() => {
      if (getOrderFields.loading) appState.setLoading();
      else {
        appState.removeLoading();
      }
      if (getOrderFields.data) {
        setDashboardFields(getOrderFields.data.orderFields);
      }
    }, [getOrderFields.loading, getOrderFields.data, getOrderFields.error]);

    useEffect(() => {
      getNonEditableFieldsForOrderQuery.loading
        ? appState.setLoading()
        : appState.removeLoading();

      if (getNonEditableFieldsForOrderQuery.data) {
        setNonEditableFieldsOfOrder(
          getNonEditableFieldsForOrderQuery.data.getNonEditableFieldsOfOrder,
        );
        getOrderDetailsForEditingQuery.fetchData({ orderId: selectedOrder.id });
      }
      if (getNonEditableFieldsForOrderQuery.error) {
        appState.setAlert(
          getNonEditableFieldsForOrderQuery.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }
    }, [
      getNonEditableFieldsForOrderQuery.loading,
      getNonEditableFieldsForOrderQuery.data,
      getNonEditableFieldsForOrderQuery.error,
    ]);

    useEffect(() => {
      getOrderDetailsForEditingQuery.loading
        ? appState.setLoading()
        : appState.removeLoading();

      if (getOrderDetailsForEditingQuery.data) {
        const orderDetails = getOrderDetailsForEditingQuery.data.order;
        const modifiedOrderLineItems = [];
        orderDetails.orderProducts.forEach((product) => {
          modifiedOrderLineItems.push({
            productId: product.id,
            asin: product.asin,
            sku: product.sku,
            sellerSku: product.sellerSku,
            productName: product.productName,
            availableQty: product.availableQuantity,
            baseUOM: product.baseUom,
            uomConfiguration: product.uomConfiguration,
            ...(product.price && { price: product.price }),
            ...(product.unitPrice && { unitPrice: product.unitPrice }),
            formFactors: [
              {
                name: product.formFactor,
                quantity: product.quantity,
                lotId:
                  product.lotId && product.lotId.trim() !== ""
                    ? product.lotId
                    : null,
                palletId:
                  product.palletId && product.palletId.trim() !== ""
                    ? product.palletId
                    : null,
                nestedFormFactorId:
                  product.nestedFormFactorId &&
                  product.nestedFormFactorId.trim() !== ""
                    ? product.nestedFormFactorId
                    : null,
                serialNumber: product.serialNumber,
                poId: product.poId,
                expiryDate: product.expiryDate,
              },
            ],
            name: product.productName,
            fnSku: product.fnSku,
            attributes: product.attributes,
            marketplaceAttributes: product.marketplaceAttributes,
          });
        });
        orderDetails.orderLineItems = modifiedOrderLineItems;

        setSelectedOrder({ ...orderDetails });
        setOrderBeingEdited(true);
      }
      if (getOrderDetailsForEditingQuery.error) {
        appState.setAlert(
          getOrderDetailsForEditingQuery.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }
    }, [
      getOrderDetailsForEditingQuery.loading,
      getOrderDetailsForEditingQuery.data,
      getOrderDetailsForEditingQuery.error,
    ]);

    useEffect(() => {
      if (bulkUploadValidation.loading) appState.setLoading();
      else {
        appState.removeLoading();
      }

      if (bulkUploadValidation.data) {
        setValidationResult(
          bulkUploadValidation.data.validateBulkUploadOrders
            .inputValidationErrors,
        );
      }
    }, [
      bulkUploadValidation.loading,
      bulkUploadValidation.data,
      bulkUploadValidation.error,
    ]);

    useEffect(() => {
      if (uploadBulk.data) {
        ordersQuery.fetchData({
          perPage: entity.perPage,
          pageNumber: 1,
          filters: entity.filters,
          sort: entity.sort,
        });
        setSuccessMessage(uploadBulk.data.bulkUploadOrders.message);
      }
      if (uploadBulk.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
      if (uploadBulk.error) {
        appState.removeLoading();
        setFinalError(uploadBulk.error.message);
      }
    }, [uploadBulk.loading, uploadBulk.data, uploadBulk.error]);

    const loadFirstTimeData = () => {
      if (
        auth &&
        auth.user &&
        auth.user.warehousesList &&
        auth.user.customersList
      ) {
        setSelectedSkus([]);
        const customers = auth.user.customersList;
        if (customers.length === 0) {
          return appState.setAlert(
            `You don't have necessary permission to execute this action.`,
            "error",
            ALERT_VISIBILITY_IN_MS,
          );
        }
        const setFilters = entity.filters
          ? { keyword: entity.filters.keyword }
          : {};
        entity.setFilters(setFilters);
        entity.setSort("-orderDate");
        appState.setLoading();
        ordersQuery.fetchData({
          perPage: entity.perPage,
          pageNumber: entity.pageNumber,
          filters: setFilters,
          paginated: false,
          sort: "-orderDate",
        });
      }
    };

    useEffect(() => {
      if (usersQuery.data?.users && usersQuery.data.users.entities) {
        const users = usersQuery.data.users.entities.filter(
          (user) =>
            user.hopstackModules && user.hopstackModules.includes("Picking"),
        );
        setPickers(users);
      }

      if (usersQuery.error) {
        setPickers([]);
      }
    }, [usersQuery.loading, usersQuery.error, usersQuery.data]);

    useEffect(() => {
      if (uploadOrdersQuery.loading) {
        appState.setLoading();
      }

      if (uploadOrdersQuery.error) {
        setFileIndex(Math.random() * 100);
        appState.setAlert(
          uploadOrdersQuery.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
        appState.removeLoading();
      }

      if (uploadOrdersQuery.data) {
        setTimeout(() => {
          appState.setAlert(
            uploadOrdersQuery.data.uploadOrders.message,
            "success",
            ALERT_VISIBILITY_IN_MS,
          );

          const filtersSet = {};
          entity.setFilters(filtersSet);
          setFileIndex(Math.random() * 100);
          ordersQuery.fetchData({
            perPage: entity.perPage,
            filters: filtersSet,
            paginated: false,
            pageNumber: 1,
            sort: entity.sort,
          });
        }, 4000);
      }
    }, [
      uploadOrdersQuery.data,
      uploadOrdersQuery.loading,
      uploadOrdersQuery.error,
    ]);

    useEffect(() => {
      if (fetchSpecificOrder.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (fetchSpecificOrder.data) {
        setExpandedOrderInfo({ order: fetchSpecificOrder.data.order });
        if (
          [
            OrderSource.FBA,
            OrderSource.SHOPIFY,
            OrderSource.SALLA,
            OrderSource.NETSUITE,
            OrderSource.ZID,
            OrderSource.APPARELMAGIC,
            OrderSource.SHIP_COMPLIANT,
            OrderSource.SAP_B1_TYM,
            OrderSource.E_BAY,
            OrderSource.SALESFORCE,
            OrderSource.VAULT_JMI,
          ].includes(fetchSpecificOrder.data.order?.source)
        ) {
          setMarkCompleteOnMarketplaceCheck(true);
        }
        if (["FBA"].includes(fetchSpecificOrder.data.order?.source)) {
          setMarkCancelledOnMarketplaceCheck(true);
        }
      }

      if (fetchSpecificOrder.error) {
        setExpandedOrderInfo(null);
      }
    }, [
      fetchSpecificOrder.loading,
      fetchSpecificOrder.error,
      fetchSpecificOrder.data,
    ]);

    useEffect(() => {
      if (getBackOrderDetailsQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (getBackOrderDetailsQuery.data) {
        setExpandedOrderInfo({
          ...getBackOrderDetailsQuery.data.getBackOrderDetails,
          isBackOrder: true,
        });
        if (
          [
            OrderSource.FBA,
            OrderSource.SHOPIFY,
            OrderSource.SALLA,
            OrderSource.NETSUITE,
            OrderSource.ZID,
            OrderSource.APPARELMAGIC,
            OrderSource.SHIP_COMPLIANT,
            OrderSource.SAP_B1_TYM,
            OrderSource.E_BAY,
            OrderSource.SALESFORCE,
            OrderSource.VAULT_JMI,
          ].includes(
            getBackOrderDetailsQuery.data.getBackOrderDetails.backOrder?.source,
          )
        ) {
          setMarkCompleteOnMarketplaceCheck(true);
        }
        if (
          ["FBA"].includes(
            getBackOrderDetailsQuery.data.getBackOrderDetails.backOrder?.source,
          )
        ) {
          setMarkCancelledOnMarketplaceCheck(true);
        }
      }

      if (getBackOrderDetailsQuery.error) {
        setExpandedOrderInfo(null);
      }
    }, [
      getBackOrderDetailsQuery.loading,
      getBackOrderDetailsQuery.error,
      getBackOrderDetailsQuery.data,
    ]);

    useEffect(() => {
      if (fetchOrderForCarrierUpdate.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (fetchOrderForCarrierUpdate.data) {
        const order = fetchOrderForCarrierUpdate.data.order;
        const modifiedOrderLineItems = [];
        order.orderProducts.forEach((product) => {
          modifiedOrderLineItems.push({
            productId: product.id,
            asin: product.asin,
            sku: product.sku,
            sellerSku: product.sellerSku,
            productName: product.productName,
            availableQty: product.availableQuantity,
            baseUOM: product.baseUOM,
            formFactors: [
              {
                name: product.formFactor,
                quantity: product.quantity,
                lotId:
                  product.lotId && product.lotId.trim() !== ""
                    ? product.lotId
                    : null,
              },
            ],
            name: product.productName,
            fnSku: product.fnSku,
            attributes: product.attributes,
            marketplaceAttributes: product.marketplaceAttributes,
          });
        });
        order.orderLineItems = modifiedOrderLineItems;
        setSelectedOrder(order);
        setCarrierUpdate({
          id: order.id,
          carrier: order.carrier,
          shippingAddress: order.shippingAddress,
          shippingRequests: order.shippingRequests,
          carrierService: order.carrierService,
          toValidAddress: order.toValidAddress,
          insuranceRequired: order.insuranceRequired,
          insuranceProvider: order.insuranceProvider,
          insuredValue:
            isNaN(order.insuredValue) === false
              ? parseFloat(order.insuredValue)
              : isNaN(order.insuredValue) === false
                ? parseFloat(order.orderValue)
                : order.insuredValue,
          bolNumber: order.bolNumber,
          scacCode: order.scacCode,
          bolData: order?.bolData,
          dateOfShipping: order.dateOfShipping,
        });
      }

      if (fetchOrderForCarrierUpdate.error) {
        appState.removeLoading();
        appState.setAlert(
          fetchOrderForCarrierUpdate.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }
    }, [
      fetchOrderForCarrierUpdate.loading,
      fetchOrderForCarrierUpdate.error,
      fetchOrderForCarrierUpdate.data,
    ]);

    useEffect(() => {
      if (getOrderForBolDownload.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (getOrderForBolDownload.data) {
        appState.setLoading();
        if (bolUploadEnabled) {
          if (getOrderForBolDownload.data.order.bol) {
            saveAs(getOrderForBolDownload.data.order.bol);
            appState.removeLoading();
          } else {
            appState.removeLoading();
            appState.setAlert(
              `There is no BOL for this order`,
              "error",
              ALERT_VISIBILITY_IN_MS,
            );
          }
        } else {
          setBolDoc(getOrderForBolDownload.data.order);
          setTimeout(() => {
            printBol(
              getOrderForBolDownload.data.order.orderId,
              1,
              appState.subdomain === "ctw" ? "RICOH IM 5000 PCL 6" : null,
              true,
              appState,
            );
            appState.removeLoading();
          }, ALERT_VISIBILITY_IN_MS);
        }
      }
    }, [
      getOrderForBolDownload.loading,
      getOrderForBolDownload.error,
      getOrderForBolDownload.data,
    ]);

    useEffect(() => {
      if (fetchSpecificOrderBatchQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (fetchSpecificOrderBatchQuery.data?.orderBatch) {
        if (packingLabelAction === "DOWNLOAD") {
          downloadPackingLabels(
            fetchSpecificOrderBatchQuery.data.orderBatch[0],
            1,
            TENANT_SHIPPING_LABEL_PRINTER_ENUM[appState.subdomain] ||
              TENANT_SHIPPING_LABEL_PRINTER_ENUM.default,
            true,
            appState,
          );
        }
        if (packingLabelAction === "PRINT") {
          downloadPackingLabels(
            fetchSpecificOrderBatchQuery.data.orderBatch[0],
            1,
            TENANT_SHIPPING_LABEL_PRINTER_ENUM[appState.subdomain] ||
              TENANT_SHIPPING_LABEL_PRINTER_ENUM.default,
            false,
            appState,
          );
        }
        setPackingLabelAction(null);
      }

      if (fetchSpecificOrderBatchQuery.error) {
        appState.setAlert(
          fetchSpecificOrderBatchQuery.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }
    }, [
      fetchSpecificOrderBatchQuery.loading,
      fetchSpecificOrderBatchQuery.error,
      fetchSpecificOrderBatchQuery.data,
    ]);

    useEffect(() => {
      if (fetchOrderBatchForPackingList.loading) {
        appState.setLoading();
      }

      if (fetchOrderBatchForPackingList.error) {
        appState.removeLoading();
        appState.setAlert(
          fetchOrderBatchForPackingList.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }

      if (fetchOrderBatchForPackingList.data) {
        const batch = fetchOrderBatchForPackingList.data?.orderBatch?.[0];
        const productIds = batch.workingList.map((item) => {
          return item.productId;
        });

        if (productIds && productIds.length !== 0) {
          fetchProductsForPackingList.fetchData({
            filters: {
              id: _.uniq(productIds),
            },
          });
        } else {
          generatePackListCsv(batch);
          appState.removeLoading();
        }
      }
    }, [
      fetchOrderBatchForPackingList.loading,
      fetchOrderBatchForPackingList.data,
      fetchOrderBatchForPackingList.error,
    ]);

    // fetchProductsForPackingList query is called after getting the response of
    // fetchOrderBatchForPackingList query
    // to get the product details of the products in the batch
    useEffect(() => {
      if (fetchProductsForPackingList.error) {
        appState.setAlert(
          fetchProductsForPackingList.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
        appState.removeLoading();
      }

      if (fetchProductsForPackingList.data) {
        const products = fetchProductsForPackingList.data?.products?.entities;
        generatePackListCsv(
          fetchOrderBatchForPackingList.data?.orderBatch?.[0],
          products,
        );
        appState.removeLoading();
      }
    }, [fetchProductsForPackingList.data, fetchProductsForPackingList.error]);

    useEffect(() => {
      if (getOrderForBolPrint.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (getOrderForBolPrint.data) {
        appState.setLoading();
        if (bolUploadEnabled) {
          if (getOrderForBolPrint.data.order.bol) {
            printShippingLabel(getOrderForBolPrint.data.order.bol, appState);
            labelEventQuery.fetchData({
              warehouse: getOrderForBolPrint.data.order.warehouse,
              customer: getOrderForBolPrint.data.order.customer,
              order: getOrderForBolPrint.data.order.id,
              quantity: 1,
              labelType: "SHIPPING_LABEL",
              dropship: false,
            });
            appState.removeLoading();
          } else {
            appState.removeLoading();
            appState.setAlert(
              `There is no BOL for this order`,
              "error",
              ALERT_VISIBILITY_IN_MS,
            );
          }
        } else {
          setBolDoc(getOrderForBolPrint.data.order);
          setTimeout(() => {
            printBol(
              getOrderForBolPrint.data.order.orderId,
              1,
              appState.subdomain === "ctw"
                ? "RICOH IM ALERT_VISIBILITY_IN_MS PCL 6"
                : null,
              false,
              appState,
            );
            labelEventQuery.fetchData({
              warehouse: getOrderForBolPrint.data.order.warehouse,
              customer: getOrderForBolPrint.data.order.customer,
              order: getOrderForBolPrint.data.order.id,
              quantity: 1,
              labelType: "BOL",
              dropship: false,
            });
            appState.removeLoading();
          }, ALERT_VISIBILITY_IN_MS);
        }
      }
    }, [
      getOrderForBolPrint.loading,
      getOrderForBolPrint.error,
      getOrderForBolPrint.data,
    ]);

    useEffect(() => {
      if (manuallyMarkOrdersQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (manuallyMarkOrdersQuery.data) {
        ordersQuery.fetchData({
          perPage: entity.perPage,
          filters: entity.filters,
          paginated: false,
          pageNumber: entity.pageNumber,
          sort: entity.sort,
        });
        appState.setAlert(
          manuallyMarkOrdersQuery.data.manuallyMarkOrders.message,
          "success",
          ALERT_VISIBILITY_IN_MS,
        );
        appState.hideConfirmation();
      }

      if (manuallyMarkOrdersQuery.error) {
        appState.removeLoading();
        appState.setAlert(
          manuallyMarkOrdersQuery.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }
    }, [
      manuallyMarkOrdersQuery.error,
      manuallyMarkOrdersQuery.data,
      manuallyMarkOrdersQuery.loading,
    ]);

    useEffect(() => {
      if (markOrderCompleteOnMarketplaceQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (markOrderCompleteOnMarketplaceQuery.data) {
        ordersQuery.fetchData({
          perPage: entity.perPage,
          filters: entity.filters,
          paginated: false,
          pageNumber: entity.pageNumber,
          sort: entity.sort,
        });
        appState.setAlert(
          markOrderCompleteOnMarketplaceQuery.data.completeOrderOnMarketplace
            .message,
          "success",
          ALERT_VISIBILITY_IN_MS,
        );
        appState.hideConfirmation();
      }

      if (markOrderCompleteOnMarketplaceQuery.error) {
        appState.removeLoading();
        appState.setAlert(
          markOrderCompleteOnMarketplaceQuery.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }
    }, [
      markOrderCompleteOnMarketplaceQuery.error,
      markOrderCompleteOnMarketplaceQuery.data,
      markOrderCompleteOnMarketplaceQuery.loading,
    ]);

    const displayBatchingNotification = () => {
      if (!batchingAlertDetails.title || !batchingAlertDetails.subTitle) return;

      notify.default(
        batchingAlertDetails.title,
        batchingAlertDetails.subTitle,
        {
          bgColor: batchingAlertDetails.bgColor,
          titleColor: batchingAlertDetails.titleColor,
          borderColor: batchingAlertDetails.borderColor,
          textColor: batchingAlertDetails.textColor,
          duration: batchingAlertDetails.duration,
          key: batchingAlertDetails.id,
          defaultColors: false,
          icon: batchingAlertDetails.icon,
        },
      );
    };

    useEffect(() => {
      displayBatchingNotification();
    }, [batchingAlertDetails]);

    useEffect(() => {
      if (createManualBatchQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (createManualBatchQuery.error) {
        appState.removeLoading();
        appState.setAlert(
          createManualBatchQuery.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }
      if (
        createManualBatchQuery.data &&
        createManualBatchQuery.data.createManualOrderBatch &&
        createManualBatchQuery.data.createManualOrderBatch.message
      ) {
        const attemptId =
          createManualBatchQuery.data.createManualOrderBatch?.attemptId;

        setBatchingAlertDetails({
          title: "BATCHING IN PROGRESS",
          subTitle: createManualBatchQuery.data.createManualOrderBatch.message,
          bgColor: "bg-blue-100",
          titleColor: "text-blue-800",
          textColor: "text-gray-700",
          duration: 0,
          id: attemptId,
          icon: <InformationCircleIcon className="h-6 w-6 text-blue-800" />,
        });

        if (attemptId) {
          setBatchingAttemptIds((prev) => [...prev, attemptId]);
        }

        setSelectedPicker(null);
        setManualBatchConfig(null);
        setSelectedOrders([]);
        setOrdersFulfillmentDetails(null);
        ordersQuery.fetchData({
          perPage: entity.perPage,
          filters: entity.filters,
          paginated: false,
          pageNumber: 1,
          sort: entity.sort,
        });
      }
    }, [
      createManualBatchQuery.loading,
      createManualBatchQuery.data,
      createManualBatchQuery.error,
    ]);

    const fetchBatchingAttempts = async (intervalId) => {
      const response = await getBatchingAttemptsQuery.fetchData({
        ids: batchingAttemptIds,
      });
      const batchingResults = response.data?.getBatchingAttempts;

      if (batchingResults?.length > 0) {
        const successfulBatches = batchingResults.filter(
          (result) => result.status === "SUCCESS",
        );
        const failedBatches = batchingResults.filter(
          (result) => result.status === "FAILED",
        );

        for (let batch of successfulBatches) {
          setBatchingAlertDetails({
            title: "BATCHED",
            subTitle: batch.result.message,
            bgColor: "bg-green-100",
            titleColor: "text-green-800",
            textColor: "text-gray-700",
            duration: ALERT_VISIBILITY_IN_MS,
            id: batch.id,
            icon: <CheckCircleIcon className="h-6 w-6 text-green-800" />,
          });
        }

        for (let batch of failedBatches) {
          setBatchingAlertDetails({
            title: "ERROR",
            subTitle:
              "Batching Process Failed. Please retry in 10mins. Contact support if issue persists.",
            bgColor: "bg-red-100",
            titleColor: "text-red-800",
            textColor: "text-gray-700",
            duration: ALERT_VISIBILITY_IN_MS,
            id: batch.id,
            icon: <XCircleIcon className="h-6 w-6 text-red-800" />,
          });
        }

        const pendingBatchIds = batchingAttemptIds.filter(
          (id) =>
            !successfulBatches.some((successBatch) => successBatch.id === id) &&
            !failedBatches.some((failedBatch) => failedBatch.id === id),
        );

        setBatchingAttemptIds(pendingBatchIds);

        if (pendingBatchIds.length === 0) {
          clearInterval(intervalId);
        }

        ordersQuery.fetchData({
          perPage: entity.perPage,
          filters: entity.filters,
          paginated: false,
          pageNumber: 1,
          sort: entity.sort,
        });
      }
    };

    useEffect(() => {
      let intervalId;

      if (batchingAttemptIds.length > 0) {
        intervalId = setInterval(() => {
          fetchBatchingAttempts(intervalId);

          setPollCounter((count) => count + 1);
        }, POLLING_INTERVAL_FOR_BATCHING_STATUS);
      } else {
        clearInterval(intervalId);
      }

      return () => clearInterval(intervalId);
    }, [batchingAttemptIds]);

    useEffect(() => {
      if (pollCounter > POLLING_COUNTER_LIMIT) {
        batchingAttemptIds.forEach((attemptId) =>
          setBatchingAlertDetails({
            title: "ERROR",
            subTitle:
              "Batching Process Failed. Please retry in 10mins. Contact support if issue persists.",
            bgColor: "bg-red-100",
            titleColor: "text-red-800",
            textColor: "text-gray-700",
            duration: ALERT_VISIBILITY_IN_MS,
            id: attemptId,
            icon: <XCircleIcon className="h-6 w-6 text-red-800" />,
          }),
        );
        setBatchingAttemptIds([]);
      }
    }, [pollCounter]);

    useEffect(() => {
      localStorage.setItem(
        "batchingAttemptIds",
        JSON.stringify(batchingAttemptIds),
      );
    }, [batchingAttemptIds]);

    useEffect(() => {
      if (getBatchingAttemptsQuery.error) {
        batchingAttemptIds.forEach((attemptId) =>
          setBatchingAlertDetails({
            title: "ERROR",
            subTitle: getBatchingAttemptsQuery.error.message,
            bgColor: "bg-red-100",
            titleColor: "text-red-800",
            textColor: "text-gray-700",
            duration: ALERT_VISIBILITY_IN_MS,
            id: attemptId,
            icon: <XCircleIcon className="h-6 w-6 text-red-800" />,
          }),
        );
        setBatchingAttemptIds([]);
      }
    }, [getBatchingAttemptsQuery.error]);

    useEffect(() => {
      if (ordersQuery.data) {
        delete ordersQuery.variables.filters;
        entity.setEntities({
          ...ordersQuery.data.orders,
          ...ordersQuery.variables,
        });
        appState.removeLoading();
      }
    }, [ordersQuery.loading, ordersQuery.error, ordersQuery.data]);

    const getAudit = (order) => {
      if (order) {
        setAuditOrderId(order.orderId);
        getAuditQuery.fetchData({ id: order.id });
      }
    };
    useEffect(() => {
      getAuditQuery.loading ? appState.setLoading() : appState.removeLoading();

      if (getAuditQuery.data?.getAudit) {
        setSelectedAudit(getAuditQuery.data.getAudit);
        const userIds = [];
        getAuditQuery.data.getAudit.stages?.forEach((stage) => {
          if (stage.userId) {
            userIds.push(stage.userId);
          }
        });
        if (userIds.length > 0) {
          getAuditUsersQuery.fetchData({
            filters: {
              id: userIds,
            },
          });
        } else {
          setShowAudit(true);
        }
      }

      if (getAuditQuery.error) {
        appState.setAlert(
          getAuditQuery.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
        setSelectedAudit(null);
      }
    }, [getAuditQuery.loading, getAuditQuery.data, getAuditQuery.error]);

    useEffect(() => {
      getAuditUsersQuery.loading
        ? appState.setLoading()
        : appState.removeLoading();

      if (getAuditUsersQuery.data?.users?.entities) {
        setSelectedAuditUsersInfo([...getAuditUsersQuery.data.users.entities]);
        setShowAudit(true);
      }

      if (getAuditUsersQuery.error) {
        appState.setAlert(
          getAuditUsersQuery.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
        setSelectedAudit(null);
        setSelectedAuditUsersInfo(null);
      }
    }, [
      getAuditUsersQuery.loading,
      getAuditUsersQuery.data,
      getAuditUsersQuery.error,
    ]);

    const selectOrder = (index, shiftKeyPressed) => {
      const selectedOrdersCopy = [...selectedOrders];
      const orders = entity.displayEntities;
      if (shiftKeyPressed) {
        if (lastSelectedOrder !== null) {
          const firstIndex = Math.min(lastSelectedOrder, index);
          const lastIndex = Math.max(lastSelectedOrder, index);
          for (let i = firstIndex; i <= lastIndex; i++) {
            if (!selectedOrdersCopy.includes(orders[i].id)) {
              selectedOrdersCopy.push(orders[i].id);
            }
          }
        } else {
          setLastSelectedOrder(index);
        }
      } else {
        setLastSelectedOrder(index);
        const idx = selectedOrdersCopy.indexOf(orders[index].id);
        if (idx !== -1) {
          selectedOrdersCopy.splice(idx, 1);
        } else {
          selectedOrdersCopy.push(orders[index].id);
        }
      }

      setSelectedOrders(selectedOrdersCopy);
    };

    const selectAllRows = () => {
      if (allRowsSelected === true) {
        setSelectedOrders([]);
        setAllRowsSelected(false);
      } else {
        setAllRowsSelected(true);
        setSelectedOrders(entity.entities.map((item) => item.id));
      }
    };

    const clearSelectedOrders = () => {
      setShowOnlySelected(false);
      setSelectedOrders([]);
    };

    const checkPagination = (direction) => {
      if (direction === "backward") {
        return entity.paginate({ pageNumber: entity.pageNumber - 1 });
      }
      if (entity.entities.length < (entity.pageNumber + 1) * entity.perPage) {
        const vars = {
          perPage: entity.perPage,
          pageNumber: entity.pageNumber + 1,
          filters: entity.filters,
          paginated: true,
          sort: entity.sort,
        };
        return ordersQuery.fetchData(vars);
      } else {
        return entity.paginate({ pageNumber: entity.pageNumber + 1 });
      }
    };

    const calculateShippingRates = () => {
      appState.setLoading();
      const entities = JSON.parse(JSON.stringify(entity.entities));
      const orders = _.filter(entities, (e) => selectedOrders.includes(e.id));
      for (const order of orders) {
        getShippingRates.fetchData({ orderNo: order.orderNo });
      }
      setTimeout(() => {
        appState.setAlert(`Rates have been calculated`, "success");
        appState.removeLoading();

        setSelectedOrders([]);
      }, 2000);
    };

    useEffect(() => {
      if (runCombinedOrders.data) {
        setCombinedOrders(runCombinedOrders.data.runCombinedOrders);
      } else {
        setCombinedOrders(null);
      }
      if (runCombinedOrders.loading) {
        setShowCombinedOrders(null);
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
    }, [
      runCombinedOrders.loading,
      runCombinedOrders.error,
      runCombinedOrders.data,
    ]);

    useEffect(() => {
      if (crmCustomersQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
      if (crmCustomersQuery?.data?.crmCustomers) {
        setCrmCustomers(crmCustomersQuery.data.crmCustomers?.entities);
      }
    }, [
      crmCustomersQuery.error,
      crmCustomersQuery.data,
      crmCustomersQuery.loading,
    ]);
    function validateCombinedOrdersBatching(orders) {
      const hasCombinedOrder = orders.some(
        (i) => i.customAttributes?.combinedOrder === true,
      );
      const hasNonCombinedOrder = orders.some(
        (i) => i.customAttributes?.combinedOrder !== true,
      );

      if (hasCombinedOrder && hasNonCombinedOrder) {
        // Both combined and non-combined orders are present
        appState.removeLoading();
        appState.setAlert(
          `You can't mix combined and non-combined orders`,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
        return false;
      } else if (hasCombinedOrder) {
        // Only combined orders are present
        return true;
      }

      return false;
    }

    const createManualBatchFinal = async ({ ordersToBatch, entity }) => {
      if (isManualBatchExecuting) {
        return;
      }
      setIsManualBatchExecuting(true);

      let selectedOrders = [];

      ordersToBatch.forEach((selectedOrder) => {
        const order = entity.entities.find(
          (entity) => entity.id === selectedOrder,
        );

        const childOrder = entity.entities.find((entity) =>
          entity?.childOrders?.includes(selectedOrder),
        );

        if (order) selectedOrders.push(order);
        else if (childOrder) selectedOrders.push(childOrder);
      });

      appState.setLoading();
      if (!selectedOrders || selectedOrders.length === 0) {
        setIsManualBatchExecuting(false);
        appState.removeLoading();
        return appState.setAlert(
          "Select at least 1 order",
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }

      let backOrderResponse;

      if (isVanEngelenTenant(appState?.subdomain)) {
        const getOrderPayload = (order) => {
          const payload = {
            order: order.id,
          };

          const requestedOrderDetails = updatedOrderBatchingDetails?.find(
            (i) => i.order === order.id,
          );

          if (
            requestedOrderDetails &&
            requestedOrderDetails.orderDetails &&
            requestedOrderDetails.orderDetails.length > 0
          ) {
            payload.requestedOrderDetails = requestedOrderDetails.orderDetails;
          }

          return payload;
        };

        backOrderResponse = await createBackOrderQuery.fetchData({
          createBackOrderInput: {
            batchingOrdersSet: selectedOrders.map((order) =>
              getOrderPayload(order),
            ),
          },
        });
      }

      if (
        (isVanEngelenTenant(appState?.subdomain) &&
          backOrderResponse.data.createBackOrder) ||
        !isVanEngelenTenant(appState?.subdomain)
      ) {
        const orders = entity.entities.filter((e) =>
          selectedOrders.includes(e),
        );
        const manualBatchQuery = {
          ids: selectedOrders.map((order) => order.id),
        };
        if (manualBatchConfig?.picker) {
          manualBatchQuery.user = manualBatchConfig.picker;
        }
        if (manualBatchConfig?.preppingStation) {
          manualBatchQuery.preppingStation = manualBatchConfig.preppingStation;
        }
        if (manualBatchConfig?.packingStation) {
          manualBatchQuery.packingStation = manualBatchConfig.packingStation;
        }
        if (manualBatchConfig?.combineOrders === true) {
          if (validateCombinedOrdersBatching(orders) === true) {
            manualBatchQuery.combineOrders = true;
          }
        }
        if (
          manualBatchConfig?.maxOrdersInBatch &&
          manualBatchConfig?.maxOrdersInBatch > 0
        ) {
          if (
            orders.findIndex((i) => i.multipleLineItems === true) !== -1 &&
            batchSettings?.maxOrdersPerBatchForMultipleLineItems &&
            manualBatchConfig.maxOrdersInBatch >
              batchSettings?.maxOrdersPerBatchForMultipleLineItems
          ) {
            appState.removeLoading();
            return appState.setAlert(
              `Maximum orders per batch for multiple line item orders is ${batchSettings?.maxOrdersPerBatchForMultipleLineItems}`,
              "error",
              ALERT_VISIBILITY_IN_MS,
            );
          } else if (
            batchSettings?.maxOrdersPerBatchForMultipleLineItems &&
            manualBatchConfig.maxOrdersInBatch >
              batchSettings?.maxOrdersPerBatchForSingleLineItems
          ) {
            appState.removeLoading();
            return appState.setAlert(
              `Maximum orders per batch for single line item orders is ${batchSettings?.maxOrdersPerBatchForSingleLineItems}`,
              "error",
              ALERT_VISIBILITY_IN_MS,
            );
          }
          manualBatchQuery.maxOrdersInBatch =
            manualBatchConfig.maxOrdersInBatch;
        }

        createManualBatchQuery.fetchData(manualBatchQuery).finally(() => {
          setIsManualBatchExecuting(false);
        });
      }
    };

    const createManualBatch = async ({
      entity,
      selectedOrders,
      fulfillmentCheck = true,
    }) => {
      let ordersToBatch = [];

      selectedOrders.forEach((selectedOrder) => {
        const order = entity.entities.find(
          (entity) => entity.id === selectedOrder,
        );

        if (order) ordersToBatch.push(order);
      });

      const splitOrdersToFetch = ordersToBatch.filter(
        (order) => order.splitOrders,
      );
      ordersToBatch = ordersToBatch.filter((order) => !order.splitOrders);

      const warehouses = _.uniq(ordersToBatch.map((order) => order.warehouse));

      const allowedOrderStatusesForBatching = ["UNPROCESSED", "EXCEPTION"];
      if (isBackOrderEnabledForTenant) {
        allowedOrderStatusesForBatching.push(
          "BATCHED_PARTIALLY",
          "COMPLETED_PARTIALLY",
          "IN_PROCESS_PARTIALLY",
        );
      }

      const notAllowedStatuses = _.uniq(
        ordersToBatch
          .map((order) => order.orderStatus)
          .filter(
            (orderStatus) =>
              allowedOrderStatusesForBatching.includes(orderStatus) === false,
          ),
      );

      if (warehouses.length > 1) {
        return appState.setAlert(
          "Select orders from the same warehouse",
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }

      if (notAllowedStatuses.length) {
        return appState.setAlert(
          `You've selected orders that include the following statuses: '${notAllowedStatuses.join(
            ", ",
          )}'. Please select orders only with statuses ${allowedOrderStatusesForBatching.join(
            ", ",
          )}.`,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }

      if (showBatchingPreview && fulfillmentCheck) {
        // fetch fulfillment details only if back order is enabled for the tenant.
        let ordersFulfillmentResponse;
        appState.setLoading();
        if (ordersToBatch.length) {
          const getOrderPayload = (order) => {
            const payload = {
              order: order.id,
            };

            const requestedOrderDetails = updatedOrderBatchingDetails?.find(
              (i) => i.order === order.id,
            );

            if (
              requestedOrderDetails &&
              requestedOrderDetails.orderDetails &&
              requestedOrderDetails.orderDetails.length > 0
            ) {
              payload.requestedOrderDetails =
                requestedOrderDetails.orderDetails;
            }

            return payload;
          };

          ordersFulfillmentResponse =
            await getOrderFulfillmentDetailsQuery.fetchData({
              input: {
                batchPreviewInput: ordersToBatch.map((order) =>
                  getOrderPayload(order),
                ),
              },
            });
        }

        // This effect will fetch the fulfillment details for order before manual batching
        const splitOrdersPromises = splitOrdersToFetch.map((order) =>
          getSplitOrderDetailsQuery.fetchData({ id: order.id }),
        );
        const splitOrdersResponse = await Promise.all(splitOrdersPromises);
        appState.removeLoading();
        const splitOrders =
          splitOrdersResponse?.map(
            (order) => order?.data?.getSplitOrderDetails,
          ) || [];

        if (ordersFulfillmentResponse?.data) {
          setManualBatchConfig({
            combineOrders: true,
          });
        }

        if (ordersFulfillmentResponse?.data && splitOrders.length > 0) {
          setOrdersFulfillmentDetails({
            ...ordersFulfillmentResponse.data.getOrderFulfillmentDetails,
            unfulfillableOrders: [
              ...ordersFulfillmentResponse.data.getOrderFulfillmentDetails
                .unfulfillableOrders,
              ...splitOrders,
            ],
          });
        } else if (
          ordersFulfillmentResponse?.data &&
          splitOrders.length === 0
        ) {
          setOrdersFulfillmentDetails(
            ordersFulfillmentResponse.data.getOrderFulfillmentDetails,
          );
        } else if (splitOrders.length > 0) {
          setOrdersFulfillmentDetails({
            backOrders: [],
            exceptionOrders: [],
            fulfillableOrders: [],
            unfulfillableOrders: splitOrders,
          });
        }
      } else {
        setManualBatchConfig({
          combineOrders: true,
        });
      }

      getPickersForWarehouseQuery.fetchData({
        filters: {
          warehouses: warehouses,
          role: ["ASSOCIATE"],
          hopstackModules: ["Picking"],
          all: true,
        },
      });
      stationsQuery.fetchData({
        filters: {
          warehouses: warehouses,
          hopstackModule: ["Prepping", "Packing"],
          all: true,
        },
      });

      setRefetchOrderDetails(false);
      // setUpdatedOrderBatchingDetails([]);
    };

    useEffect(() => {
      if (refetchOrderDetails) {
        createManualBatch({ entity, selectedOrders, fulfillmentCheck: true });
      }
    }, [refetchOrderDetails]);

    useEffect(() => {
      if (getOrderFulfillmentDetailsQuery.error) {
        setOrdersFulfillmentDetails(null);
        appState.setAlert(
          getOrderFulfillmentDetailsQuery.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }
    }, [getOrderFulfillmentDetailsQuery.error]);

    const markOrdersCompleted = async ({ entity, selectedOrders }) => {
      setExpandedOrderInfo(null);
      let ordersToMarkComplete = entity.entities.filter((order) =>
        selectedOrders.includes(order.id),
      );

      ordersToMarkComplete = ordersToMarkComplete.filter(
        (order) => !order.splitOrders,
      );
      const allowedOrderStatusesForBatching = [
        "UNPROCESSED",
        "BATCHED",
        "IN-PROCESS",
        "BATCHED_PARTIALLY",
        "PARTIAL_COMPLETED",
        "INCOMPLETE",
        "EXCEPTION",
      ];

      const notAllowedStatuses = _.uniq(
        ordersToMarkComplete
          .map((order) => order.orderStatus)
          .filter(
            (orderStatus) =>
              allowedOrderStatusesForBatching.includes(orderStatus) === false,
          ),
      );

      if (notAllowedStatuses.length) {
        return appState.setAlert(
          `You've selected orders that include the following statuses: '${notAllowedStatuses.join(
            ", ",
          )}'. Please select orders only with statuses ${allowedOrderStatusesForBatching.join(
            ", ",
          )}.`,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }
    };

    const markOrdersCancelled = async ({ entity, selectedOrders }) => {
      setExpandedOrderInfo(null);
      let ordersToMarkCancel = entity.entities.filter((order) =>
        selectedOrders.includes(order.id),
      );

      ordersToMarkCancel = ordersToMarkCancel.filter(
        (order) => !order.splitOrders,
      );

      const allowedOrderStatusesForBatching = [
        "UNPROCESSED",
        "BATCHED",
        "IN-PROCESS",
        "BATCHED_PARTIALLY",
        "PARTIAL_COMPLETED",
        "INCOMPLETE",
        "EXCEPTION",
      ];

      const notAllowedStatuses = _.uniq(
        ordersToMarkCancel
          .map((order) => order.orderStatus)
          .filter(
            (orderStatus) =>
              allowedOrderStatusesForBatching.includes(orderStatus) === false,
          ),
      );

      if (notAllowedStatuses.length) {
        return appState.setAlert(
          `You've selected orders that include the following statuses: '${notAllowedStatuses.join(
            ", ",
          )}'. Please select orders only with statuses ${allowedOrderStatusesForBatching.join(
            ", ",
          )}.`,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }
    };

    const createAutoBatches = () => {
      if (isDelmarTenant(appState.subdomain)) {
        return appState.setAlert(
          `This feature is temporarily disabled. Please contact support for more information.`,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }
      appState.showConfirmation(
        "Confirm",
        "Are you sure you want to run auto-batching?",
        () => {
          createAutoBatchesQuery.fetchData();
          appState.hideConfirmation();
        },
        appState.hideConfirmation,
      );
    };

    useEffect(() => {
      if (createAutoBatchesQuery.data) {
        appState.setAlert(
          createAutoBatchesQuery.data.createAutoOrderBatches.message,
          "success",
          ALERT_VISIBILITY_IN_MS,
        );
        setSelectedOrders([]);
        ordersQuery.fetchData({
          perPage: entity.perPage,
          filters: entity.filters,
          paginated: false,
          pageNumber: 1,
          sort: entity.sort,
        });
      }
      if (createAutoBatchesQuery.error) {
        appState.setAlert(
          createAutoBatchesQuery.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
        ordersQuery.fetchData({
          perPage: entity.perPage,
          filters: entity.filters,
          paginated: false,
          pageNumber: 1,
          sort: entity.sort,
        });
      }
      if (createAutoBatchesQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
    }, [
      createAutoBatchesQuery.loading,
      createAutoBatchesQuery.error,
      createAutoBatchesQuery.data,
    ]);

    useEffect(() => {
      if (simulateAutoBatchesQuery.data) {
        setSimulatedBatches(
          simulateAutoBatchesQuery.data.simulateAutoOrderBatches,
        );
      }
      if (simulateAutoBatchesQuery.error) {
        setSimulatedBatches([]);
      }
      if (simulateAutoBatchesQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
    }, [
      simulateAutoBatchesQuery.loading,
      simulateAutoBatchesQuery.error,
      simulateAutoBatchesQuery.data,
    ]);

    useEffect(() => {
      if (searchOrdersQuery.error) {
        appState.setAlert(
          searchOrdersQuery.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }
    }, [searchOrdersQuery.error]);

    useEffect(() => {
      (async () => {
        const response = await getSearchFiltersQuery.fetchData();

        if (response.data?.getSearchFilters) {
          setSearchFilters(response.data.getSearchFilters);
        }
      })();
    }, []);

    const fetchEnhancedSearchResults = async (payload) => {
      if (searchOrdersQuery.loading) return;

      if (!payload.keyword) {
        setSearchResults({});
        return;
      }

      const response = await searchOrdersQuery.fetchData(payload);
      const orders = response.data?.searchOrders;

      if (orders?.entities?.length > 0) {
        setSearchResults((prev) => ({
          ...orders,
          entities: [...(prev?.entities || []), ...orders.entities],
        }));
      }
    };

    const markUnprocessed = (id) => {
      appState.showConfirmation(
        "Confirm",
        "Are you sure you want to mark these orders as UNPROCESSED?",
        () => {
          manuallyMarkOrdersQuery.fetchData({
            ids: [id],
            status: "UNPROCESSED",
          });
        },
        appState.hideConfirmation,
      );
    };

    const markInProcess = (id) => {
      appState.showConfirmation(
        "Confirm",
        "Are you sure you want to mark these orders as IN-PROCESS?",
        () => {
          manuallyMarkOrdersQuery.fetchData({
            ids: [id],
            status: "IN-PROCESS",
          });
        },
        appState.hideConfirmation,
      );
    };

    const markCancelled = (id) => {
      appState.showConfirmation(
        "Confirm",
        "Are you sure you want to mark this order as CANCELLED?",
        () => {
          manuallyMarkOrdersQuery.fetchData({
            ids: [id],
            status: "CANCELLED",
          });
        },
        appState.hideConfirmation,
      );
    };

    /**
     * Mark an order as shipped on the marketplace after user confirmation.
     *
     * @function
     * @name markShippedOnMarketplace
     *
     * @param {string} id - The unique identifier for the order.
     *
     * @returns {void}
     *
     * @example
     * // Mark order with ID 12we5w3es3 as shipped.
     * markShippedOnMarketplace(12we5w3es3);
     */
    const markShippedOnMarketplace = (id) => {
      appState.showConfirmation(
        "Confirm",
        "Are you sure you want to mark this order as shipped on marketplace?",
        () => {
          markOrderCompleteOnMarketplaceQuery.fetchData({
            orderId: id,
          });
        },
        appState.hideConfirmation,
      );
    };
    /**
     * Sets an order to be mark as completed by fetching specific order data and setting the
     * `orderToMarkComplete` state with the given order ID.
     *
     * @param {string|number} id - The ID of the order to be marked as completed.
     *
     */
    const markCompleted = (id) => {
      fetchSpecificOrder.fetchData({ orderId: id });
      getOrderChangesPreviewQuery.fetchData({
        getOrderChangesPreviewId: id,
        operationType: "COMPLETED",
      });
      setOrderToMarkComplete(id);
    };
    const bulkMarkCompleted = async (orderIds) => {
      try {
        setExpandedOrderInfo(null);
        setBulkOrdersToMarkComplete(null);

        const eligibleStatuses = [
          "UNPROCESSED",
          "BATCHED",
          "IN-PROCESS",
          "BATCHED_PARTIALLY",
          "PARTIAL_COMPLETED",
          "INCOMPLETE",
          "EXCEPTION",
        ];

        const orderStatuses = await Promise.all(
          orderIds.map(async (id) => {
            try {
              const response = await fetchSpecificOrder.fetchData({
                orderId: id,
              });

              return {
                orderId: id,
                status: response?.data?.order?.orderStatus,
              };
            } catch (error) {
              return { orderId: id, status: null };
            }
          }),
        );

        const allEligible = orderStatuses.every((order) =>
          eligibleStatuses.includes(order.status),
        );

        if (allEligible) {
          setExpandedOrderInfo(null);
          setBulkOrdersToMarkComplete(orderIds);

          appState.showConfirmation(
            "Confirm",
            "Are you sure you want to mark these orders as COMPLETED?",
            () => {
              manuallyMarkOrdersQuery.fetchData({
                ids: orderIds,
                status: "COMPLETED",
                markCompleteOnMarketplace: true,
              });
            },
            appState.hideConfirmation,
          );

          setBulkOrdersToMarkComplete(null);
          setMarkCompleteOnMarketplaceCheck(false);
          setExpandedOrderInfo(null);
          clearSelectedOrders();
        }

        setExpandedOrderInfo(null);
      } catch (error) {
        console.error(
          "An error occurred while marking orders as completed:",
          error,
        );
      }
    };

    const bulkMarkCancelled = async (orderIds) => {
      try {
        setExpandedOrderInfo(null);
        setBulkOrdersToMarkCancelled(null);

        const eligibleStatuses = [
          "UNPROCESSED",
          "BATCHED",
          "IN-PROCESS",
          "BATCHED_PARTIALLY",
          "PARTIAL_COMPLETED",
          "INCOMPLETE",
          "EXCEPTION",
          ,
        ];

        const orderStatuses = await Promise.all(
          orderIds.map(async (id) => {
            try {
              const response = await fetchSpecificOrder.fetchData({
                orderId: id,
              });

              return {
                orderId: id,
                status: response?.data?.order?.orderStatus,
              };
            } catch (error) {
              return { orderId: id, status: null };
            }
          }),
        );

        const allEligible = orderStatuses.every((order) =>
          eligibleStatuses.includes(order.status),
        );

        if (allEligible) {
          setExpandedOrderInfo(null);

          setBulkOrdersToMarkCancelled(orderIds);

          appState.showConfirmation(
            "Confirm",
            "Are you sure you want to mark these orders as CANCELLED?",
            () => {
              cancelOrdersQuery.fetchData({
                ids: orderIds,
                reason: `Order cancelled manually by ${auth.user.name}`,
                markCancelOnMarketplace: false,
              });
            },
            appState.hideConfirmation,
          );

          setBulkOrdersToMarkCancelled(null);

          setExpandedOrderInfo(null);
          clearSelectedOrders();
        }

        setExpandedOrderInfo(null);
      } catch (error) {
        console.error(
          "An error occurred while marking orders as completed:",
          error,
        );
      }
    };

    /**
     * Confirms the marking of an order as completed. Fetches data related to the order using
     * the `manuallyMarkOrdersQuery`, and updates the status to "COMPLETED". Also, resets
     * the `orderToMarkComplete`, `markCompleteOnMarketplaceCheck`, and `expandedOrderInfo` states.
     *
     */
    const confirmMarkCompleted = () => {
      manuallyMarkOrdersQuery.fetchData({
        ids: [orderToMarkComplete],
        status: "COMPLETED",
        markCompleteOnMarketplace: markCompleteOnMarketplaceCheck,
      });
      setOrderToMarkComplete(null);
      setMarkCompleteOnMarketplaceCheck(false);
      setExpandedOrderInfo(null);
    };

    const markPartialCompleted = () => {
      appState.showConfirmation(
        "Confirm",
        "Are you sure you want to mark these orders as PARTIAL COMPLETED?",
        () => {
          manuallyMarkOrdersQuery.fetchData({
            ids: selectedOrders,
            status: "PARTIAL_COMPLETED",
          });
        },
        appState.hideConfirmation,
      );
    };

    const resetAllData = () => {
      appState.showConfirmation(
        "Confirm",
        "Are you sure you want to reset these orders?",
        () => {
          manuallyMarkOrdersQuery.fetchData({
            ids: entity.entities.map((item) => item.id),
            status: "UNPROCESSED",
          });
        },
        appState.hideConfirmation,
      );
    };

    const addNewOrder = (orderType) => {
      const warehouses = auth.user.warehousesList;
      const customers = auth.user.customersList;
      if (warehouses.length === 0 || customers.length === 0) {
        return appState.setAlert(
          `You don't have necessary permission to execute this action.`,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }
      if (orderType === "STO" && warehouses.length < 2) {
        return appState.setAlert(
          `You need at least 2 warehouses to create a STO order.`,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }
      let selectedWarehouse, selectedCustomer;
      let warehouseToBeSelected = true;
      let customerToBeSelected = true;
      if (warehouses.length === 1) {
        selectedWarehouse = warehouses[0].id;
        warehouseToBeSelected = false;
      }
      if (customers.length === 1) {
        selectedCustomer = customers[0].id;
        customerToBeSelected = false;
        inventoryQuery.fetchData({
          perPage: 10,
          filters: {
            warehouse: selectedWarehouse ? [selectedWarehouse] : null,
            customer: selectedCustomer ? [selectedCustomer] : null,
            includeBundles: true,
          },
          paginated: false,
          pageNumber: 1,
          sort: "asin",
        });
      }

      const selectedOrderObj = {
        orderType,
        warehouse: selectedWarehouse,
        customer: selectedCustomer,
        warehouseToBeSelected,
        customerToBeSelected,
        toValidAddress: true,
      };

      setSelectedOrder(selectedOrderObj);
      setOrderBeingCreated(true);
    };

    const addItem = (addedProduct) => {
      let currentOrderInfo = selectedOrder;
      if (
        !currentOrderInfo.orderLineItems ||
        currentOrderInfo.orderLineItems.length === 0
      ) {
        currentOrderInfo.orderLineItems = [];
      }

      if (addedProduct.idx >= 0) {
        currentOrderInfo.orderLineItems[addedProduct.idx] = {
          ...addedProduct,
          idx: null,
          editing: false,
        };
      } else {
        currentOrderInfo.orderLineItems.push({ ...addedProduct });
      }

      setSelectedOrder({ ...currentOrderInfo });
    };

    const removeItem = (idx) => {
      let currentOrderInfo = selectedOrder;
      if (
        !currentOrderInfo.orderLineItems ||
        currentOrderInfo.orderLineItems.length === 0
      ) {
        return;
      }

      currentOrderInfo.orderLineItems = currentOrderInfo.orderLineItems.filter(
        (item, index) => index !== idx,
      );
      setSelectedOrder({ ...currentOrderInfo });
    };

    const removeDelivery = (idx) => {
      let currentSelectedOrder = selectedOrder;
      if (
        !currentSelectedOrder.orders ||
        currentSelectedOrder.orders.length === 0
      ) {
        return;
      }

      currentSelectedOrder.orders = currentSelectedOrder.orders.filter(
        (item, index) => index !== idx,
      );
      setSelectedOrder({ ...currentSelectedOrder });
    };

    const onChangeItem = (idx, e) => {
      let currentOrderInfo = selectedOrder;
      const item = currentOrderInfo.orderLineItems[idx];
      item[e.target.name] =
        e.target.type === "number" ? parseInt(e.target.value) : e.target.value;
      currentOrderInfo.orderLineItems[idx] = item;
      setSelectedOrder({ ...selectedOrder });
    };

    const onChange = (e) => {
      const order = {
        ...selectedOrder,
      };

      if (e.target.type === "number") {
        order[e.target.name] = parseInt(e.target.value);
      } else {
        order[e.target.name] = e.target.value;
      }

      setSelectedOrder(order);
    };

    const viewShipmentPlan = (fbaShipment) => {
      setFbaOrderSelected(fbaShipment);

      getFBAShipments.fetchData({
        customer: fbaShipment.customer,
        shipmentIds: [fbaShipment.reference],
        marketplaceCountryCode: fbaShipment.marketplaceCountryCode ?? "US",
        sellerId: fbaShipment.sellerId,
      });
    };

    const updateShipmentPlan = (shipmentPlan) => {
      if (shipmentPlan) {
        const transformedShipmentPlan = {
          ...shipmentPlan,
          customer: fbaOrderSelected.customer,
          warehouse: fbaOrderSelected.warehouse,
        };
        setShipmentPlan({ ...transformedShipmentPlan });
        updateFBAShipment.fetchData({
          ...transformedShipmentPlan,
        });
      } else {
        setShipmentPlan(null);
      }
    };

    useEffect(() => {
      if (getFBAShipments.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (getFBAShipments.data && !importFbaShipmentModal) {
        setShipmentPlan(getFBAShipments.data.getFBAShipments.shipments[0]);
      }
      if (getFBAShipments.data && importFbaShipmentModal) {
        setImportShipment(getFBAShipments.data.getFBAShipments.shipments[0]);
        appState.setAlert(
          getFBAShipments.data.getFBAShipments.message,
          "success",
          ALERT_VISIBILITY_IN_MS,
        );
      }

      if (getFBAShipments.error) {
        appState.setAlert(
          getFBAShipments.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }
    }, [getFBAShipments.loading, getFBAShipments.error, getFBAShipments.data]);

    useEffect(() => {
      if (updateFBAShipment.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (updateFBAShipment.data) {
        appState.setAlert(
          updateFBAShipment.data.updateFbaShipment.message,
          "success",
          ALERT_VISIBILITY_IN_MS,
        );
        setShipmentPlan(null);
        ordersQuery.fetchData({
          perPage: entity.perPage,
          pageNumber: 1,
          filters: {
            ...entity.filters,
          },
          sort: entity.sort,
        });
      }

      if (updateFBAShipment.error) {
        appState.setAlert(
          updateFBAShipment.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }
    }, [
      updateFBAShipment.loading,
      updateFBAShipment.error,
      updateFBAShipment.data,
    ]);

    /**
     * Updates the import filters with a given field and value.
     *
     * @function
     * @param {string} field - The name of the filter to change.
     * @param {string|number|boolean|Object} value - The value to set for the specified filter.
     */
    const onChangeDropdownImport = (field, value) => {
      const filters = {
        ...importFilters,
      };

      filters[field] = value;
      setImportFilters({ ...filters });
    };

    const onChangeDropdown = (field, value) => {
      const order = {
        ...selectedOrder,
      };

      if (field === "customer") {
        if (value !== order[field]) {
          inventoryQuery.fetchData({
            perPage: 10,
            filters: {
              customer: [value],
              includeBundles: true,
            },
            paginated: false,
            pageNumber: 1,
            sort: "asin",
          });

          if (order.orderLineItems && order.orderLineItems.length > 0) {
            order.orderLineItems = [];
          }

          if (order.orderType === "STO") {
            order["toWarehouse"] = null;
            order["warehouse"] = null;
          }
        }
      }

      order[field] = value;
      setSelectedOrder({ ...order });
    };

    const onChangeInventorySearch = (keyword) => {
      const filtersToApply = {
        keyword,
        customer: [selectedOrder.customer],
        warehouse: [selectedOrder.warehouse],
        includeBundles: true,
      };

      inventoryQuery.fetchData({
        perPage: 10,
        pageNumber: 1,
        filters: filtersToApply,
        paginated: false,
      });
    };

    const debouncedInventorySearch = _.debounce(onChangeInventorySearch, 1000);

    const saveDeliveryInfo = () => {
      const currentDeliveryInfo = deliveryInfo;
      const currentSelectedOrder = selectedOrder;
      if (!currentSelectedOrder.orders) {
        currentSelectedOrder.orders = [];
      }
      currentSelectedOrder.orders.push({ ...currentDeliveryInfo });
      setSelectedOrder({ ...currentSelectedOrder });
      setDeliveryInfo(null);
    };

    useEffect(() => {
      if (saveOrderQuery.error) {
        appState.setAlert(
          saveOrderQuery.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }

      if (saveOrderQuery.data) {
        appState.setAlert(
          saveOrderQuery.data.saveOrder.message,
          "success",
          ALERT_VISIBILITY_IN_MS,
        );
        setSelectedOrder(null);
        setOrderBeingCreated(false);
        ordersQuery.fetchData({
          perPage: entity.perPage,
          pageNumber: 1,
          filters: { ...entity.filters },
          sort: entity.sort,
          paginated: false,
        });
      }

      if (saveOrderQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
    }, [saveOrderQuery.error, saveOrderQuery.data, saveOrderQuery.loading]);

    useEffect(() => {
      if (createSalesOrderQuery.error) {
        appState.setAlert(
          createSalesOrderQuery.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }

      if (createSalesOrderQuery.data) {
        appState.setAlert(
          createSalesOrderQuery.data.createSalesOrder.message,
          "success",
          ALERT_VISIBILITY_IN_MS,
        );
        setSelectedSalesOrder(null);
        setSalesOrderMode(null);
        ordersQuery.fetchData({
          perPage: entity.perPage,
          pageNumber: 1,
          filters: { ...entity.filters },
          sort: entity.sort,
          paginated: false,
        });
      }

      if (createSalesOrderQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
    }, [
      createSalesOrderQuery.error,
      createSalesOrderQuery.data,
      createSalesOrderQuery.loading,
    ]);

    useEffect(() => {
      if (createCRMCustomerQuery.error) {
        appState.setAlert(
          createCRMCustomerQuery.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }

      if (createCRMCustomerQuery.data) {
        appState.setAlert(
          createCRMCustomerQuery.data.createCrmCustomer.message,
          "success",
          ALERT_VISIBILITY_IN_MS,
        );
        crmCustomersQuery.fetchData();
        setAddCustomerPanel(false);
      }

      if (createCRMCustomerQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
    }, [
      createCRMCustomerQuery.error,
      createCRMCustomerQuery.data,
      createCRMCustomerQuery.loading,
    ]);

    useEffect(() => {
      if (updateCRMCustomerQuery.error) {
        appState.setAlert(
          updateCRMCustomerQuery.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }

      if (updateCRMCustomerQuery.data) {
        appState.setAlert(
          updateCRMCustomerQuery.data.updateCrmCustomer.message,
          "success",
          ALERT_VISIBILITY_IN_MS,
        );
        crmCustomersQuery.fetchData();
      }

      if (updateCRMCustomerQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
    }, [
      updateCRMCustomerQuery.error,
      updateCRMCustomerQuery.data,
      updateCRMCustomerQuery.loading,
    ]);

    useEffect(() => {
      if (getAvalaraTaxInvoiceQuery.error) {
        appState.setAlert(
          getAvalaraTaxInvoiceQuery.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }

      if (getAvalaraTaxInvoiceQuery.data?.createAvalaraTaxInvoice) {
        appState.setAlert(
          "Sales Tax calculated successfully",
          "success",
          ALERT_VISIBILITY_IN_MS,
        );

        setAvalaraTaxInvoice(
          getAvalaraTaxInvoiceQuery.data.createAvalaraTaxInvoice,
        );
      }

      if (getAvalaraTaxInvoiceQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
    }, [
      getAvalaraTaxInvoiceQuery.error,
      getAvalaraTaxInvoiceQuery.data,
      getAvalaraTaxInvoiceQuery.loading,
    ]);

    const getAvalaraTaxInvoice = () => {
      if (!cart || cart.length === 0) {
        return appState.setAlert(
          "Please add items to the cart",
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }
      const createAvalaraTaxInvoice = {
        customer: selectedSalesOrder.company,
        warehouse: selectedSalesOrder.warehouse,
        shippingAddress: selectedSalesOrder.shippingAddress,
        test: true,
        orderLineItems: (cart ?? []).map((item) => ({
          productId: item.id,
          formFactor: item.baseUom,
          quantity: parseInt(item.quantity, 10),
          lotId: item.lotId,
          nestedFormFactorId: item.nestedFormFactorId,
          marketplaceAttributes: item.marketplaceAttributes,
          attributes: item.attributes,
          fnSku: item.fnSku,
          sku: item.sku,
          name: item.name,
          price: parseFloat(item.price) / parseInt(item.quantity),
        })),
      };
      getAvalaraTaxInvoiceQuery.fetchData(createAvalaraTaxInvoice);
    };

    useEffect(() => {
      if (validateProductForHZQuery.error) {
        appState.setAlert(
          validateProductForHZQuery.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }

      if (
        validateProductForHZQuery.data?.validateProductForHardinessZone
          ?.isShippingAllowedToAddress === true
      ) {
        const itemExistsInCart = cart?.find(
          (item) => item.sku === productForCart.sku,
        );

        if (itemExistsInCart) {
          setCart((prev) =>
            prev.map((item) =>
              item.sku === itemExistsInCart.sku ? productForCart : item,
            ),
          );
        } else {
          const currentCart = cart || [];
          setCart([...currentCart, productForCart]);
        }

        appState.setAlert(
          "Item added to cart",
          "success",
          ALERT_VISIBILITY_IN_MS,
        );
      }

      if (
        validateProductForHZQuery.data?.validateProductForHardinessZone
          ?.errorMessage
      ) {
        appState.setAlert(
          validateProductForHZQuery.data.validateProductForHardinessZone
            .errorMessage,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }
      if (
        validateProductForHZQuery.data?.validateProductForHardinessZone
          ?.hardinessWarningMessage
      ) {
        appState.setAlert(
          validateProductForHZQuery.data.validateProductForHardinessZone
            .hardinessWarningMessage,
          "success",
          ALERT_VISIBILITY_IN_MS,
        );
      }

      if (validateProductForHZQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
    }, [
      validateProductForHZQuery.error,
      validateProductForHZQuery.data,
      validateProductForHZQuery.loading,
    ]);

    useEffect(() => {
      updateOrderQuery.loading
        ? appState.setLoading()
        : appState.removeLoading();

      if (updateOrderQuery.data) {
        appState.setAlert(
          updateOrderQuery.data.updateOrder.message,
          "success",
          ALERT_VISIBILITY_IN_MS,
        );
        appState.hideConfirmation();
        setSelectedOrder(null);
        setOrderBeingEdited(false);
        ordersQuery.fetchData({
          perPage: entity.perPage,
          pageNumber: 1,
          filters: { ...entity.filters },
          sort: entity.sort,
        });
      }
      if (updateOrderQuery.error) {
        appState.setAlert(
          updateOrderQuery.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }
    }, [
      updateOrderQuery.loading,
      updateOrderQuery.data,
      updateOrderQuery.error,
    ]);

    /**
     * Converts a date string to epoch time with the local time zone.
     * @param {string} dateString - The date string to convert.
     * @returns {number} The epoch time with the local time zone.
     */
    const getEpochWithLocalTimeZone = (dateString) => {
      const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      return moment.tz(dateString, userTimezone).valueOf();
    };

    const showError = (error) => {
      appState.setAlert(error, "error", ALERT_VISIBILITY_IN_MS);
    };

    // This function will validate the order, mainly for the order create/edit
    const validateOrderData = () => {
      if (!selectedOrder?.warehouse) {
        showError("Please select the warehouse");
        return false;
      }

      if (!selectedOrder.customer) {
        showError("Please select the client");
        return false;
      }

      if (selectedOrder.orderType === "STO") {
        if (!selectedOrder.toWarehouse) {
          showError("Please select the 'to Warehouse'");
          return false;
        }

        if (selectedOrder.warehouse === selectedOrder.toWarehouse) {
          showError("from warehouse and to warehouse cannot be the same");
          return false;
        }
      }

      if (
        !selectedOrder.orderLineItems ||
        !selectedOrder.orderLineItems.length > 0
      ) {
        showError("Add line items to create Order");
        return false;
      }

      return true;
    };
    const handleOrderDataSubmission = () => {
      if (!validateOrderData()) {
        return;
      }
      const orderDateInEpoch = selectedOrder.orderDate
        ? getEpochWithLocalTimeZone(selectedOrder.orderDate)
        : Date.now();
      const order = {
        ..._.omit(selectedOrder, ["selectedCarrierRateData"]),
        orderDate: orderDateInEpoch,
        orderLineItems: selectedOrder.orderLineItems.map((item) => ({
          productId: item.productId,
          formFactor: item.formFactors[0].name,
          quantity: item.formFactors[0].quantity,
          lotId:
            item.formFactors[0].lotId && item.formFactors[0].lotId.trim() !== ""
              ? item.formFactors[0].lotId
              : null,
          expiryDate:
            item.formFactors[0].expiryDate &&
            item.formFactors[0].expiryDate.trim() !== ""
              ? item.formFactors[0].expiryDate
              : null,
          serialNumber:
            item.formFactors[0].serialNumber &&
            item.formFactors[0].serialNumber.trim() !== ""
              ? item.formFactors[0].serialNumber
              : null,
          poId:
            item.formFactors[0].poId && item.formFactors[0].poId.trim() !== ""
              ? item.formFactors[0].poId
              : null,
          nestedFormFactorId:
            item.formFactors[0].nestedFormFactorId &&
            item.formFactors[0].nestedFormFactorId.trim() !== ""
              ? item.formFactors[0].nestedFormFactorId
              : null,
          palletId:
            item.formFactors[0].palletId &&
            item.formFactors[0].palletId.trim() !== ""
              ? item.formFactors[0].palletId
              : null,
          marketplaceAttributes: item.marketplaceAttributes ?? null,
          attributes: item.attributes ?? null,
          fnSku: item.fnSku,
          sku: item.sku,
          name: item.name,
          ...(item.unitPrice && { unitPrice: item.unitPrice }),
        })),
        ...(selectedOrder?.selectedCarrierRateData || {}),
        source: selectedOrder?.source ? selectedOrder.source : "Hopstack",
        ...(selectedOrder?.reference &&
          selectedOrder?.source === "HOPSTACK_FBA" && {
            inboundPlanId: selectedOrder.reference,
          }),
        estimatedBoxes: selectedOrder?.estimatedBoxes?.map((estimatedBox) =>
          _.omit(estimatedBox, ["canBeUpdated", "boxId"]),
        ),
      };
      if (isOrderBeingCreated) {
        saveOrderQuery.fetchData(order);
      }
      if (isOrderBeingEdited || carrierUpdate) {
        const orderFields = Object.keys(order);
        const orderInput = { ...order };
        orderFields.forEach((field) => {
          if (!editOrderFields.has(field)) {
            delete orderInput[field];
          }
        });
        updateOrderQuery.fetchData({
          orderInput,
          user: auth.user?.id,
        });
      }
      setFileIndex(Math.random() * 100);
    };

    const handleSalesOrderSubmission = ({
      paymentDetails,
      cartDetails,
      attributes,
    }) => {
      if (!validateSalesOrderData()) {
        return;
      }

      const { id, ...shippingAddress } = selectedSalesOrder.shippingAddress;

      const defaultCustomer = auth.user.customersList.find(
        (cus) => cus.code === "DEF",
      )?.id;

      const salesOrderInput = {
        orderType: "Regular",
        warehouse: selectedSalesOrder?.warehouse,
        customer: defaultCustomer,
        orderLineItems: cart.map((item) => ({
          productId: item.id,
          formFactor: item.baseUom,
          quantity: item.quantity,
          attributes: item.attributes,
          sku: item.sku,
          name: item.name,
          price: parseFloat(item?.price || 0),
          currency: "USD",
          units: parseInt(item.units),
        })),
        source: OrderSource.HOPSTACK_SALES_PORTAL,
        customerIdOnCrm: selectedSalesOrder?.customer,
        salesChannel: selectedSalesOrder?.company,
        shippingAddress,
        paymentDetails,
        cartDetails,
        attributes,
      };
      createSalesOrderQuery.fetchData({ salesOrderInput });
    };

    const validateSalesOrderData = () => {
      const defaultCustomer = auth.user.customersList.find(
        (cus) => cus.code === "DEF",
      )?.id;

      if (!selectedSalesOrder?.warehouse) {
        showError("Please select the warehouse");
        return false;
      }

      if (!selectedSalesOrder.company) {
        showError("Please select the holding company");
        return false;
      }

      if (!selectedSalesOrder.customer) {
        showError("Please select the customer");
        return false;
      }

      if (!cart.length > 0) {
        showError("Add line items to create Order");
        return false;
      }

      if (!defaultCustomer) {
        showError(
          "You lack the necessary permissions to create a sales order for this company.",
        );
      }

      return true;
    };

    const updateTracking = (order) => {
      if (order) {
        setTrackingUpdate({
          id: order.id,
          trackingNumber: order.trackingNumber,
          carrier: order.carrier,
          trackingUrl: order.trackingUrl,
          bolNumber: order.bolNumber,
          typeOfShipment: order.typeOfShipment,
          proNumber: order.proNumber,
          source: order.source,
          requestedCarrier: order.requestedCarrier,
          shippingAddress: order.shippingAddress,
          shippingCost: order.shippingCost,
          carrierService: order.carrierService,
          dateOfShipping: order.dateOfShipping,
          deliveryDate: order.deliveryDate,
          bolData: order?.bolData,
          thirdPartyFreightChargesBill: order.thirdPartyFreightChargesBill,
        });
      }
    };

    const updateOrderLineItem = () => {
      const orderLineItemInfo = {
        id: selectedOrderLineItem.id,
        serialNumber: selectedOrderLineItem.serialNumber,
        poId: selectedOrderLineItem.poId,
        overrideSerialNumber: true,
      };
      updateOrderLineItemQuery.fetchData({
        orderLineItemInput: orderLineItemInfo,
      });
    };

    const onChangeSelectedOrderLineItem = (e) => {
      const orderLineItem = { ...selectedOrderLineItem };
      orderLineItem[e.target.name] = e.target.value;
      setSelectedOrderLineItem({ ...orderLineItem });
    };

    const updateCarrier = (order) => {
      if (order) {
        fetchOrderForCarrierUpdate.fetchData({ orderId: order.id });
      }
    };

    const submitTrackingUpdate = () => {
      const updateTrackingInfo = { ...trackingUpdate };

      updateTrackingInfoQuery.fetchData({ orderInfo: updateTrackingInfo });
    };

    const submitCarrierUpdate = () => {
      const updateCarrierInfo = { ...carrierUpdate };

      updateCarrierInfoQuery.fetchData({
        orderInfo: updateCarrierInfo,
      });
    };

    const submitBolData = async (orderInfo) => {
      appState.setLoading();
      const response = await updateCarrierInfoQuery.fetchData({
        orderInfo,
      });

      if (response.data) {
        appState.setAlert(
          response.data.updateCarrierInfo.message,
          "success",
          ALERT_VISIBILITY_IN_MS,
        );
        setBolBuildData(null);
        appState.removeLoading();
      }

      if (response.error) {
        appState.setAlert(
          response.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
        appState.removeLoading();
      }
    };

    const uploadFile = (e, labelType = "shippingLabel") => {
      if (
        ["image/jpeg", "image/png", "application/pdf"].includes(e.type) ===
        false
      ) {
        return appState.setAlert(
          `Only upload files in the following formats JPG, PNG or PDF.`,
          "error",
          8000,
        );
      }
      if (labelType === "shippingLabel") {
        setTrackingUpdate({
          ...trackingUpdate,
          labelData: e.base64,
          labelType: e.type,
          labelUrl: null,
        });
      } else {
        setTrackingUpdate({
          ...trackingUpdate,
          bolData: {
            ...trackingUpdate.bolData,
            labelData: e.base64,
            labelType: e.type,
            labelUrl: null,
          },
        });
      }
    };

    const editOrder = (order) => {
      if (order) {
        setSelectedOrder({ ...order });
        getNonEditableFieldsForOrderQuery.fetchData({
          orderId: order?.id,
          userId: auth.user?.id,
        });
      }
    };

    const deleteOrder = (id) => {
      appState.showConfirmation(
        "Confirm",
        "Are you sure you want to delete this order?",
        () => {
          deleteOrderQuery.fetchData({
            id,
          });
        },
        appState.hideConfirmation,
      );
    };

    useEffect(() => {
      if (downloadOrderReportQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (downloadOrderReportQuery.data) {
        pdf(
          <GoodsShippedNote
            order={downloadOrderReportQuery.data.order}
            customer={
              auth.user?.customersList
                ? auth.user.customersList.find(
                    (customer) =>
                      customer.id ===
                      downloadOrderReportQuery.data.order.customer,
                  )?.name
                : null
            }
            warehouse={
              auth.user?.warehousesList
                ? auth.user.warehousesList.find(
                    (warehouse) =>
                      warehouse.id ===
                      downloadOrderReportQuery.data.order.warehouse,
                  )?.name
                : null
            }
            tenant={appState.tenant}
          />,
        )
          .toBlob()
          .then((blob) =>
            saveAs(
              blob,
              `Order-Report-${downloadOrderReportQuery.data.order.orderId}.pdf`,
            ),
          )
          .catch((err) => alert(err));
      }

      if (downloadOrderReportQuery.error) {
        appState.setAlert(
          downloadOrderReportQuery.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }
    }, [
      downloadOrderReportQuery.loading,
      downloadOrderReportQuery.error,
      downloadOrderReportQuery.data,
    ]);

    useEffect(() => {
      if (getCustomerQuery.data) {
        setAvailableWarehousesForChange([
          ...auth.user.warehousesList.filter((w) =>
            getCustomerQuery.data.customer.warehouses.join(",").includes(w.id),
          ),
        ]);
      }

      if (getCustomerQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
    }, [
      getCustomerQuery.loading,
      getCustomerQuery.data,
      getCustomerQuery.error,
    ]);

    const downloadOrderReport = (orderId) => {
      downloadOrderReportQuery.fetchData({ orderId });
    };

    useEffect(() => {
      if (getFnSkuLabelsQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (getFnSkuLabelsQuery.data) {
        const fnSkus = [];
        for (const orderLineItem of getFnSkuLabelsQuery.data.order.orderLineItems.filter(
          (item) => !!item.fnSku,
        )) {
          fnSkus.push({
            fnSku: orderLineItem.fnSku,
            bestByDate: orderLineItem.bestByDate,
            productName: orderLineItem.productName,
            quantity: orderLineItem.quantity,
          });
        }
        setFnSkuOptions({
          fnSkus,
          printSize: "3x1",
          print: getFnSkuLabelsQuery.variables.print === true,
        });
      }

      if (getFnSkuLabelsQuery.error) {
        appState.setAlert(getFnSkuLabelsQuery.error.message);
      }
    }, [
      getFnSkuLabelsQuery.data,
      getFnSkuLabelsQuery.loading,
      getFnSkuLabelsQuery.error,
    ]);

    const submitFnSkuOptions = () => {
      if (fnSkuOptions.printSize === "30up") {
        appState.setLoading();
        printThirtyUpLabel(
          fnSkuOptions.fnSkus.map((i) => i.fnSku),
          1,
          fnSkuOptions.printerName,
          appState,
          fnSkuOptions.print,
        );
      } else {
        for (const code of fnSkuOptions.fnSkus) {
          appState.setLoading();
          printCanvasLabelPdf(
            `${code.fnSku}`,
            code.quantity,
            null,
            fnSkuOptions.printerName,
            fnSkuOptions.printSize,
            appState,
            fnSkuOptions.print,
            code.productName,
            code.bestByDate && `Best By: ${code.bestByDate}`,
          );
        }
      }
    };
    const handleAssignWarehouse = (order) => {
      setSelectedWarehouse({});
      setSelectedOrderForChange(order.id);

      getCustomerQuery.fetchData({ id: order.customer });
      checkIfOrderWarehouseCanBeChangedQuery.fetchData({ orderId: order.id });
    };

    const submitAssignWarehouse = () => {
      assignWarehouseQuery.fetchData({
        orderId: selectedOrderForChange,
        warehouseId: selectedWarehouse,
      });
    };

    const displayOrders = () => {
      let orders;

      if (showOnlySelected) {
        orders = entity.entities.filter((item) =>
          selectedOrders.includes(item.id),
        );
      } else {
        orders = entity.displayEntities;
      }

      // Sort orders by item.id in descending order
      orders.sort((a, b) => b.id - a.id);

      return orders;
    };

    /**
     * Executes a search for shipments based on the provided import filters.
     * - Ensures that the client, warehouse, and Shipment ID are selected/provided.
     * - If valid, it fetches the FBA shipments with the provided data.
     */
    const onImportShipmentSearch = () => {
      const data = {};
      if (!importFilters?.customer?.value) {
        appState.setAlert(
          "Please select the Client",
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
        return;
      }
      if (!importFilters?.warehouse?.value) {
        appState.setAlert(
          "Please select the Warehouse",
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
        return;
      }
      if (!shipmentImportSearch) {
        appState.setAlert(
          "Please enter the correct Shipment ID",
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
        return;
      }

      if (
        multiAccountSupportEnabled &&
        marketplaces.length > 0 &&
        sellerIds.length > 0 &&
        (!importFilters?.marketplace?.value || !importFilters?.sellerId?.value)
      ) {
        appState.setAlert(
          "Please select the Marketplace and Seller ID",
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
        return;
      }
      data.customer = importFilters.customer.value;
      data.marketplaceCountryCode = importFilters?.marketplace?.value;
      data.sellerId = importFilters?.sellerId?.value;
      data.shipmentIds = [shipmentImportSearch];
      getFBAShipments.fetchData(data);
    };

    /**
     * Confirms the import of a shipment based on the provided details.
     * - Ensures there is a shipment to import.
     * - If valid, it fetches the orders from marketplaces with the provided data.
     */
    const confirmImportShipment = () => {
      const data = {};
      if (!importShipment) {
        appState.setAlert(
          "There is nothing to import",
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
        return;
      }
      if (importShipment?.ShipmentStatus.toLowerCase() !== "working") {
        appState.setAlert(
          "You can only import shipments that are in Working status",
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
        return;
      } else {
        data.warehouse = importFilters.warehouse.value;
        data.customer = importFilters.customer.value;
        data.marketplace = "amazon";
        data.shipmentIds = [shipmentImportSearch];
        data.sellerId = importFilters?.sellerId?.value;
        data.marketplaceCountryCode = importFilters?.marketplace?.value;
        data.region = amazonIntegrations.filter(
          (i) => i.sellerId === data.sellerId,
        ).region;
      }
      importOrdersFromMarketPlacesQuery.fetchData(data);
    };

    const fetchProductSkuBinMappings = (product) => {
      if (
        selectedOrder &&
        product?.productId &&
        selectedOrder.warehouse &&
        selectedOrder.customer &&
        (!productSkuBinMappings ||
          productSkuBinMappings.findIndex(
            (i) => i.product === product?.productId,
          ) === -1)
      ) {
        skuBinMappingsQuery.fetchData({
          perPage: entity.perPage,
          filters: {
            product: [product.productId],
            warehouse: [selectedOrder.warehouse],
            customer: [selectedOrder.customer],
            all: true,
          },
          paginated: false,
          pageNumber: 1,
          sort: entity.sort,
        });
      } else if (
        !productSkuBinMappings ||
        productSkuBinMappings.findIndex(
          (i) => i.product === product?.productId,
        ) === -1
      ) {
        setProductSkuBinMappings(null);
      }
    };
    /**
     * Triggers the print action for a specific packing label and fetches the order batch data.
     *
     * @param {string|number} id - The order batch ID.
     * @returns {void}
     */
    const printPackingLabels = (id) => {
      setPackingLabelAction("PRINT");
      fetchSpecificOrderBatchQuery.fetchData({
        orderBatchId: id,
        typeOfBatch: "PACKING",
      });
    };
    /**
     * Triggers the download action for a specific packing label and fetches the order batch data.
     *
     * @param {string|number} id - The order batch ID.
     * @returns {void}
     */
    const downloadPackingLabel = (id) => {
      setPackingLabelAction("DOWNLOAD");
      fetchSpecificOrderBatchQuery.fetchData({
        orderBatchId: id,
        typeOfBatch: "PACKING",
      });
    };

    /**
     * Triggers the download process for a packing list.
     *
     * @param {string|number} id - The unique identifier for the order batch.
     */
    const downloadPackingList = async (id) => {
      const currentOrder = ordersQuery.data?.orders?.entities.find(
        (entity) => entity.id === id,
      );
      const isProPrepTenant = isProprepTenant(appState?.subdomain);

      if (currentOrder?.source === OrderSource.SAP_B1_TYM || isProPrepTenant) {
        await getWarehouseQuery.fetchData({ id: currentOrder?.warehouse });
        setCurrentOrder(currentOrder);
      }

      fetchOrderBatchForPackingList.fetchData({
        orderBatchId: id,
        typeOfBatch: "PACKING",
      });
    };

    const getNewOrdersFromLocalStorage = () => {
      const storedOrders = JSON.parse(localStorage.getItem("newOrders")) || [];
      const currentTime = Date.now();
      return storedOrders
        .filter(
          (order) =>
            currentTime - order.timestamp < TIME_FOR_REMOVAL_FROM_LOCAL_STORAGE,
        )
        .map((order) => order.data);
    };

    const setNewOrdersToLocalStorage = (orders) => {
      const timestampedNewOrders = orders.map((order) => ({
        data: order,
        timestamp: Date.now(),
      }));
      localStorage.setItem("newOrders", JSON.stringify(timestampedNewOrders));
    };

    useEffect(() => {
      const storedNewOrders = getNewOrdersFromLocalStorage();
      setNewOrders(storedNewOrders);
      if (storedNewOrders.length > 0) {
        setLastCheckedOrderId(storedNewOrders[0].id); // Assuming the most recent order has the highest ID
      }
    }, []);

    // useEffect(() => {
    //   if (
    //     ordersQuery.data &&
    //     ordersQuery.data.orders &&
    //     ordersQuery.data.orders.entities
    //   ) {
    //     const allOrdersData = ordersQuery.data.orders.entities;

    //     const recentOrderId = allOrdersData.reduce((maxId, order) => {
    //       return order.id > maxId ? order.id : maxId;
    //     }, allOrdersData[0]?.id || null);

    //     if (
    //       lastCheckedOrderId === null ||
    //       lastCheckedOrderId > mostRecentOrderId
    //     ) {
    //       setLastCheckedOrderId(recentOrderId); // Initialize lastCheckedOrderId if null
    //     }
    //     if (recentOrderId > lastCheckedOrderId) {
    //       const newOrdersList = allOrdersData.filter(
    //         (order) => order.id > lastCheckedOrderId,
    //       );
    //       setNewOrdersAvailable(true);
    //       setNewlyAddedOrders(newOrdersList.length);
    //       const nonDuplicateNewOrders = newOrdersList.filter(
    //         (order) => !newOrders.some((newOrder) => newOrder.id === order.id),
    //       );

    //       const updatedNewOrders = [
    //         ...nonDuplicateNewOrders,
    //         ...newOrders.slice(0, 5 - newOrdersList.length),
    //       ];

    //       setNewOrders(updatedNewOrders);
    //       setNewOrdersToLocalStorage(updatedNewOrders);

    //       setLastCheckedOrderId(recentOrderId);
    //     }

    //     setMostRecentOrderId(recentOrderId);
    //   }
    // }, [ordersQuery.loading, ordersQuery.error, ordersQuery.data]);

    // let pollTimer;
    // // Polling effect to refetch data every 60  seconds
    // useEffect(() => {
    //   fetchOrdersFromBackendAfterPolling();

    //   return () => clearInterval(pollTimer);
    // }, [ordersQuery.loading, ordersQuery.error, ordersQuery.data]);

    // const fetchOrdersFromBackendAfterPolling = () => {
    //   pollTimer = setInterval(() => {
    //     ordersQuery
    //       .fetchData({
    //         perPage: entity.perPage,
    //         pageNumber: 1,
    //         filters: { ...entity.filters },
    //         sort: entity.sort,
    //       })
    //       .then((result) => {
    //         if (result.data) {
    //           const allOrdersData = result.data.orders.entities;

    //           const recentOrderId = allOrdersData.reduce((maxId, order) => {
    //             return order.id > maxId ? order.id : maxId;
    //           }, allOrdersData[0]?.id || null);

    //           if (recentOrderId > lastCheckedOrderId) {
    //             const newOrdersList = allOrdersData.filter(
    //               (order) => order.id > lastCheckedOrderId,
    //             );
    //             setNewlyAddedOrders(newOrdersList.length);
    //             setNewOrdersAvailable(true);
    //             const nonDuplicateNewOrders = newOrdersList.filter(
    //               (order) =>
    //                 !newOrders.some((newOrder) => newOrder.id === order.id),
    //             );
    //             const updatedNewOrders = [
    //               ...nonDuplicateNewOrders,
    //               ...newOrders.slice(0, 5 - newOrdersList.length),
    //             ];

    //             setNewOrders(updatedNewOrders);
    //             setNewOrdersToLocalStorage(updatedNewOrders);
    //             setLastCheckedOrderId(recentOrderId);
    //           }

    //           setMostRecentOrderId(recentOrderId);
    //         }
    //       })
    //       .catch((error) => {});
    //   }, TIME_INTERVAL_FOR_POLLING);
    // };

    /**
     * Generates and downloads a CSV file for given data.
     * The CSV file contains information about box names, dimensions, weight, order ID, SKU, and more.
     *
     * @param {Object} data - The input data to be converted into CSV format. It contains a workingList of items and a list of boxes.
     * @param {Object} products - The list of products.
     * @returns {void}
     */
    const generatePackListCsv = (data, products) => {
      const orderSource = currentOrder?.source;
      const isPdf =
        orderSource === OrderSource.SAP_B1_TYM ||
        isProprepTenant(appState?.subdomain);
      if (isPdf) {
        const warehouse = getWarehouseQuery?.data?.warehouse?.address;

        const orderDetails = {
          shippingAddress: currentOrder?.shippingAddress,
          billingAddress: currentOrder?.billingAddress,
          warehouse,
          carrier: currentOrder?.carrier,
        };

        pdf(
          <PackingSlip
            isProprepTenant={isProprepTenant(appState.subdomain)}
            data={{ ...data, ...orderDetails }}
          />,
        )
          .toBlob()
          .then((blob) =>
            saveAs(
              blob,
              `${[data?.attributes?.humanReadableOrderIds]?.join("-")}_Packing-List.pdf`,
            ),
          )
          .catch((err) => alert(err));
      } else {
        let csvData = [
          [
            "Box Name",
            "Box Dimension (in)",
            "Box Weight (lb)",
            "Order Id",
            "SKU",
            "Product Name",
            "Quantity",
            "SKU Form Factor",
            "Cost/Unit",
            "Total Cost",
            "Commodity Code",
            "Orgin",
            "Content",
          ],
        ];
        let groupedData = {};

        // Creating a map of products for easy lookup
        const productsMap = _.keyBy(products, "id");
        // Grouping data based on sku-boxName and summing up the quantities
        data?.workingList?.forEach((item) => {
          let key = `${item.sku}-${item.boxName}`;
          let box = data.boxes?.find((b) => b.name === item.boxName);
          let dimensions = box
            ? `${box?.length} x ${box?.width} x ${box?.height}`
            : "N/A";
          let boxWeight = box?.weight;
          if (groupedData[key]) {
            groupedData[key].quantity += item.quantity;
          } else {
            groupedData[key] = {
              boxName: item.boxName,
              dimensions: dimensions,
              boxWeight: boxWeight,
              orderId: data?.attributes?.humanReadableOrderIds?.map((i) => i),
              sku: item.sku,
              productName: item.name,
              quantity: item.quantity,
              formFactor: item.formFactor,
              commodityCode:
                productsMap[item.productId]?.attributes?.commodityCode,
              cog: productsMap[item.productId]?.attributes?.cog,
              origin: productsMap[item.productId]?.attributes?.origin,
              content: productsMap[item.productId]?.attributes?.content,
            };
          }
        });

        for (let key in groupedData) {
          let item = groupedData[key];
          csvData.push([
            item.boxName,
            item.dimensions,
            item.boxWeight,
            item.orderId,
            item.sku,
            item.productName,
            item.quantity,
            item.formFactor,
            item.cog,
            "", // Total Cost is blank
            item.commodityCode,
            item.origin,
            item.content,
          ]);
        }

        const csvContent = Papa.unparse(csvData);
        const blob = new Blob([csvContent], {
          type: "text/csv;charset=utf-8;",
        });
        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute(
          "download",
          `${data?.attributes?.humanReadableOrderIds?.join("-")}_Packing-List.csv`,
        );
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    };

    /**
     * This is only for Delmar at the moment.
     * This is to print ALL labels of the order in one-shot.
     * Keeping the name generic to extend this to other tenants as well.
     * @param {string} orderId - The unique identifier for the order.
     * @returns {void}
     */
    const printOrder = async (order) => {
      appState.setLoading();
      const fetchOrderWithLabels = await fetchOrderWithLabelsQuery.fetchData({
        orderId: order.id,
      });

      if (fetchOrderWithLabels.data) {
        const orderWithLabels = fetchOrderWithLabels.data.order;
        const printingList = getPrintFiles(orderWithLabels);
        appState.setAlert(`Printing labels`, "success", ALERT_VISIBILITY_IN_MS);
        for (const shippingLabel of printingList) {
          printShippingLabel(
            shippingLabel.file,
            appState,
            shippingLabel.printerName,
            shippingLabel.duplex,
          );
          labelEventQuery.fetchData({
            warehouse: order.warehouse,
            customer: order.customer,
            order: order.id,
            quantity: 1,
            labelType: "SHIPPING_LABEL",
            dropship: false,
          });
        }
      }
      appState.removeLoading();
    };
    const validateCreateCRMCustomer = () => {
      if (!newCustomer) {
        return appState.setAlert(
          "Please enter customer details",
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }
      if (!newCustomer.firstName) {
        return appState.setAlert(
          "Please enter first name",
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }
      if (!newCustomer.lastName) {
        return appState.setAlert(
          "Please enter last name",
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }
      if (!newCustomer?.accountAddress) {
        return appState.setAlert(
          "Please enter account address",
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }
      if (
        !newCustomer?.shippingAddresses ||
        newCustomer?.shippingAddresses?.length === 0
      ) {
        return appState.setAlert(
          "Please enter shipping address",
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }
      return true;
    };

    const createCRMCustomer = () => {
      if (!validateCreateCRMCustomer()) {
        return;
      }
      const {
        firstName,
        lastName,
        shippingAddresses,
        accountAddress,
        ...otherDetails
      } = newCustomer;

      const updatedAccountAddress = {
        ...accountAddress,
        firstName,
        lastName,
      };
      const updatedShippingAddresses =
        shippingAddresses?.map((address) => ({
          ...address,
          firstName,
          lastName,
        })) || [];

      const crmCustomerInput = {
        ...otherDetails,
        firstName,
        lastName,
        accountAddress: updatedAccountAddress,
        shippingAddresses: updatedShippingAddresses,
      };

      createCRMCustomerQuery.fetchData({ crmCustomerInput });
    };

    const viewInvoiceLabels = (invoiceLabelUrls) => {
      for (const invoiceLabelUrl of invoiceLabelUrls) {
        if (isValidURL(invoiceLabelUrl)) {
          saveAs(invoiceLabelUrl);
        } else {
          appState.setAlert(
            `${invoiceLabelUrl} is not a valid URL.`,
            "error",
            ALERT_VISIBILITY_IN_MS,
          );
        }
      }
    };

    const addNewSalesOrder = () => {
      const warehouses = auth.user.warehousesList;
      const customers = auth.user.customersList;
      if (warehouses.length === 0 || customers.length === 0) {
        return appState.setAlert(
          `You don't have necessary permission to execute this action.`,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }
      let selectedWarehouse, selectedCustomer;
      let warehouseToBeSelected = true;
      let customerToBeSelected = true;
      let companyToBeSelected = true;
      if (warehouses.length === 1) {
        selectedWarehouse = warehouses[0].id;
        warehouseToBeSelected = false;
      }
      if (customers.length === 1) {
        selectedCustomer = customers[0].id;
        customerToBeSelected = false;
      }
      const salesOrder = {
        warehouse: selectedWarehouse,
        customer: selectedCustomer,
        warehouseToBeSelected,
        customerToBeSelected,
        companyToBeSelected,
      };
      setSelectedSalesOrder(salesOrder);
      setSalesOrderMode(SALES_ORDER_MODES.CREATE);
    };

    const onChangeSalesDropdown = (field, value) => {
      const salesOrder = {
        ...selectedSalesOrder,
      };

      salesOrder[field] = value;
      setSelectedSalesOrder({ ...salesOrder });
    };

    const handleAddToCart = (product) => {
      setProductForCart(product);

      const shippingAddress = selectedSalesOrder?.shippingAddress;

      const validateProductForHardinessZoneInput = {
        product: {
          sku: product.sku,
          attributes: {
            fromHZ: parseInt(product.attributes?.fromHZ),
            toHZ: parseInt(product.attributes?.toHZ),
            noShipAreas: product.attributes?.noShipAreas,
            isIndoorPlant: product.attributes?.isIndoorPlant,
          },
        },
        shippingAddress: {
          zip: shippingAddress?.zip,
          plantingZoneCode: shippingAddress?.plantingZoneCode,
          isPlantingZoneAddress: shippingAddress?.isPlantingZoneAddress,
          state: shippingAddress?.state,
        },
      };

      validateProductForHZQuery.fetchData({
        validateProductForHardinessZoneInput,
      });
    };

    const startOrderPolling = (bulkUploadId) => {
      const autoRefreshInterval = setInterval(async () => {
        try {
          const listBulkUploadInput = {
            entityType: BULK_UPLOAD_ENTITY_TYPES?.ORDER,
            id: bulkUploadId,
          };
          const getPastBulkUploadsResponse =
            await listPastBulkUploadQuery.fetchData({
              listBulkUploadInput: listBulkUploadInput,
            });
          if (getPastBulkUploadsResponse?.data) {
            const pastUploadEntities =
              getPastBulkUploadsResponse?.data?.listBulkUpload?.entities;
            if (pastUploadEntities && pastUploadEntities?.length !== 0) {
              if (
                pastUploadEntities[0]?.processingStatus ===
                  STATUS_ENUM.COMPLETED ||
                pastUploadEntities[0]?.processingStatus ===
                  STATUS_ENUM.FAILED ||
                pastUploadEntities[0]?.processingStatus ===
                  STATUS_ENUM.PARTIAL_COMPLETE
              ) {
                clearInterval(autoRefreshInterval);
                setShowBanner(true);
                if (
                  pastUploadEntities[0]?.processingStatus ===
                  STATUS_ENUM.COMPLETED
                ) {
                  setTypeOfBanner(BANNER_TYPE_ENUM?.COMPLETED);
                } else if (
                  pastUploadEntities[0]?.processingStatus === STATUS_ENUM.FAILED
                ) {
                  setTypeOfBanner(BANNER_TYPE_ENUM?.FAILED);
                } else if (
                  pastUploadEntities[0]?.processingStatus ===
                  STATUS_ENUM.PARTIAL_COMPLETE
                ) {
                  setTypeOfBanner(BANNER_TYPE_ENUM?.PARTIAL_COMPLETE);
                }
                localStorage.removeItem(BULK_UPLOAD_KEYS?.ORDER_ID);
                localStorage.removeItem(BULK_UPLOAD_KEYS?.ORDER_FILE);
                ordersQuery.fetchData({
                  perPage: entity.perPage,
                  pageNumber: 1,
                  filters: { ...entity.filters },
                  sort: entity.sort,
                  paginated: false,
                });
              }
            }
          }
        } catch (error) {
          clearInterval(autoRefreshInterval);
          setTypeOfBanner(null);
          setShowBanner(false);
        }
      }, POLLING_TIMER);
    };

    useEffect(() => {
      if (bulkUploadId) {
        setShowBanner(true);
        setTypeOfBanner(BANNER_TYPE_ENUM?.INITIATED);
        startOrderPolling(bulkUploadId);
      }
    }, [bulkUploadId]);

    const setOrderManageActionType = () => {
      bulkUploadEntity?.resetModal();

      const isStoEnabled = appState?.tenant?.settings?.isSTOEnabled === true;
      const isFbaEnabled =
        appState?.tenant?.typeOfCustomer?.includes("Prep Center");
      const filteredOrderActions = [
        {
          name: "Create Sales Order",
          value: "CREATE_SALES_ORDER",
          title: "Create Sales Order",
          subTitle: "",
          visible: createSalesOrderEnabled,
        },
        {
          name: "Create Regular Order",
          value: "CREATE_REGULAR_ORDER",
          title: "Create Regular Order",
          subTitle: "",
          visible: true,
        },
        {
          name: "Create Stock Transfer Order",
          value: "CREATE_STOCK_TRANSFER_ORDER",
          title: "Create Stock Transfer Order",
          subTitle: "",
          visible: isStoEnabled,
        },
        {
          name: "Export Report",
          value: "EXPORT_REPORT",
          title: "Export Report",
          subTitle: "",
          visible: true,
        },
        {
          name: "Import FBA Shipment",
          value: "IMPORT_FBA_SHIPMENT",
          title: "Import FBA Shipment",
          subTitle: "",
          visible: isFbaEnabled,
        },
      ].filter((i) => i.visible);
      const filteredOrderActionsEnum = ORDER_ACTIONS_TYPE_ENUM;
      setOrderActionTypes([...filteredOrderActions, ...ACTION_TYPES]);
      setOrderActionTypesEnum({
        ...filteredOrderActionsEnum,
        ...ACTIONS_TYPE_ENUM,
      });
    };

    useEffect(() => {
      setOrderManageActionType();
      getOrderDashboardFieldsV2();
    }, []);

    const getOrderDashboardFieldsV2 = async () => {
      try {
        const getOrderDashboardFieldsV2Response =
          await getOrdersFieldsV2.fetchData();
        if (getOrderDashboardFieldsV2Response.error) {
          setOrderDashboardFieldsV2(null);
        } else if (getOrderDashboardFieldsV2Response?.data) {
          setOrderDashboardFieldsV2(
            getOrderDashboardFieldsV2Response?.data?.orderFieldsV2,
          );
        }
      } catch (error) {
        setOrderDashboardFieldsV2(null);
      }
    };

    const buildBol = async (orderId) => {
      appState.setLoading();
      const response = await getOrderForBolBuild.fetchData({ orderId });
      if (response.data) {
        setBolBuildData({
          ...response.data.order,
          bolData: {
            ...(response.data.order.bolData || {}),
            orderLineItems:
              response.data.order.bolData?.orderLineItems ||
              response.data.order.orderLineItems,
            handlingUnits:
              response.data.order.bolData?.handlingUnits ||
              response.data.order.orderLineItems
                .filter((item) => item.formFactor === "Pallet")
                .map((item) => ({
                  type: "Pallet",
                  isPredefined: true,
                  items: [{ ...item, allocatedQuantity: item.quantity }],
                  weight: item.weight || "",
                })),
          },
        });
      }
      appState.removeLoading();
      if (response.error) {
        setBolBuildData(null);
        appState.setAlert(
          response.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }
    };

    return (
      <WrappedComponent
        {...props}
        selectedSkus={selectedSkus}
        setSelectedSkus={setSelectedSkus}
        bulkOrdersToMarkComplete={bulkOrdersToMarkComplete}
        setBulkOrdersToMarkComplete={setBulkOrdersToMarkComplete}
        bulkOrdersToMarkCancelled={bulkOrdersToMarkCancelled}
        setBulkOrdersToMarkCancelled={setBulkOrdersToMarkCancelled}
        orders={entity.entities}
        mostRecentOrderId={mostRecentOrderId}
        setMostRecentOrderId={setMostRecentOrderId}
        newOrders={newOrders}
        newOrdersAvailable={newOrdersAvailable}
        setNewOrdersAvailable={setNewOrdersAvailable}
        displayOrders={displayOrders()}
        total={entity.total}
        masterData={masterDataQuery.data ? masterDataQuery.data.masterData : {}}
        loading={loading}
        selectedAudit={selectedAudit}
        setSelectedAudit={setSelectedAudit}
        selectedAuditUsersInfo={selectedAuditUsersInfo}
        setSelectedAuditUsersInfo={setSelectedAuditUsersInfo}
        showAudit={showAudit}
        setShowAudit={setShowAudit}
        auditOrderId={auditOrderId}
        setAuditOrderId={setAuditOrderId}
        dashboardFields={dashboardFields}
        saveBulkUpload={(rows) => {
          const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
          uploadBulk.fetchData({ rows, timezone: userTimezone });
        }}
        errorMessage={finalError}
        successMessage={successMessage}
        getAudit={getAudit}
        calculateShippingRates={() => {
          appState.setLoading();
          setTimeout(() => {
            appState.setAlert(
              "Rates have been calculated",
              "success",
              ALERT_VISIBILITY_IN_MS,
            );
            appState.removeLoading();
          }, 1000);
        }}
        selectOrder={selectOrder}
        selectedOrders={selectedOrders}
        clearSelectedOrders={clearSelectedOrders}
        runCombinedOrders={() => runCombinedOrders.fetchData()}
        simulatedBatches={simulatedBatches}
        simulateAutoBatching={() => simulateAutoBatchesQuery.fetchData()}
        clearSimulatedBatches={() => setSimulatedBatches([])}
        rates={rates}
        setSelectedRates={setSelectedRates}
        pageNumber={entity.pageNumber}
        checkPagination={checkPagination}
        perPage={entity.perPage}
        combinedOrders={combinedOrders}
        setCombinedOrders={setCombinedOrders}
        expandOrder={async (order) => {
          if (order.childOrders?.length > 0) {
            getBackOrderDetailsQuery.fetchData({ id: order.id });
          } else if (order.splitOrders?.length > 0) {
            appState.setLoading();
            const splitOrdersResponse =
              await getSplitOrderDetailsQuery.fetchData({ id: order.id });
            appState.removeLoading();
            if (splitOrdersResponse.data) {
              setExpandedOrderInfo({
                ...splitOrdersResponse.data.getSplitOrderDetails,
                isSplitOrder: true,
              });
              if (
                [
                  OrderSource.FBA,
                  OrderSource.SHOPIFY,
                  OrderSource.SALLA,
                  OrderSource.NETSUITE,
                  OrderSource.ZID,
                  OrderSource.APPARELMAGIC,
                  OrderSource.SHIP_COMPLIANT,
                  OrderSource.SAP_B1_TYM,
                  OrderSource.E_BAY,
                  OrderSource.SALESFORCE,
                  OrderSource.VAULT_JMI,
                ].includes(
                  splitOrdersResponse.data.getSplitOrderDetails.order?.source,
                ) ||
                order?.source.includes(OrderSource.COMMERCE_HUB)
              ) {
                setMarkCompleteOnMarketplaceCheck(true);
              }
              if (
                ["FBA"].includes(
                  splitOrdersResponse.data.getSplitOrderDetails.order?.source,
                )
              ) {
                setMarkCancelledOnMarketplaceCheck(true);
              }
            }

            if (splitOrdersResponse.error) {
              setExpandedOrderInfo(null);
            }
          } else {
            fetchSpecificOrder.fetchData({ orderId: order.id });
          }
        }}
        expandedOrderInfo={expandedOrderInfo}
        setExpandedOrderInfo={setExpandedOrderInfo}
        selectedCombinedOrder={selectedCombinedOrder}
        setShowCombinedOrders={setShowCombinedOrders}
        onChangeCombinedDropdown={(field, value) =>
          setShowCombinedOrders({
            ...selectedCombinedOrder,
            combinedCriteria: value,
          })
        }
        setPerPage={(perPage) => {
          entity.setPerPage({ perPage });
          ordersQuery.fetchData({
            perPage,
            pageNumber: 1,
            filters: { ...entity.filters },
            sort: entity.sort,
          });
        }}
        submitFilters={() => {
          appState.setLoading();
          setShowFilters(false);

          const filters = {
            ...entity.filters,
            completionStartEndDate: entity.filters.completionStartEndDate
              ? entity.filters.completionStartEndDate.map((date) =>
                  dayjs(date, "DD-MM-YYYY").format("YYYY-MM-DD"),
                )
              : null,
          };

          ordersQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters,
            sort: entity.sort,
          });
        }}
        clearKeyword={() => {
          entity.setFilters({
            ...entity.filters,
            keyword: null,
          });
          ordersQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: { ...entity.filters, keyword: null },
            sort: entity.sort,
          });
        }}
        filters={entity.filters}
        onChangeFilter={(field, value, autoSubmit = false) => {
          const copiedFilters = { ...entity.filters };
          if (value === null || value === undefined) {
            delete copiedFilters[field];
            entity.setFilters({
              ...copiedFilters,
            });
          } else {
            if (field === "startEndDate" && value.length > 0) {
              // As orderDate filter needs to be converted to ISO string
              const gmtTimezone = "GMT";
              value = value.map((date, index) => {
                const momentDate = moment.tz(date, "DD-MM-YYYY", gmtTimezone);
                return index === 0
                  ? momentDate.startOf("day").toISOString()
                  : momentDate.endOf("day").toISOString();
              });
            }
            copiedFilters[field] = value;

            if (
              (field === "startEndDate" ||
                field === "completionStartEndDate") &&
              (value.length === 0 || !value[0])
            ) {
              delete copiedFilters[field];
            }

            entity.setFilters({
              ...copiedFilters,
            });
          }

          if (autoSubmit) {
            ordersQuery.fetchData({
              perPage: entity.perPage,
              pageNumber: 1,
              filters: {
                ...copiedFilters,
              },
              sort: entity.sort,
            });
          }
        }}
        onChangeSearchKeyword={(e) =>
          entity.setFilters({
            ...entity.filters,
            keyword: e.target.value,
          })
        }
        sort={entity.sort}
        setSort={(key) => {
          const sort = entity.sort === key ? `-${key}` : key;
          entity.setSort({ sort });
          ordersQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: {
              ...entity.filters,
            },
            sort,
          });
        }}
        showFilters={showFilters}
        setShowFilters={setShowFilters}
        clearFilters={loadFirstTimeData}
        runAutoBatching={createAutoBatches}
        runAutoBatches={createAutoBatches}
        createManualBatch={createManualBatch}
        markOrdersCompleted={markOrdersCompleted}
        markOrdersCancelled={markOrdersCancelled}
        createManualBatchFinal={createManualBatchFinal}
        showManualBatchCreationDialog={showManualBatchCreationDialog}
        printShippingLabel={(orderId) => {
          fetchOrderForPrintingShippingLabel.fetchData({ orderId });
        }}
        downloadShippingLabel={(orderId) => {
          fetchOrderForDownloadingShippingLabel.fetchData({ orderId });
        }}
        viewInvoiceLabels={(invoiceLabelUrls) => {
          viewInvoiceLabels(invoiceLabelUrls);
        }}
        printBoxLabels={(orderId) => {
          fetchOrderForPrintingBoxLabels.fetchData({ orderId });
        }}
        downloadBoxLabels={(orderId) =>
          fetchOrderForDownloadingBoxLabels.fetchData({ orderId })
        }
        printPalletLabels={(orderId) => {
          fetchOrderForPrintingPalletLabels.fetchData({ orderId });
        }}
        downloadPalletLabels={(orderId) =>
          fetchOrderForDownloadingPalletLabels.fetchData({ orderId })
        }
        selectedPicker={selectedPicker}
        setSelectedPicker={setSelectedPicker}
        newlyAddedOrders={newlyAddedOrders}
        pickers={pickers}
        markInProcess={markInProcess}
        markUnprocessed={markUnprocessed}
        markCompleted={markCompleted}
        bulkMarkCompleted={bulkMarkCompleted}
        bulkMarkCancelled={bulkMarkCancelled}
        markPartialCompleted={markPartialCompleted}
        markCancelled={markCancelled}
        batchDateRange={batchDateRange}
        setBatchDateRange={setBatchDateRange}
        resetAllData={resetAllData}
        viewShipmentPlan={viewShipmentPlan}
        setShipmentPlan={updateShipmentPlan}
        shipmentPlan={shipmentPlan}
        warehouses={auth.user?.warehousesList ? auth.user.warehousesList : []}
        customers={auth.user?.customersList ? auth.user.customersList : []}
        addNewOrder={addNewOrder}
        selectedOrder={selectedOrder}
        setSelectedOrder={setSelectedOrder}
        selectedOrderLineItem={selectedOrderLineItem}
        setSelectedOrderLineItem={setSelectedOrderLineItem}
        onChangeSelectedOrderLineItem={onChangeSelectedOrderLineItem}
        updateOrderLineItem={updateOrderLineItem}
        addItem={addItem}
        removeItem={removeItem}
        onChangeItem={onChangeItem}
        onChange={onChange}
        onChangeDropdown={onChangeDropdown}
        availableInventory={availableInventory}
        handleOrderDataSubmission={handleOrderDataSubmission}
        onChangeInventorySearch={debouncedInventorySearch}
        deliveryInfo={deliveryInfo}
        setDeliveryInfo={setDeliveryInfo}
        saveDeliveryInfo={saveDeliveryInfo}
        removeDelivery={removeDelivery}
        updateTracking={updateTracking}
        updateCarrier={updateCarrier}
        trackingUpdate={trackingUpdate}
        carrierUpdate={carrierUpdate}
        setTrackingUpdate={setTrackingUpdate}
        setCarrierUpdate={setCarrierUpdate}
        submitTrackingUpdate={submitTrackingUpdate}
        submitCarrierUpdate={submitCarrierUpdate}
        viewShippingAddress={(order) => {
          if (order) {
            setShippingAddress({
              ...order,
            });
          }
        }}
        shippingAddress={shippingAddress}
        setShippingAddress={setShippingAddress}
        getMarketplaceProducts={() =>
          getMarketplaceProductsQuery.fetchData({
            customer: selectedOrder.customer,
          })
        }
        tenant={appState.tenant}
        buildBol={buildBol}
        downloadBol={(orderId) => getOrderForBolDownload.fetchData({ orderId })}
        printBol={(orderId) => getOrderForBolPrint.fetchData({ orderId })}
        bolDoc={bolDoc}
        setBolDoc={setBolDoc}
        carrierList={
          integrationsQuery?.data?.integrations &&
          integrationsQuery.data.integrations[0] &&
          integrationsQuery.data.integrations[0].carrierAccounts
            ? integrationsQuery.data.integrations[0].carrierAccounts.map(
                (item) => ({
                  name: item.readable,
                  id: item.id,
                }),
              )
            : [
                { name: "UPS", id: "UPS" },
                { name: "USPS", id: "USPS" },
                { name: "FedEx", id: "FedEx" },
                { name: "DHL", id: "DHL" },
              ]
        }
        shipstationList={
          integrationsQuery?.data?.integrations &&
          integrationsQuery.data.integrations[0] &&
          integrationsQuery.data.integrations[0].integrationType ===
            "Shipstation"
            ? integrationsQuery.data.integrations[0].carriers
            : []
        }
        eHubList={
          integrationsQuery?.data?.integrations?.[0]?.integrationType === "eHub"
            ? integrationsQuery.data.integrations[0].carriers
            : []
        }
        carrierServiceList={
          integrationsQuery?.data?.integrations &&
          integrationsQuery.data.integrations[0] &&
          integrationsQuery.data.integrations[0].carriers
            ? integrationsQuery.data.integrations[0].carriers
            : {}
        }
        uploadFile={uploadFile}
        deleteOrder={deleteOrder}
        fileIndex={fileIndex}
        setFileIndex={setFileIndex}
        downloadOrderReport={downloadOrderReport}
        cancelOrders={cancelOrders}
        selectAllRows={selectAllRows}
        allRowsSelected={allRowsSelected}
        validate={(rows) => {
          bulkUploadValidation.fetchData({ rows });
        }}
        validationResult={validationResult}
        printFnSkuLabels={(orderId) =>
          getFnSkuLabelsQuery.fetchData({ orderId, print: true })
        }
        downloadFnSkuLabels={(orderId) =>
          getFnSkuLabelsQuery.fetchData({ orderId, print: false })
        }
        fnSkuOptions={fnSkuOptions}
        setFnSkuOptions={setFnSkuOptions}
        submitFnSkuOptions={submitFnSkuOptions}
        handleAssignWarehouse={handleAssignWarehouse}
        assignWarehouse={submitAssignWarehouse}
        selectedWarehouse={selectedWarehouse}
        setSelectedWarehouse={setSelectedWarehouse}
        cannotChangeOrderWarehouse={cannotChangeOrderWarehouse}
        availableWarehousesForChange={availableWarehousesForChange}
        editOrder={editOrder}
        nonEditableFieldsOfOrder={nonEditableFieldsOfOrder}
        isOrderBeingCreated={isOrderBeingCreated}
        isOrderBeingEdited={isOrderBeingEdited}
        setOrderBeingCreated={setOrderBeingCreated}
        setOrderBeingEdited={setOrderBeingEdited}
        setOrderToMarkComplete={setOrderToMarkComplete}
        orderToMarkComplete={orderToMarkComplete}
        confirmMarkCompleted={confirmMarkCompleted}
        markCompleteOnMarketplaceCheck={markCompleteOnMarketplaceCheck}
        setMarkCompleteOnMarketplaceCheck={setMarkCompleteOnMarketplaceCheck}
        markShippedOnMarketplace={markShippedOnMarketplace}
        orderToMarkShipped={orderToMarkShipped}
        setOrderToMarkShipped={setOrderToMarkShipped}
        shipmentImportSearch={shipmentImportSearch}
        setShipmentImportSearch={setShipmentImportSearch}
        onImportShipmentSearch={onImportShipmentSearch}
        importShipment={importShipment}
        setImportShipment={setImportShipment}
        confirmImportShipment={confirmImportShipment}
        importFbaShipmentModal={importFbaShipmentModal}
        setImportFbaShipmentModal={setImportFbaShipmentModal}
        onChangeDropdownImport={onChangeDropdownImport}
        importFilters={importFilters}
        setImportFilters={setImportFilters}
        orderToMarkCancelled={orderToMarkCancelled}
        setOrderToMarkCancelled={setOrderToMarkCancelled}
        setMarkCancelledOnMarketplaceCheck={setMarkCancelledOnMarketplaceCheck}
        markCancelledOnMarketplaceCheck={markCancelledOnMarketplaceCheck}
        confirmMarkCancelled={confirmMarkCancelled}
        possibleSubStatuses={possibleSubStatuses(appState.tenant)}
        showOnlySelected={showOnlySelected}
        setShowOnlySelected={setShowOnlySelected}
        showBatchSettings={showBatchSettings}
        setShowBatchSettings={setShowBatchSettings}
        batchSettingsEnabled={batchSettingsEnabled}
        batchSettings={batchSettings}
        setBatchSettings={setBatchSettings}
        saveBatchSettings={() =>
          saveBatchSettingsQuery.fetchData({
            batchSettingsInput: { ...batchSettings },
          })
        }
        fetchProductSkuBinMappings={fetchProductSkuBinMappings}
        productSkuBinMappings={productSkuBinMappings}
        productSkuBinMappingsWithLpn={productSkuBinMappingsWithLpn}
        productSkuBinMappingsWithSerialNumber={
          productSkuBinMappingsWithSerialNumber
        }
        productSkuBinMappingsWithPoId={productSkuBinMappingsWithPoId}
        productSkuBinMappingsWithPalletId={productSkuBinMappingsWithPalletId}
        lotIdSelectionEnabled={lotIdSelectionEnabled}
        expiryDateSelectionEnabled={true}
        productSkuBinMappingsWithExpiryDate={
          productSkuBinMappingsWithExpiryDate
        }
        lpnSelectionEnabled={lpnSelectionEnabled}
        stations={stations}
        manualBatchConfig={manualBatchConfig}
        setManualBatchConfig={setManualBatchConfig}
        multiAccountSupportEnabled={multiAccountSupportEnabled}
        marketplaces={marketplaces}
        sellerIds={sellerIds}
        bolUploadEnabled={bolUploadEnabled}
        updateCarrierInfoQuery={updateCarrierInfoQuery}
        dataTableFilters={dataTableFilters}
        setDataTableFilters={setDataTableFilters}
        printPackingLabels={printPackingLabels}
        downloadPackingLabel={downloadPackingLabel}
        packingLabelsEnabled={fbaSkuLabelGeneration}
        csvPackingListEnabled={csvPackingListDownloadEnabled}
        fetchEnhancedSearchProductResults={fetchEnhancedSearchProductResults}
        orderChangesPreview={orderChangesPreview}
        setOrderChangesPreview={setOrderChangesPreview}
        downloadPackingList={downloadPackingList}
        printOrder={printOrder}
        isBackOrderEnabledForTenant={isBackOrderEnabledForTenant}
        showBatchingPreview={showBatchingPreview}
        ordersFulfillmentDetails={ordersFulfillmentDetails}
        setOrdersFulfillmentDetails={setOrdersFulfillmentDetails}
        groupedOrders={ordersQuery?.data?.orders?.groupedOrders}
        selectedOrderNotes={selectedOrderNotes}
        setSelectedOrderNotes={setSelectedOrderNotes}
        isManualBatchExecuting={isManualBatchExecuting}
        selectedSalesOrder={selectedSalesOrder}
        setSelectedSalesOrder={setSelectedSalesOrder}
        addNewSalesOrder={addNewSalesOrder}
        onChangeSalesDropdown={onChangeSalesDropdown}
        cart={cart}
        setCart={setCart}
        handleSalesOrderSubmission={handleSalesOrderSubmission}
        createCRMCustomer={createCRMCustomer}
        newCustomer={newCustomer}
        setNewCustomer={setNewCustomer}
        crmCustomers={crmCustomers}
        handleAddToCart={handleAddToCart}
        getAvalaraTaxInvoice={getAvalaraTaxInvoice}
        avalaraTaxInvoice={avalaraTaxInvoice}
        salesOrderMode={salesOrderMode}
        setSalesOrderMode={setSalesOrderMode}
        salesOrderModesEnum={SALES_ORDER_MODES}
        addCustomerPanel={addCustomerPanel}
        setAddCustomerPanel={setAddCustomerPanel}
        salesOrderIds={salesOrderIds}
        setSalesOrderIds={setSalesOrderIds}
        bulkUploadV2Enabled={bulkUploadV2Enabled}
        actionTypes={orderActionTypes}
        showBulkUpload={showBulkUpload}
        setShowBulkUpload={setShowBulkUpload}
        setSelectedActionType={setSelectedActionType}
        selectedActionType={selectedActionType}
        orderActionTypesEnum={orderActionTypesEnum}
        bulkuploadFileName={selectedBulkUploadFile}
        pastUploadsList={bulkUploadEntity?.pastUploadsEntities}
        showBanner={showBanner}
        typeOfBanner={typeOfBanner}
        closeBanners={() => {
          localStorage.removeItem(BULK_UPLOAD_KEYS?.ORDER_ID);
          localStorage.removeItem(BULK_UPLOAD_KEYS?.ORDER_FILE);
          setShowBanner(false);
        }}
        orderDashboardFieldsV2={orderDashboardFieldsV2}
        searchFilters={searchFilters}
        fetchEnhancedSearchResults={fetchEnhancedSearchResults}
        searchOrdersLoading={searchOrdersQuery.loading}
        searchResults={searchResults}
        setSearchResults={setSearchResults}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        showBatchingPrioritisation={showBatchingPrioritisation}
        setShowBatchingPrioritisation={setShowBatchingPrioritisation}
        setSelectedOrders={setSelectedOrders}
        refetchOrderDetails={refetchOrderDetails}
        setRefetchOrderDetails={setRefetchOrderDetails}
        updatedOrderBatchingDetails={updatedOrderBatchingDetails}
        setUpdatedOrderBatchingDetails={setUpdatedOrderBatchingDetails}
        enhancedSearchText={enhancedSearchText}
        setEnhancedSearchText={setEnhancedSearchText}
        showEnhancedSearch={showEnhancedSearch}
        setShowEnhancedSearch={setShowEnhancedSearch}
        bolBuildData={bolBuildData}
        setBolBuildData={setBolBuildData}
        submitBolData={submitBolData}
      />
    );
  };
};

const editOrderFields = new Set([
  "id",
  "orderStatus",
  "carrier",
  "orderDate",
  "shippingAddress",
  "reference",
  "orderId",
  "orderValue",
  "orderLineItems",
  "subStatus",
  "shippingLabel",
  "tote",
  "trackingNumber",
  "cannotFulfil",
  "formFactor",
  "trackingUrl",
  "source",
  "orderType",
  "email",
  "shippingLabels",
  "trackingNumbers",
  "boxInformationNeeded",
  "fbaAudit",
  "typeOfShipment",
  "confirmedTypeOfShipment",
  "boxLabels",
  "transportFailed",
  "hazmatConfirmed",
  "dropship",
  "consignmentId",
  "verifiedLabelUpload",
  "shippingRequests",
  "carrierService",
  "attributes",
  "insuranceRequired",
  "insuranceProvider",
  "insuredValue",
  "notes",
  "completedDate",
  "bolNumber",
  "bolSealNumber",
  "scacCode",
  "dateOfShipping",
  "toValidAddress",
  "warehouse",
  "customer",
  "preSelectedCarrierRate",
  "estimatedBoxes",
  "toWarehouse",
  "inboundPlanId",
]);

export default withOrdersLogic;

// TODO: This should not be hard-coded on the frontend. Needs to be retrieved from the backend.
export const possibleSubStatuses = (tenant) => {
  const subStatuses = [
    "UNPROCESSED",
    "BATCHING_FAILED",
    "PICKING_STARTED",
    "PICKING_COMPLETED",
  ];

  if (tenant?.typeOfCustomer?.includes("Prep Center")) {
    subStatuses.push("PREPPING_STARTED", "PREPPING_COMPLETED");
  }

  subStatuses.push("PACKING_STARTED", "PACKING_COMPLETED");

  return subStatuses.map((i) => ({ id: i, name: i }));
};

function isValidURL(string) {
  try {
    new URL(string);
  } catch (error) {
    return false;
  }
  return true;
}
