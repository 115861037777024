import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import HeaderWithArrow from "#newUiComponents/commons/HeaderWithArrow";
import PrimaryButton from "#newUiComponents/commons/PrimaryButton";
import { PlusIcon } from "@heroicons/react/solid";
import {
  dummyData,
  fbaInboundOptions,
  handleFilterMarketPlaces,
  handleGetAmazonIntegrations,
  handleGetStatusConfig,
  inboundFormInitState,
  LIST_INBOUND_PLANS_TIME_IN_MS,
  planStatusesEnums,
} from "./FbaUtils";
import {
  fetchIntegrations,
  createFbaInboundPlan,
  listFbaInboundPlans,
  getSingleFbaInboundPlan,
  getAuditTrail,
} from "#redux/FbaInbound/fbaInboundActions";
import InboundForm from "./InboundForm";
import FbaInboundTable from "./FbaInboundTable";
import useDebouncedEffect from "#hooks/useDebouncedEffect";
import { AppStateContext } from "#contexts/appState";
import {
  resetFbaInboundIntegrations,
  resetFbaInboundState,
} from "#redux/FbaInbound/fbaInboundSlice";
import CustomNotification from "#newUiComponents/commons/CustomNotification";
import SlideOverPanel from "#components/common/SlideOverPanel";
import FbaConfirmationProducts from "./FbaConfirmationProducts";
import InboundPlanInfo from "./InboundPlanInfo";
import { useParams, useHistory } from "react-router-dom";
import FbaProductTableReadOnly from "./FbaProductTableReadOnly";
import { AuthContext } from "#contexts/auth";
import { listShipments } from "#redux/FbaPacking/fbaPackingActions";
import { resetFbaPackingState } from "#redux/FbaPacking/fbaPackingSlice";
import CustomAuditTrail from "#newUiComponents/commons/CustomAuditTrail";

const FbaInbound = () => {
  const dispatch = useDispatch();
  const appState = useContext(AppStateContext);
  const auth = useContext(AuthContext);
  const notify = CustomNotification();
  const urlParams = useParams();
  const history = useHistory();

  const { warehouses, customers, integrations, createInboundPlan } =
    useSelector((state) => state.fbaInbound);

  // State for inbound form and inbound plan variables
  const [inboundForm, setInboundForm] = useState({ ...inboundFormInitState });

  const [showCreatePlanPanel, setShowCreatePlanPanel] = useState(false);
  const [newInboundForm, setNewInboundForm] = useState({
    ...inboundFormInitState,
  });

  const [inboundPlansVariables, setInboundPlansVariables] = useState({
    pageNumber: 1,
    perPage: 10,
    sort: "-updatedAt",
    filters: {
      customerIds: null,
      ids: null,
      warehouseIds: null,
      inboundPlanIds: null,
    },
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [listInboundPlansData, setListInboundPlansData] = useState([]);
  const [slideOverOpen, setSlideOverOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState({});
  const [selectedPlanForAuditTrail, setSelectedPlanForAuditTrail] = useState(
    {},
  );
  const [auditTrailStages, setAuditTrailStages] = useState([]);
  const [isSinglePlanView, setIsSinglePlanView] = useState(false);
  const [showAuditTrailSlideOver, setShowAuditTrailSlideOver] = useState(false);
  const [isIntegrationsLoading, setIsIntegrationsLoading] = useState(false);

  // Fetch inbound plans with updated parameters
  const fetchPlans = (pageNumber = 1) => {
    if (pageNumber === 1) {
      setListInboundPlansData([]);
      setCurrentPage(1);
    }
    const variables = {
      listInboundPlansInput: {
        ...inboundPlansVariables,
        pageNumber,
      },
    };
    dispatch(listFbaInboundPlans({ variables }));
  };

  const fetchSinglePlan = (
    inboundPlanId,
    successCallback = () => {},
    errorCallback = () => {},
  ) => {
    dispatch(
      getSingleFbaInboundPlan({
        variables: {
          listInboundPlansInput: {
            pageNumber: 1,
            perPage: 1,
            filters: { inboundPlanIds: [inboundPlanId] },
          },
        },
        successCallback,
        errorCallback,
      }),
    );
  };

  const fetchShipmentsList = (
    planData,
    successCallback = () => {},
    errorCallback = () => {},
  ) => {
    const shipmentListVariables = {
      input: {
        customerId: planData?.customer,
        warehouseId: planData?.warehouse,
        inboundReferenceId: planData?.inboundPlanId,
      },
    };
    dispatch(
      listShipments({
        variables: shipmentListVariables,
        successCallback,
        errorCallback,
      }),
    );
  };

  const handleGetAuditTrail = (inboundPlan) => {
    const successCallback = (data) => {
      appState.removeLoading();
      setAuditTrailStages(data?.stages);
      setShowAuditTrailSlideOver(true);
      console.log(data, "thisis data......");
    };

    const errorCallback = (err) => {
      appState.removeLoading();
      notify.error(err);
    };

    appState.setLoading();

    dispatch(
      getAuditTrail({
        variables: {
          id: inboundPlan.id,
        },
        successCallback,
        errorCallback,
      }),
    );
  };
  const handleResetFetchPlans = () => {
    setCurrentPage(1);
    fetchPlans(1);
  };

  // Handle form submission for creating a new inbound plan
  const handleFormSubmit = () => {
    const { marketPlace, client, warehouse, sellerId } = newInboundForm;
    const variables = {
      createInboundPlanInput: {
        marketplace: marketPlace,
        customer: client,
        warehouse,
        sellerId:
          (sellerId ? sellerId : integrations?.data?.[0]?.sellerId) || null,
      },
    };
    const successCallback = (data) => {
      notify.success(`${data?.message} with planId - ${data?.inboundPlanId}`);
      setCurrentPage(1);
      fetchPlans(1); // Refresh data

      // Get the created plan's ID and push to its route
      if (data?.inboundPlanId) {
        history.push(`/fbaInbound/${data.inboundPlanId}`);
      }
    };
    const errorCallback = (error) => {
      notify.error(error);
    };
    dispatch(
      createFbaInboundPlan({ variables, successCallback, errorCallback }),
    );
  };

  const handleHeaderWithArrow = (planStatusAfterActive) => {
    return planStatusAfterActive ? (
      <HeaderWithArrow
        headerTitle={
          <p className="flex items-center gap-2">
            <span className="text-gray-400">Inbound Plan ID</span>
            <span>{selectedPlan?.inboundPlanId}</span>
            <span>{">"}</span>
            <span>Plan Details</span>
          </p>
        }
        description={""}
        isLearnMore={false}
        isArrow
        arrowAction={() => {
          setSlideOverOpen(false);
          history.push("/fbaInbound");
          // handleResetFetchPlans();
        }}
        mainClasses="mb-0"
      />
    ) : (
      <HeaderWithArrow
        headerTitle={
          <p className="flex items-center gap-2">
            {/* <span className="text-gray-400">Inbound Plan ID</span> */}
            <span>{selectedPlan?.inboundPlanId}</span>
            <span>{">"}</span>
            <span>Confirmation of Products and their Quantities</span>
          </p>
        }
        description={
          "This screen allows users to review and verify the selected products along with their quantities before proceeding, ensuring accuracy for shipment planning."
        }
        isLearnMore={false}
        isArrow
        arrowAction={() => {
          setSlideOverOpen(false);
          history.push("/fbaInbound");
          handleResetFetchPlans();
        }}
        mainClasses="mb-0"
      />
    );
  };

  const planStatusAfterActive =
    selectedPlan?.status === planStatusesEnums.ACTIVE.key ||
    selectedPlan?.status === planStatusesEnums.VOIDED.key ||
    selectedPlan?.status === planStatusesEnums.SHIPPED.key;

  // Set the selected plan from list based on URL params
  const handleGetSelectedPlan = (planId) => {
    appState.setLoading();
    fetchSinglePlan(
      planId,
      (data) => {
        appState.removeLoading();
        if (data && data.inboundPlanId) {
          setSelectedPlan(data);
          fetchShipmentsList(data);
        } else {
          notify.error("Couldn't fetch the plan details");
          history.push("/fbaInbound");
        }
      },
      (error) => {
        appState.removeLoading();
        notify.error("Failed to fetch the plan details", error);
        history.push("/fbaInbound");
      },
    );
    // setSelectedPlan(plan);
  };

  // Fetch warehouses and customers initially
  useEffect(() => {
    return () => {
      dispatch(resetFbaInboundState());
      dispatch(resetFbaPackingState());
    };
  }, []);

  // Update filters in inboundPlansVariables when client or warehouse changes
  useEffect(() => {
    setCurrentPage(1);
    setInboundPlansVariables((prev) => ({
      ...prev,
      filters: {
        ...prev.filters,
        customerIds: inboundForm.client
          ? [inboundForm.client]
          : auth.user && auth.user.customersList
            ? auth.user.customersList.map((customer) => customer.id)
            : null,
        warehouseIds: inboundForm.warehouse
          ? [inboundForm.warehouse]
          : auth.user && auth.user.warehousesList
            ? auth.user.warehousesList.map((warehouse) => warehouse.id)
            : null,
      },
    }));
  }, [inboundForm.client, inboundForm.warehouse, auth.user]);

  // Fetch additional pages of inbound plans when page number changes
  useEffect(() => {
    appState.setAdminPageBgColor("bg-white");
    if (currentPage > 1) {
      fetchPlans(currentPage);
    }
  }, [currentPage]);

  // Fetch inbound plans when filters (excluding pageNumber) change
  useDebouncedEffect(
    () => {
      if (!isSinglePlanView) {
        fetchPlans(1);
      }
    },
    LIST_INBOUND_PLANS_TIME_IN_MS,
    [
      inboundPlansVariables.filters.customerIds,
      inboundPlansVariables.filters.warehouseIds,
      inboundPlansVariables.sort,
      inboundPlansVariables.filters.inboundPlanIds,
      isSinglePlanView,
    ],
  );
  // Reset integrations and fetch new ones when client or warehouse changes
  useDebouncedEffect(
    () => {
      dispatch(resetFbaInboundIntegrations());
      setNewInboundForm((form) => ({ ...form, marketPlace: "", sellerId: "" }));

      if (newInboundForm.warehouse && newInboundForm.client) {
        const variables = {
          filters: {
            customer: newInboundForm.client,
            warehouse: newInboundForm.warehouse,
          },
        };
        setIsIntegrationsLoading(true);
        dispatch(
          fetchIntegrations({
            variables,
            successCallback: () => {
              setIsIntegrationsLoading(false);
            },
            errorCallback: (error) => {
              notify.error(error);
              setIsIntegrationsLoading(false);
            },
          }),
        );
      }
    },
    "immediate",
    [newInboundForm.client, newInboundForm.warehouse],
  );

  // Manage SlideOverPanel visibility based on URL params
  useEffect(() => {
    if (urlParams.planId) {
      setIsSinglePlanView(true);
      handleGetSelectedPlan(urlParams.planId);
    } else {
      setIsSinglePlanView(false);
      setSlideOverOpen(false);
      setSelectedPlan({});
    }
  }, [urlParams.planId]);

  useEffect(() => {
    if (selectedPlan && Object.keys(selectedPlan).length) {
      setSlideOverOpen(true);
    }
  }, [selectedPlan]);

  useEffect(() => {
    if (!isSinglePlanView) {
      const inboundPlanIds = searchTerm ? [searchTerm] : null;
      setInboundPlansVariables((prev) => ({
        ...prev,
        filters: {
          ...prev.filters,
          inboundPlanIds: inboundPlanIds,
        },
      }));
    }
  }, [searchTerm, isSinglePlanView]);

  return (
    <>
      <div className="p-8 font-inter">
        <div className="mb-4 flex justify-between">
          <HeaderWithArrow
            headerTitle="Inbound FBA Plans"
            description="View and manage your FBA inbound plans. Track past and ongoing shipments, check product eligibility, and initiate new inbound plans for seamless inventory management."
            isLearnMore={false}
            isArrow={false}
          />
          <div className="flex items-start justify-end gap-6">
            <PrimaryButton
              height="2.8rem"
              minWidth="7rem"
              maxWidth="15rem"
              variant="primary"
              className="ml-6 mt-2 text-sm font-medium"
              onClick={() => {
                setShowCreatePlanPanel(true);
              }}
              loading={createInboundPlan.isPending}
              disabled={false}>
              <PlusIcon className="mr-1 h-4 w-4 stroke-2 text-white" />
              Create an Inbound Plan
            </PrimaryButton>
          </div>
        </div>

        <div className="mb-4">
          <InboundForm
            inboundForm={inboundForm}
            setInboundForm={setInboundForm}
            fbaIntegrations={handleGetAmazonIntegrations(integrations?.data)}
            isIntegrationsLoading={integrations?.isPending}
            shouldSellerIdFieldVisible={
              appState?.tenant?.settings
                ?.multiAccountIntegrationSupportEnabled === true
            }
            isFilterOnly={true}
            formData={fbaInboundOptions(
              auth.user?.warehousesList ? auth.user.warehousesList : [],
              auth.user?.customersList ? auth.user.customersList : [],
              // handleFilterMarketPlaces(
              //   inboundForm.sellerId,
              //   handleGetAmazonIntegrations(integrations?.data),
              // )?.map((marketPlace) => ({
              //   name: marketPlace,
              //   id: marketPlace,
              // })) || [],
              // handleGetAmazonIntegrations(integrations?.data)?.map(
              //   (integration) => ({
              //     id: integration?.sellerId,
              //     name: integration?.sellerId,
              //   }),
              // ),
              // dummyData.shippingModeOptions,
            )}
          />
        </div>

        <div className="h-70v w-full">
          <FbaInboundTable
            listInboundPlansData={listInboundPlansData}
            setListInboundPlansData={setListInboundPlansData}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageSize={inboundPlansVariables?.perPage}
            fetchPlans={fetchPlans}
            setSlideOverOpen={setSlideOverOpen}
            setSelectedPlan={setSelectedPlan}
            customers={auth.user?.customersList ? auth.user.customersList : []}
            warehouses={
              auth.user?.warehousesList ? auth.user.warehousesList : []
            }
            handleGetAuditTrail={handleGetAuditTrail}
            setSelectedPlanForAuditTrail={setSelectedPlanForAuditTrail}
          />
        </div>
      </div>
      <SlideOverPanel
        open={showCreatePlanPanel}
        setOpen={setShowCreatePlanPanel}
        isCrossIconVisible={false}
        title={
          <HeaderWithArrow
            headerTitle="Create Inbound Plan"
            description="Create a new inbound plan by selecting the required details"
            isArrow={true}
            arrowAction={() => setShowCreatePlanPanel(false)}
          />
        }
        containerStyle="max-w-2xl">
        <div className="flex h-full flex-col font-inter">
          <div className="flex-1 p-6">
            <InboundForm
              inboundForm={newInboundForm}
              setInboundForm={setNewInboundForm}
              isFilterOnly={false}
              formData={fbaInboundOptions(
                auth.user?.warehousesList ? auth.user.warehousesList : [],
                auth.user?.customersList ? auth.user.customersList : [],
                handleFilterMarketPlaces(
                  inboundForm.sellerId,
                  handleGetAmazonIntegrations(integrations?.data),
                )?.map((marketPlace) => ({
                  name: marketPlace,
                  id: marketPlace,
                })) || [],
                handleGetAmazonIntegrations(integrations?.data)?.map(
                  (integration) => ({
                    id: integration?.sellerId,
                    name: integration?.sellerId,
                  }),
                ),
                dummyData.shippingModeOptions,
              )}
              fbaIntegrations={handleGetAmazonIntegrations(integrations?.data)}
              shouldSellerIdFieldVisible={
                appState?.tenant?.settings
                  ?.multiAccountIntegrationSupportEnabled === true
              }
              isIntegrationsLoading={isIntegrationsLoading}
            />
          </div>
          <div className="sticky bottom-0 flex justify-end gap-4 border-t bg-white p-4">
            <PrimaryButton
              height="3rem"
              minWidth="9rem"
              // maxWidth="15rem"
              variant="secondary"
              className="text-base font-medium"
              onClick={() => setShowCreatePlanPanel(false)}>
              Cancel
            </PrimaryButton>
            <PrimaryButton
              height="3rem"
              minWidth="9rem"
              // maxWidth="15rem"
              variant="primary"
              className="border-none text-base font-medium"
              disabled={
                !newInboundForm.marketPlace ||
                !newInboundForm.warehouse ||
                !newInboundForm.client ||
                !newInboundForm.sellerId
              }
              loading={createInboundPlan.isPending}
              onClick={() => {
                handleFormSubmit();
                setShowCreatePlanPanel(false);
              }}>
              Create Plan
            </PrimaryButton>
          </div>
        </div>
      </SlideOverPanel>
      <SlideOverPanel
        open={showAuditTrailSlideOver}
        isCrossIconVisible={false}
        title={
          <HeaderWithArrow
            headerTitle={
              <p className="flex items-center gap-2">
                <span className="text-gray-400">Inbound Plan ID</span>
                <span>{selectedPlanForAuditTrail?.inboundPlanId}</span>
                <span>{">"}</span>
                <span>Audit Trail</span>
              </p>
            }
            description={""}
            isLearnMore={false}
            isArrow
            arrowAction={() => {
              setShowAuditTrailSlideOver(false);
              setAuditTrailStages([]);
              setSelectedPlanForAuditTrail({});
            }}
            mainClasses="mb-0"
          />
        }
        setOpen={(value) => {
          setShowAuditTrailSlideOver(value);
          if (!value) {
            setAuditTrailStages([]);
            setSelectedPlanForAuditTrail({});
          }
        }}
        containerStyle={"max-w-4xl"}>
        <div className="px-8">
          <CustomAuditTrail
            title={""}
            stages={auditTrailStages}
            statusConfig={handleGetStatusConfig()}
            showLatestFirst
          />
        </div>
      </SlideOverPanel>

      <SlideOverPanel
        open={slideOverOpen}
        isCrossIconVisible={false}
        title={
          <div className="flex justify-between p-2">
            {handleHeaderWithArrow(planStatusAfterActive)}
          </div>
        }
        setOpen={(value) => {
          if (!value) {
            history.push("/fbaInbound");
            if (!planStatusAfterActive) handleResetFetchPlans();
          }
          setSlideOverOpen(value);
        }}
        containerStyle={"max-w-6xl"}>
        {planStatusAfterActive ? (
          <div className="px-8">
            <div>
              <InboundPlanInfo
                selectedPlan={selectedPlan}
                warehouses={
                  auth.user?.warehousesList
                    ? auth.user.warehousesList
                    : [] || []
                }
                customers={
                  auth.user?.customersList ? auth.user.customersList : [] || []
                }
                fetchShipmentsList={fetchShipmentsList}
              />
              <FbaProductTableReadOnly
                inventoryData={selectedPlan?.items}
                isSearchable={false}
              />
            </div>
          </div>
        ) : (
          <FbaConfirmationProducts
            selectedPlan={selectedPlan}
            handleResetFetchPlans={handleResetFetchPlans}
            client={
              customers?.data
                ? customers?.data?.find(
                    (customer) => customer.id === selectedPlan?.customer,
                  ) || {}
                : []
            }
          />
        )}
      </SlideOverPanel>
    </>
  );
};

export default FbaInbound;
