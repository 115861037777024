import { Tab } from "@headlessui/react";
import DetailedView from "#components/packer/DetailedView";
import ListView from "#components/packer/ListView";
import CompletedBatch from "#components/packer/CompletedBatch";
import AwaitingPackingLabel from "#components/packer/AwaitingPackingLabel";
import AwaitingPalletLabel from "#components/packer/AwaitingPalletLabel";
import AwaitingPalletInformation from "#components/packer/AwaitingPalletInformation";
import AwaitingHazmatConfirmation from "#components/packer/AwaitingHazmatConfirmation";
import AwaitingTrackingInformation from "#components/packer/AwaitingTrackingInformation";
import PackerRateShopping from "./PackerRateShopping";
import ConfirmBoxContents from "#components/packer/ConfirmBoxContents";
import ConfirmCasePackContents from "#components/packer/ConfirmCasePackContents";
import { useState, useContext } from "react";
import { AppStateContext } from "#contexts/appState";
import FbaShippingHandler from "#components/packer/FbaShippingHandler";

import { useFeatureFlags } from "#contexts/featureFlags";
/**
 * Checks if the rate shopping screen can be shown based on various conditions.
 *
 * @param {Object} currentBatch - The current batch information.
 * @param {Object} order - The order information.
 * @param {Object} appState - The App Context value.
 * @return {boolean} True if the rate shopping screen can be shown, otherwise false.
 */
const canShowRateShoppingScreen = (currentBatch, order, appState) => {
  return (
    currentBatch &&
    currentBatch.status === "AWAITING_LABEL" &&
    order &&
    order.boxInformationNeeded !== false &&
    !order.selectedCarrierServiceId &&
    order.bypassCarrierSelection !== true &&
    appState?.tenant?.features?.rateShopping === true
  );
};

const batchStatuses = {
  AWAITING_LABEL: "AWAITING_LABEL",
  COMPLETED: "COMPLETED",
  STARTED: "STARTED",
};

const PackerBatch = ({
  currentBatch,
  scanBarcode,
  currentItemMismatch,
  setCurrentItemMismatch,
  confirmPackItem,
  confirmDropoff,
  printShippingLabel,
  simulateItemScan,
  weight,
  setAddBox,
  setWeight,
  order,
  workflow,
  changeQuantity,
  subdomain,
  onChangeValue,
  submitOrderLineItem,
  cancelItem,
  boxDetails,
  setBoxDetails,
  transportMode,
  setTransportMode,
  restartActivity,
  confirmTransportInformation,
  transportLoading,
  printBoxLabels,
  submitPalletInformation,
  markHazmatConfirmed,
  masterData,
  submitTrackingInformation,
  markOrderException,
  pauseActivity,
  removeBox,
  unpackItem,
  packItems,
  itemsToPack,
  setItemsToPack,
  confirmBoxes,
  setWeightOfBox,
  duplicateBox,
  handleFindAndPack,
  isAddItemQuantityExceed,
  isSetAddItemQuantityExceed,
  itemsToUnPack,
  setItemsToUnPack,
  handleFindAndUnpackItem,
  fetchSpecificOrder,
  currentProduct,
  groupedCasesBySku,
  setGroupedCasesBySku,
  tenant,
  reprintShippingLabel,
  printPackingLabels,
  packingLabelsEnabled,
  warehouses,
  print2DBarcode,
  downloadBoxLabels,
  print2DBarcodes,
  download2DBarcodes,
  numberOfBoxesToDuplicate,
  setNumberOfBoxesToDuplicate,
  prepEnabled,
  getFbaLabelGenerationStatus,
  fbaLabelGenerationStatus,
  navigateToBoxContentsConfirmation,
  getCurrentBatch,
  confirmPrepItem,
  checkFbaBoxWeightLimits,
}) => {
  const [selectedIndex, setSelectedIndex] = useState("List View");
  const appState = useContext(AppStateContext);

  const { newRateshoppingUx: isNewRateShoppingEnabled } = useFeatureFlags();

  if (
    [batchStatuses.AWAITING_LABEL].includes(currentBatch?.status) &&
    order?.source === "FBA" &&
    currentBatch?.confirmedBoxContents
  ) {
    return (
      <FbaShippingHandler
        order={order}
        currentBatch={currentBatch}
        canShowRateShoppingScreen={canShowRateShoppingScreen(
          currentBatch,
          order,
          appState,
        )}
        restartActivity={restartActivity}
        fetchSpecificOrder={fetchSpecificOrder}
        tenant={tenant}
        navigateToBoxContentsConfirmation={navigateToBoxContentsConfirmation}
        masterData={masterData}
        printBoxLabels={printBoxLabels}
        printShippingLabel={printShippingLabel}
        downloadBoxLabels={downloadBoxLabels}
        getCurrentBatch={getCurrentBatch}
        pauseActivity={pauseActivity}
        confirmDropoff={confirmDropoff}
      />
    );
  }

  const tabs = {
    "Detail View": (
      <DetailedView
        currentBatch={currentBatch}
        currentItemMismatch={currentItemMismatch}
        setCurrentItemMismatch={setCurrentItemMismatch}
        confirmPackItem={confirmPackItem}
        weight={weight}
        setAddBox={setAddBox}
        setWeight={setWeight}
        setWeightOfBox={setWeightOfBox}
        order={order}
        workflow={workflow}
        changeQuantity={changeQuantity}
        subdomain={subdomain}
        onChangeValue={onChangeValue}
        submitOrderLineItem={submitOrderLineItem}
        scanBarcode={scanBarcode}
        cancelItem={cancelItem}
        restartActivity={restartActivity}
        pauseActivity={pauseActivity}
        id={currentBatch?.currentItem?.id}
        removeBox={removeBox}
        unpackItem={unpackItem}
        packItems={packItems}
        itemsToPack={itemsToPack}
        setItemsToPack={setItemsToPack}
        duplicateBox={duplicateBox}
        handleFindAndPack={handleFindAndPack}
        isAddItemQuantityExceed={isAddItemQuantityExceed}
        isSetAddItemQuantityExceed={isSetAddItemQuantityExceed}
        handleFindAndUnpackItem={handleFindAndUnpackItem}
        currentProduct={currentProduct}
        tenant={tenant}
        warehouses={warehouses}
        print2DBarcode={print2DBarcode}
        numberOfBoxesToDuplicate={numberOfBoxesToDuplicate}
        setNumberOfBoxesToDuplicate={setNumberOfBoxesToDuplicate}
        prepEnabled={prepEnabled}
        simulateItemScan={simulateItemScan}
        confirmPrepItem={confirmPrepItem}
        checkFbaBoxWeightLimits={checkFbaBoxWeightLimits}
      />
    ),
    "List View": (
      <ListView
        currentBatch={currentBatch}
        scanBarcode={scanBarcode}
        confirmPackItem={confirmPackItem}
        simulateItemScan={simulateItemScan}
        subdomain={subdomain}
        unpackItem={unpackItem}
        order={order}
        packItems={packItems}
        itemsToPack={itemsToPack}
        setItemsToPack={setItemsToPack}
        itemsToUnPack={itemsToUnPack}
        setItemsToUnPack={setItemsToUnPack}
        setSelectedIndex={setSelectedIndex}
        isSetAddItemQuantityExceed={isSetAddItemQuantityExceed}
        handleFindAndUnpackItem={handleFindAndUnpackItem}
        tenant={tenant}
        reprintShippingLabel={reprintShippingLabel}
        warehouses={warehouses}
      />
    ),
  };
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  if (
    (currentBatch &&
      ["COMPLETED", "AWAITING_LABEL"].includes(currentBatch.status) &&
      order &&
      order.boxInformationNeeded !== false &&
      (order.source === "FBA" ||
        tenant?.settings?.activities?.packing?.boxSelection === true) &&
      !currentBatch.confirmedBoxContents) ||
    (canShowRateShoppingScreen(currentBatch, order, appState) &&
      isNewRateShoppingEnabled)
  ) {
    if (order.isCasePack) {
      return (
        <ConfirmCasePackContents
          currentBatch={currentBatch}
          currentItemMismatch={currentItemMismatch}
          setCurrentItemMismatch={setCurrentItemMismatch}
          confirmPackItem={confirmPackItem}
          weight={weight}
          setAddBox={setAddBox}
          setWeight={setWeight}
          setWeightOfBox={setWeightOfBox}
          order={order}
          workflow={workflow}
          changeQuantity={changeQuantity}
          subdomain={subdomain}
          onChangeValue={onChangeValue}
          submitOrderLineItem={submitOrderLineItem}
          scanBarcode={scanBarcode}
          cancelItem={cancelItem}
          restartActivity={restartActivity}
          pauseActivity={pauseActivity}
          removeBox={removeBox}
          unpackItem={unpackItem}
          packItems={packItems}
          itemsToPack={itemsToPack}
          setItemsToPack={setItemsToPack}
          confirmBoxes={confirmBoxes}
          handleFindAndPack={handleFindAndPack}
          isAddItemQuantityExceed={isAddItemQuantityExceed}
          isSetAddItemQuantityExceed={isSetAddItemQuantityExceed}
          itemsToUnPack={itemsToUnPack}
          setItemsToUnPack={setItemsToUnPack}
          handleFindAndUnpackItem={handleFindAndUnpackItem}
          groupedCasesBySku={groupedCasesBySku}
          setGroupedCasesBySku={setGroupedCasesBySku}
        />
      );
    }

    return (
      <ConfirmBoxContents
        currentBatch={currentBatch}
        currentItemMismatch={currentItemMismatch}
        setCurrentItemMismatch={setCurrentItemMismatch}
        confirmPackItem={confirmPackItem}
        weight={weight}
        setAddBox={setAddBox}
        setWeight={setWeight}
        setWeightOfBox={setWeightOfBox}
        order={order}
        boxDetails={boxDetails}
        setBoxDetails={setBoxDetails}
        transportMode={transportMode}
        setTransportMode={setTransportMode}
        workflow={workflow}
        changeQuantity={changeQuantity}
        subdomain={subdomain}
        onChangeValue={onChangeValue}
        submitOrderLineItem={submitOrderLineItem}
        scanBarcode={scanBarcode}
        cancelItem={cancelItem}
        restartActivity={restartActivity}
        pauseActivity={pauseActivity}
        removeBox={removeBox}
        unpackItem={unpackItem}
        packItems={packItems}
        itemsToPack={itemsToPack}
        setItemsToPack={setItemsToPack}
        confirmBoxes={confirmBoxes}
        handleFindAndPack={handleFindAndPack}
        isAddItemQuantityExceed={isAddItemQuantityExceed}
        isSetAddItemQuantityExceed={isSetAddItemQuantityExceed}
        itemsToUnPack={itemsToUnPack}
        setItemsToUnPack={setItemsToUnPack}
        handleFindAndUnpackItem={handleFindAndUnpackItem}
        tenant={tenant}
        print2DBarcode={print2DBarcode}
        fetchSpecificOrder={fetchSpecificOrder}
        downloadBoxLabels={downloadBoxLabels}
        printPackingLabels={printPackingLabels}
        reprintShippingLabel={reprintShippingLabel}
        isNewRateShoppingEnabled={isNewRateShoppingEnabled}
        checkFbaBoxWeightLimits={checkFbaBoxWeightLimits}
      />
    );
  }

  if (
    currentBatch &&
    currentBatch.status === "AWAITING_LABEL" &&
    order &&
    order.transportFailed === true &&
    order.source === "FBA"
  ) {
    return (
      <AwaitingTrackingInformation
        currentBatch={currentBatch}
        confirmDropoff={confirmDropoff}
        pack={true}
        printShippingLabel={printShippingLabel}
        subdomain={subdomain}
        confirmTransportInformation={confirmTransportInformation}
        getFbaLabelGenerationStatus={getFbaLabelGenerationStatus}
        transportLoading={transportLoading}
        printBoxLabels={printBoxLabels}
        submitPalletInformation={submitPalletInformation}
        markHazmatConfirmed={markHazmatConfirmed}
        masterData={masterData}
        submitTrackingInformation={submitTrackingInformation}
        restartActivity={restartActivity}
        pauseActivity={pauseActivity}
        order={order}
        downloadBoxLabels={downloadBoxLabels}
        print2DBarcodes={print2DBarcodes}
        download2DBarcodes={download2DBarcodes}
      />
    );
  }

  if (!appState?.tenant) {
    return (
      <div className="flex w-full justify-center">
        <div className="max-w-1000 mx-auto mb-8 mt-32 rounded-lg border-solid bg-white p-10">
          Loading...
        </div>
      </div>
    );
  }

  if (
    currentBatch &&
    currentBatch.status === "AWAITING_LABEL" &&
    order &&
    order.boxInformationNeeded !== false &&
    order.source === "FBA" &&
    order.typeOfShipment === "LTL" &&
    order.boxLabels &&
    !order.pallet
  ) {
    return (
      <AwaitingPalletInformation
        order={order}
        currentBatch={currentBatch}
        confirmDropoff={confirmDropoff}
        pack={true}
        printShippingLabel={printShippingLabel}
        subdomain={subdomain}
        confirmTransportInformation={confirmTransportInformation}
        transportLoading={transportLoading}
        printBoxLabels={printBoxLabels}
        submitPalletInformation={submitPalletInformation}
        restartActivity={restartActivity}
        pauseActivity={pauseActivity}
        downloadBoxLabels={downloadBoxLabels}
      />
    );
  }
  if (
    currentBatch &&
    currentBatch.status === "AWAITING_LABEL" &&
    order &&
    order.boxInformationNeeded !== false &&
    order.source === "FBA" &&
    order.typeOfShipment === "LTL" &&
    order.boxLabels &&
    order.pallet &&
    order.palletLabels
  ) {
    return (
      <AwaitingPalletLabel
        currentBatch={currentBatch}
        confirmDropoff={confirmDropoff}
        pack={true}
        printShippingLabel={printShippingLabel}
        subdomain={subdomain}
        confirmTransportInformation={confirmTransportInformation}
        transportLoading={transportLoading}
        printBoxLabels={printBoxLabels}
        restartActivity={restartActivity}
        pauseActivity={pauseActivity}
      />
    );
  }

  if (
    canShowRateShoppingScreen(currentBatch, order, appState) &&
    !isNewRateShoppingEnabled
  ) {
    return (
      <PackerRateShopping
        order={order}
        orderBatch={currentBatch}
        restartActivity={restartActivity}
        fetchSpecificOrder={fetchSpecificOrder}
        tenant={tenant}
        transportMode={transportMode}
      />
    );
  }

  if (
    currentBatch &&
    currentBatch.status === "AWAITING_LABEL" &&
    order &&
    order.boxInformationNeeded !== false &&
    (order.source === "FBA" ||
      tenant?.features?.boxSelection === true ||
      tenant?.features?.rateShopping === true)
  ) {
    return (
      <AwaitingPackingLabel
        currentBatch={currentBatch}
        confirmDropoff={confirmDropoff}
        pack={true}
        printShippingLabel={printShippingLabel}
        subdomain={subdomain}
        confirmTransportInformation={confirmTransportInformation}
        getFbaLabelGenerationStatus={getFbaLabelGenerationStatus}
        transportLoading={transportLoading}
        markOrderException={markOrderException}
        order={order}
        restartActivity={restartActivity}
        pauseActivity={pauseActivity}
        fbaLabelGenerationStatus={fbaLabelGenerationStatus}
      />
    );
  }

  if (
    currentBatch &&
    ["AWAITING_LABEL", "COMPLETED"].includes(currentBatch.status)
  ) {
    return (
      <CompletedBatch
        currentBatch={currentBatch}
        confirmDropoff={confirmDropoff}
        pack={true}
        printShippingLabel={printShippingLabel}
        subdomain={subdomain}
        restartActivity={restartActivity}
        pauseActivity={pauseActivity}
        printBoxLabels={printBoxLabels}
        order={order}
        tenant={tenant}
        reprintShippingLabel={reprintShippingLabel}
        printPackingLabels={printPackingLabels}
        packingLabelsEnabled={packingLabelsEnabled}
      />
    );
  }

  return (
    <>
      <div className="flex-col pt-4">
        <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
          <Tab.List className="flex items-center justify-center">
            <div className="space-x-2 rounded-full bg-primaryAccent p-2">
              {tabs &&
                Object.keys(tabs).map((tab) => (
                  <Tab
                    key={tab}
                    className={({ selected }) =>
                      classNames(
                        "font-regular rounded-full px-6 py-2.5 leading-5",
                        "z-50 font-montserrat text-lg focus:outline-none focus:ring-0",
                        selected
                          ? "bg-white font-medium text-primaryAccent"
                          : "rounded-full border border-primaryAccent bg-transparent text-white",
                      )
                    }>
                    {tab}
                  </Tab>
                ))}
            </div>
          </Tab.List>
          <div className="block">
            <Tab.Panels className="mt-2">
              {tabs &&
                Object.values(tabs).map((tabInner, idx) => (
                  <Tab.Panel
                    key={idx}
                    className="mt-4 flex flex-col items-center bg-transparent p-4">
                    {tabInner}
                  </Tab.Panel>
                ))}
            </Tab.Panels>
          </div>
        </Tab.Group>
      </div>
    </>
  );
};

export default PackerBatch;
