import { useState, useContext, useEffect } from "react";
import { AuthContext } from "#contexts/auth";
import { AppStateContext } from "#contexts/appState";
import { twMerge } from "tailwind-merge";
import { Tabs } from "antd";
import { GET_LIST_SUB_CATEGORIES_BY_PREFERENCES, GET_USERS } from "#queries";
import { UPDATE_NOTIFICATION_V1 } from "#mutations";
import { useQuery } from "#hooks/useQuery";
import AlertsNotifications from "#newUiComponents/notificationV2/AlertsNotifications";
import { capitalizeLabel } from "#utils/helper-functions";
import { useHistory } from "react-router-dom";
import { NotificationContext } from "#contexts/notification";

export const ALERT_VISIBILITY_IN_5000_MS = 5000;
export const NOTIFICATION_ALERTS_ENUM = {
  all: "ALL",
  order: "/orders",
  consignments: "/consignments",
  notificationV2: "notificationV2",
  orderId: "orderId",
  consignmentId: "consignmentId",
};
const customNotificationsAlertsTabs = [
  { key: "ALL", label: "All" },
  { key: "UNREAD", label: "Unread" },
];
const NotificationAlertsDashboard = ({ onClose, fetchNotifications }) => {
  const notification = useContext(NotificationContext);
  const appState = useContext(AppStateContext);
  const history = useHistory();
  const auth = useContext(AuthContext);
  const listSubcategoriesByPreferencesQuery = useQuery(
    GET_LIST_SUB_CATEGORIES_BY_PREFERENCES,
  );
  const updateNotificationsV1Query = useQuery(UPDATE_NOTIFICATION_V1);
  const allUsersQuery = useQuery(GET_USERS);
  const [selectedReportTab, setSelectedReportTab] = useState(
    customNotificationsAlertsTabs[0]["key"],
  );
  const [markAllAsRead, setMarkAllAsRead] = useState(false);
  const [notificationsAlertsTabs, setNotificationsAlertsTabs] = useState([]);
  const [notificationsAlertsList, setNotificationsAlertsList] = useState([]);
  const [allUsers, setAllUsers] = useState([]);

  useEffect(() => {
    getAllUsersList();
  }, []);

  const getAllUsersList = async () => {
    if (auth && auth.user && auth?.user?.role) {
      const getAllUsersResponse = await allUsersQuery.fetchData({
        filters: {
          all: true,
          role: [auth?.user?.role],
        },
        sort: "-createdAt",
      });
      if (getAllUsersResponse.error) {
        setAllUsers([]);
      } else if (getAllUsersResponse.data) {
        const users =
          getAllUsersResponse?.data?.users.entities
            .filter((user) => user.name !== null)
            .map((user) => {
              return {
                name: user?.name,
                id: user?.id,
                username: user?.username,
                email: user?.email,
              };
            }) ?? [];
        setAllUsers(users);
        getListSubcategoriesByPreferences(users);
      }
    }
  };

  const getListSubcategoriesByPreferences = async (users) => {
    appState.setLoading();
    const getListSubcategoriesByPreferencesResponse =
      await listSubcategoriesByPreferencesQuery.fetchData();
    appState.removeLoading();
    if (getListSubcategoriesByPreferencesResponse?.error) {
      setNotificationsAlertsTabs([...customNotificationsAlertsTabs]);
    } else if (getListSubcategoriesByPreferencesResponse?.data) {
      const subCategories =
        getListSubcategoriesByPreferencesResponse?.data?.listSubcategoriesByPreferences?.entities.map(
          (subCategory) => ({
            key: subCategory?.subCategory,
            label: capitalizeLabel(subCategory?.subCategory),
          }),
        ) ?? [];
      setNotificationsAlertsTabs([
        ...customNotificationsAlertsTabs,
        ...subCategories,
      ]);

      getNotificationsListBySubCategories(selectedReportTab, users);
    }
  };

  const getNotificationsListBySubCategories = async (
    selectedReportTab,
    users,
  ) => {
    if (selectedReportTab) {
      setNotificationsAlertsList([]);
      appState.setLoading();
      const getListNotificationsResponse = await fetchNotifications({
        filters: {
          subCategory: selectedReportTab,
        },
      });
      appState.removeLoading();
      if (getListNotificationsResponse?.error) {
        setNotificationsAlertsTabs([...customNotificationsAlertsTabs]);
      } else if (getListNotificationsResponse?.data) {
        const { notifications, hasUnread } = processNotifications(
          getListNotificationsResponse?.data?.listNotificationsV1?.entities,
          users,
        );
        setNotificationsAlertsList(notifications || []);
        setMarkAllAsRead(hasUnread);

        if (selectedReportTab === NOTIFICATION_ALERTS_ENUM?.all) {
          const unreadTotal = notifications.filter(
            (n) => n?.isRead === false,
          )?.length;
          notification.setEntities({
            entities: notifications,
            total: notifications?.length,
            unreadTotal,
            paginated: false,
            pageNumber: 0,
            notification: "notificationV2",
            markAsRead: false,
          });
        }
      }
    }
  };

  const fetchUpdateNotifications = async (payload) => {
    try {
      appState.setLoading();
      const response = payload
        ? await updateNotificationsV1Query.fetchData(payload)
        : { data: [], error: null };
      appState.removeLoading();

      return response;
    } catch (error) {
      appState.removeLoading();
      return { data: [], error };
    }
  };

  const updateNotificationsV1 = async (requestNotificationV1Input) => {
    if (requestNotificationV1Input) {
      try {
        appState.setLoading();
        const updateNotificationsV1Response = await fetchUpdateNotifications({
          updateNotificationsV1Input: requestNotificationV1Input,
        });
        appState.removeLoading();
        if (updateNotificationsV1Response.error) {
        } else if (updateNotificationsV1Response.data) {
          notification.setMarkAsRead({ markAsRead: true });
          getNotificationsListBySubCategories(selectedReportTab, allUsers);
        }
      } catch (error) {
        appState.removeLoading();
      }
    }
  };

  const handleLinkClick = async (path) => {
    if (path?.notificationId) {
      try {
        appState.setLoading();
        const requestNotificationV1Input = {
          id: path?.notificationId,
        };
        const updateNotificationsV1Response = await fetchUpdateNotifications({
          updateNotificationsV1Input: requestNotificationV1Input,
        });
        appState.removeLoading();
        if (updateNotificationsV1Response.error) {
        } else if (updateNotificationsV1Response.data) {
          notification.setMarkAsRead({ markAsRead: true });
          if (path) {
            if (
              path?.href === NOTIFICATION_ALERTS_ENUM?.order &&
              path?.mappingData?.id
            ) {
              const orderId = path?.mappingData?.id;
              history.push(path?.href, { orderId });
            } else if (
              path?.href === NOTIFICATION_ALERTS_ENUM?.consignments &&
              path?.mappingData?.id
            ) {
              const consignmentId = path?.mappingData?.id;
              history.push(path?.href, { consignmentId });
            } else {
              history.push(path?.href);
            }
            onClose();
          }
        }
      } catch (error) {
        appState.removeLoading();
      }
    }
  };

  const tabContents =
    notificationsAlertsTabs?.map((tab) => ({
      key: tab.key,
      label: (
        <span
          className={twMerge(
            `text-sm transition-colors duration-300 hover:text-primaryAccent ${
              selectedReportTab === tab.key
                ? "font-medium text-primaryAccent"
                : "font-light text-gray-500"
            }`,
          )}>
          {tab.label}
        </span>
      ),
      children: (
        <AlertsNotifications
          key={tab.key + JSON.stringify(selectedReportTab)}
          selectedTab={tab}
          onClose={onClose}
          notificationsAlertsList={notificationsAlertsList}
          markAllAsRead={markAllAsRead}
          updateNotificationsV1={updateNotificationsV1}
          handleLinkClick={handleLinkClick}
        />
      ),
    })) ?? [];

  useEffect(() => {
    if (
      selectedReportTab === NOTIFICATION_ALERTS_ENUM?.all &&
      notification?.notification === NOTIFICATION_ALERTS_ENUM?.notificationV2 &&
      allUsers?.length !== 0
    ) {
      const { notifications, hasUnread } = processNotifications(
        notification?.entities,
        allUsers,
      );
      setNotificationsAlertsList(notifications || []);
      setMarkAllAsRead(hasUnread);
    }
  }, [notification, allUsers]);

  const processNotifications = (entities, users) => {
    const notifications =
      entities?.map((item) => {
        const userId = item?.user;
        const user = users?.find((u) => u.id === userId);
        return {
          ...item,
          userId: userId,
          name: user?.name || null,
          email: user?.email || null,
          isRead: item?.isRead,
          href: item?.href,
          notificationId: item?.id,
        };
      }) ?? [];

    const hasUnread = notifications.some(
      (notification) => notification?.isRead === false,
    );

    return { notifications, hasUnread };
  };

  return (
    <div className="relative flex h-full flex-col overflow-y-auto overflow-x-hidden bg-white p-0 font-inter">
      <main>
        <Tabs
          size="large"
          tabBarStyle={{ borderBottom: "0px solid #224E73" }}
          onChange={(tabKey) => {
            setSelectedReportTab(tabKey);
            getNotificationsListBySubCategories(tabKey, allUsers);
          }}
          activeKey={selectedReportTab}
          items={tabContents}
        />
      </main>
    </div>
  );
};

export default NotificationAlertsDashboard;
