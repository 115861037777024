import _ from "lodash";
import {
  ExclamationIcon,
  TrashIcon,
  PrinterIcon,
} from "@heroicons/react/solid";
import SearchSortFilter from "#components/common/SearchSortFilter";
import { Menu } from "@headlessui/react";
import {
  DotsVerticalIcon,
  LinkIcon,
  PencilAltIcon,
  DownloadIcon,
  CollectionIcon,
  FolderIcon,
  InformationCircleIcon,
} from "@heroicons/react/outline";
import { Popover } from "@headlessui/react";
import { useContext, useEffect, useRef, useState } from "react";
import {
  getHeaderObject,
  getUpdatedHeaders,
} from "../../utils/getHeaderObject";
import { AuthContext } from "../../contexts/auth";
import CustomTableWithTheme from "../common/CustomTableWithTheme";
import cellStyleForTable from "../common/CellStyleForTable";
import QuickFilters from "../common/QuickFilters";
import ReactTooltip from "react-tooltip";
import SlideOverModal from "#components/common/SlideOverModal";
import LinkProduct from "./LinkProduct";
import { ProductAliasModal } from "./ProductAliasModal";
import { AppStateContext } from "#contexts/appState";
import { getHighlightedText } from "#utils/searchPanelUtils";
import CustomPopover from "#newUiComponents/commons/CustomPopover";
import useDebouncedEffect from "#hooks/useDebouncedEffect";
import SearchPanel from "#newUiComponents/commons/SearchPanel";
import { useFeatureFlags } from "#contexts/featureFlags";

const searchTabEnums = {
  sku: "SKU",
  asin: "ASIN",
  ean: "EAN",
  fnSku: "FNSKU",
  lpn: "LPN",
  name: "Name",
  upc: "UPC",
};

const payloadField = {
  sku: "sku",
  asin: "asin",
  ean: "ean",
  fnSku: "fnSku",
  lpn: "lpn",
  name: "name",
  upc: "upc",
};

const ORDER_DEBOUNCE_TIME_IN_MS = 500;
const PRODUCTS_TABLE_NAME = "productsManagement";

const ProductsList = ({
  products,
  noValuesText,
  getSku,
  onChangeSearchKeyword,
  filters,
  submitFilters,
  clearKeyword,
  setSort,
  sort,
  setShowFilters,
  clearFilters,
  getSpecificProduct,
  customers,
  onChangeFilter,
  writable,
  deleteProduct,
  multiAccountSupportEnabled,
  warehouses,
  printFnSkuLabels,
  downloadFnSkuLabels,
  productCategories,
  convertToBundle,
  tenant,
  parentProducts,
  selectedParentProduct,
  setSelectedParentProduct,
  getParentSku,
  getParentSkuForEdit,
  deleteParentProduct,
  perPage,
  searchFilters,
  fetchEnhancedSearchResults,
  searchProductsLoading,
  searchResults,
  setSearchResults,
  currentPage,
  setCurrentPage,
  setShowPrepInstructionsModal,
}) => {
  if (parentProducts.length > 0) {
    products = [
      ...parentProducts.map((i) => ({ ...i, isParentProduct: true })),
      ...products,
    ];
  }
  const [productBeingLinked, setProductBeingLinked] = useState(null);

  const appState = useContext(AppStateContext);
  const [showEnhancedSearch, setShowEnhancedSearch] = useState(false);
  const searchTabs = Object.entries(searchTabEnums).map((val) => ({
    id: val[0],
    name: val[1],
  }));
  const [activeTab, setActiveTab] = useState(searchTabs[0].id);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [selectedSources, setSelectedSources] = useState([]);
  const [selectedProductType, setSelectedProductType] = useState("");
  const [enhancedSearchText, setEnhancedSearchText] = useState("");

  const searchRef = useRef();

  const { enhancedSearchUx: isEnhancedSearchEnabled } = useFeatureFlags();

  useEffect(() => {
    function handleOutsideClick(event) {
      if (
        !searchRef ||
        !searchRef.current ||
        !searchRef.current.contains(event.target)
      ) {
        if (!event.target.closest('[role="dialog"]')) {
          setShowEnhancedSearch(false);
        }
      }
    }
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  // Close search popup on Esc key press
  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === "Escape") {
        setShowEnhancedSearch(false);
      }
    };

    document.addEventListener("keydown", handleEscape);
    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, []);

  const onChangeEnhancedSearchKeyword = (e) => {
    setEnhancedSearchText(e.target.value);
    setSearchResults({});

    setExactMatches([]);
    setNonExactMatches([]);
  };

  const clearEnhancedSearchKeyword = () => {
    setEnhancedSearchText("");
    setCurrentPage(0);
  };

  const getTableRow = (product) => {
    if (!product) return null;

    return (
      <div
        className="flex items-center justify-between border-b border-gray-300 pb-2 last:border-0"
        key={product?.productData?.id}>
        <div className="flex items-center gap-6">
          <div>
            <p className="flex gap-2">
              <a
                className="flex cursor-pointer items-center gap-2 font-medium text-gray-900"
                onClick={(e) => {
                  e.stopPropagation();
                  handleSkuClick(product?.productData);
                }}>
                {product?.productData?.typeOfProduct === "BUNDLE" && (
                  <BundleCubeIcon color="#111827" />
                )}
                {activeTab === payloadField.sku
                  ? getHighlightedText({
                      text: product?.productData?.sku,
                      activeTab: payloadField[activeTab],
                      searchHighlights: product?.searchHighlights,
                    })?.text
                  : product?.productData?.sku}
              </a>

              {activeTab !== payloadField.sku && (
                <span className="text-gray-400">|</span>
              )}

              {activeTab !== payloadField.sku && (
                <span className="text-gray-600">
                  {
                    getHighlightedText({
                      text: product?.productData[payloadField[activeTab]],
                      activeTab: payloadField[activeTab],
                      searchHighlights: product?.searchHighlights,
                    })?.text
                  }
                </span>
              )}
            </p>

            <p className="flex gap-2">
              <span className="text-gray-600">
                {customers &&
                  customers.find(
                    (customer) =>
                      customer.id === product?.productData?.customer,
                  )?.name}
              </span>

              <span className="text-gray-400">|</span>

              <span className="text-gray-600">
                {product?.productData?.source}
              </span>
            </p>
          </div>

          <div>{/* Status badge */}</div>
        </div>

        <div className="mr-4">
          <CustomPopover
            actions={menuItems(product?.productData).map((menuItem) => ({
              ...menuItem,
              key: menuItem.title,
              icon: null,
              name: menuItem.title,
              onClick: menuItem["onClick"],
            }))}
            styles={{ right: 0 }}
            triggerElement={
              <DotsVerticalIcon className="h-6 w-6 text-gray-500" />
            }
          />
        </div>
      </div>
    );
  };

  const searchPayload = (keyword, nextCursor) => {
    return {
      keyword: keyword?.trim(),
      field: payloadField[activeTab],
      filters: {
        customers: selectedCustomers?.map((customer) => customer.id),
        sources: selectedSources?.map((source) => source.id),
        typeOfProduct: selectedProductType,
      },
      nextCursor,
    };
  };

  useDebouncedEffect(
    () => fetchEnhancedSearchResults(searchPayload(enhancedSearchText)),
    ORDER_DEBOUNCE_TIME_IN_MS,
    [enhancedSearchText],
  );

  useEffect(() => {
    // on scroll
    fetchEnhancedSearchResults(
      searchPayload(enhancedSearchText, searchResults?.nextCursor),
    );
  }, [currentPage]);

  useEffect(() => {
    setSearchResults({});
    setCurrentPage(0);
    fetchEnhancedSearchResults(searchPayload(enhancedSearchText, null));
    setExactMatches([]);
    setNonExactMatches([]);
  }, [activeTab, selectedCustomers, selectedSources, selectedProductType]);

  const validScoreResults = searchResults?.entities
    ? [...searchResults.entities]?.filter((item) => item?.score > 0)
    : [];

  const [exactMatches, setExactMatches] = useState([]);
  const [nonExactMatches, setNonExactMatches] = useState([]);

  useEffect(() => {
    if (validScoreResults?.length > 0) {
      const latestSearchResults = validScoreResults
        .reverse()
        .slice(0, searchResults.count)
        .reverse();

      latestSearchResults.forEach((item) => {
        const textsFound =
          activeTab === payloadField.sku
            ? getHighlightedText({
                text: item?.productData?.sku,
                activeTab: payloadField[activeTab],
                searchHighlights: item?.searchHighlights,
              }).textsFound
            : getHighlightedText({
                text: item?.productData[payloadField[activeTab]],
                activeTab: payloadField[activeTab],
                searchHighlights: item?.searchHighlights,
              }).textsFound;

        const hitText = textsFound.find((text) => text.type === "hit");

        if (hitText.value.toLowerCase() === enhancedSearchText.toLowerCase()) {
          // exact match
          setExactMatches((prev) => [...prev, item]);
        } else {
          // non exact match
          setNonExactMatches((prev) => [...prev, item]);
        }
      });
    }
  }, [validScoreResults.length]);

  const [customerOpen, setCustomerOpen] = useState(false);
  const [sourceOpen, setSourceOpen] = useState(false);
  const [productTypeOpen, setProductTypeOpen] = useState(false);

  const filterProps = {
    Customer: {
      label: "FILTER BY CLIENT",
      selectedValues: selectedCustomers,
      setSelectedValues: setSelectedCustomers,
      placeholder: "Select Client",
      id: "customer",
      dropdownOpen: customerOpen,
      setDropdownOpen: setCustomerOpen,
      optionClasses: "text-lg",
    },
    Source: {
      label: "FILTER BY SOURCE",
      selectedValues: selectedSources,
      setSelectedValues: setSelectedSources,
      placeholder: "Select Source",
      id: "source",
      dropdownOpen: sourceOpen,
      setDropdownOpen: setSourceOpen,
      optionClasses: "text-lg",
    },
    "Product Type": {
      label: "FILTER BY PRODUCT TYPE",
      value: selectedProductType,
      setSelectedValues: setSelectedProductType,
      placeholder: "Select Product Type",
      id: "product-type",
      dropdownOpen: productTypeOpen,
      setDropdownOpen: setProductTypeOpen,
      optionClasses: "text-lg",
    },
  };

  const handleSearchFilterChange = ({ filterName, filterValue }) => {
    switch (filterName) {
      case "customer":
        const customerExists = selectedCustomers.find(
          (customer) => customer.id === filterValue.id,
        );

        if (customerExists) {
          setSelectedCustomers((customers) =>
            customers.filter((customer) => customer.id !== customerExists.id),
          );
        } else {
          setSelectedCustomers((customers) => [...customers, filterValue]);
        }
        break;

      case "source":
        const sourceExists = selectedSources.find(
          (source) => source.id === filterValue.id,
        );

        if (sourceExists) {
          setSelectedSources((sources) =>
            sources.filter((source) => source.id !== sourceExists.id),
          );
        } else {
          setSelectedSources((sources) => [...sources, filterValue]);
        }
        break;

      case "product-type":
        setSelectedProductType(filterValue);
        break;
    }
  };

  const menuItems = (product) => {
    const arr = [
      {
        title: "Edit",
        icon: PencilAltIcon,
        onClick: () =>
          product?.isParentProduct
            ? getParentSkuForEdit(product.id)
            : getSpecificProduct(product.id),
      },
      {
        title: "Delete",
        icon: TrashIcon,
        onClick: () =>
          product?.isParentProduct
            ? deleteParentProduct(product)
            : deleteProduct(product),
      },
    ];

    if (product?.isParentProduct === true) {
      return arr;
    }

    arr.push(
      {
        title: "Print Labels",
        icon: PrinterIcon,
        onClick: () => printFnSkuLabels(product.id),
      },
      {
        title: "Download Labels",
        icon: DownloadIcon,
        onClick: () => downloadFnSkuLabels(product.id),
      },
    );

    if (product.typeOfProduct !== "BUNDLE") {
      arr.push({
        title: "Convert to Bundle",
        icon: CollectionIcon,
        onClick: () => convertToBundle(product),
      });
      if (tenant?.settings?.isProductLinkageAllowed === true) {
        arr.push({
          title: "Link Products",
          icon: LinkIcon,
          onClick: () => setProductBeingLinked(product),
        });
      }
    }

    if (product.source === "FBA") {
      arr.push({
        title: "Prep Instructions",
        icon: InformationCircleIcon,
        onClick: () => {
          setShowPrepInstructionsModal(true);
          product?.isParentProduct
            ? getParentSkuForEdit(product.id)
            : getSpecificProduct(product.id);
        },
      });
    }

    return arr;
  };
  const prepCenter =
    warehouses?.findIndex(
      (item) =>
        item.typeOfWarehouse && item.typeOfWarehouse.includes("PREP CENTER"),
    ) !== -1;

  const initialHeaders = [
    getHeaderObject("SKU", "sku"),
    getHeaderObject("Name", "name"),
    getHeaderObject("Description", "description"),
    getHeaderObject("Source", "source"),
    getHeaderObject("Client", "client"),
    getHeaderObject("Action", "action"),
    getHeaderObject("UPC", "upc"),
    getHeaderObject("Category", "category"),
  ];

  if (prepCenter) {
    initialHeaders.push(getHeaderObject("FnSKU", "fnSku"));
  }

  const [headers, setHeaders] = useState(initialHeaders);
  const [showHeaders, setShowHeaders] = useState(headers);
  const auth = useContext(AuthContext);
  useEffect(() => {
    if (multiAccountSupportEnabled) {
      const MULTI_REGION_HEADERS_INDEX = 3;
      setHeaders([
        ...headers.slice(0, MULTI_REGION_HEADERS_INDEX),
        getHeaderObject("Marketplace", "marketplace"),
        getHeaderObject("Seller", "seller"),
        ...headers.slice(MULTI_REGION_HEADERS_INDEX),
      ]);
    }
  }, [multiAccountSupportEnabled]);

  useEffect(() => {
    const updatedHeaders = getUpdatedHeaders(
      auth,
      headers,
      PRODUCTS_TABLE_NAME,
    );
    if (updatedHeaders) {
      setHeaders(updatedHeaders);
      setShowHeaders(updatedHeaders.filter((header) => header.enabled));
    }
  }, [auth.user]);

  const renderExclamationIcon = (product) => {
    if (product.source === "FBA" && product.partOfBundle === false) {
      if (
        !product.asin ||
        !product.fnSku ||
        product.asin.trim() === "" ||
        product.fnSku.trim() === ""
      ) {
        return (
          <>
            <Popover className="relative bg-white">
              <Popover.Button>
                <ExclamationIcon className="text-xs text-red-600" width={30} />
              </Popover.Button>

              <Popover.Panel className="absolute z-50 bg-white">
                <div className="w-48 border border-gray-600 bg-white p-4">
                  {(!product.asin || product.asin.trim() === "") && (
                    <div>ASIN is missing</div>
                  )}
                  {(!product.fnSku || product.fnSku.trim() === "") && (
                    <div>FNSKU is missing</div>
                  )}
                </div>

                <img src="/solutions.jpg" alt="" />
              </Popover.Panel>
            </Popover>
          </>
        );
      }
    }
    if (product.source === "FBM" && product.partOfBundle === false) {
      if (!product.asin || product.asin.trim() === "") {
        return (
          <Popover className="relative bg-white">
            <Popover.Button>
              <ExclamationIcon className="text-xs text-red-600" width={30} />
            </Popover.Button>

            <Popover.Panel className="absolute z-50 bg-white">
              <div className="w-48 border border-gray-600 bg-white p-4">
                {(!product.asin || product.asin.trim() === "") && (
                  <div>ASIN is missing</div>
                )}
              </div>

              <img src="/solutions.jpg" alt="" />
            </Popover.Panel>
          </Popover>
        );
      }
    }

    return null;
  };

  const handleSkuClick = (product) => {
    if (!product?.isParentProduct) {
      getSku(product.id);
    } else {
      getParentSku(product.id);
    }
  };

  const renderVariantsTable = (headersToUse, productsToUse) => {
    return (
      <CustomTableWithTheme>
        <thead className="sticky left-0 top-0 rounded-full bg-primaryAccent p-4">
          <tr className="font-montserratborder-left text-textWhite">
            {headersToUse.map((header, headerIdx) =>
              headerIdx === 0 ? (
                <th
                  scope="col"
                  className="px-1 py-3 pl-4 text-left font-medium tracking-wider"
                  key={headerIdx}>
                  {header.name}
                </th>
              ) : (
                <th
                  scope="col"
                  className="px-1 py-3 pl-4 text-left font-medium tracking-wider"
                  key={headerIdx}>
                  {header.name}
                </th>
              ),
            )}
          </tr>
        </thead>
        <tbody>
          {productsToUse && productsToUse.length === 0 ? (
            <tr className="bg-white">
              {showHeaders.map((header, headerIdx) =>
                headerIdx === 0 ? (
                  <td
                    className="tracking-widerrounded-tl rounded-bl border-l-8 border-goldenSand p-5 text-left font-semibold text-primaryAccent"
                    key={headerIdx}>
                    {noValuesText}
                  </td>
                ) : (
                  <td
                    className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B"
                    key={headerIdx}></td>
                ),
              )}
            </tr>
          ) : null}
          {productsToUse &&
            productsToUse.map((product, rowIndex) => (
              <tr
                key={rowIndex}
                className={`${
                  product.active === false
                    ? "bg-red-300"
                    : `${rowIndex % 2 === 0 ? "bg-white" : "bg-gray-100"}`
                }`}>
                {showHeaders.map((header, columnIndex) => {
                  let value = header.correspondingValue;
                  let cellStyle = cellStyleForTable(
                    value,
                    ["sku"],
                    columnIndex,
                    rowIndex,
                  );
                  if (value === "sku") {
                    return (
                      <td className={cellStyle}>
                        {renderExclamationIcon(product)}
                        <div className="flex items-center gap-x-2">
                          {product.isParentProduct && (
                            <div
                              className="h-6 w-6 text-primaryAccent"
                              title={`Product with variants`}>
                              <FolderIcon />
                            </div>
                          )}
                          {product.typeOfProduct === "BUNDLE" && (
                            <BundleCubeIcon />
                          )}

                          <div onClick={() => handleSkuClick(product)}>
                            {product.sku}
                          </div>
                          <ProductAliasModal
                            aliases={product?.aliases}
                            customers={customers}
                          />
                        </div>
                      </td>
                    );
                  }
                  if (value === "name") {
                    return (
                      <td className={cellStyle}>{truncate(product.name)} </td>
                    );
                  }
                  if (value === "fnSku") {
                    return (
                      <td className={cellStyle}>{truncate(product?.fnSku)}</td>
                    );
                  }
                  if (value === "upc") {
                    return (
                      <td className={cellStyle}>
                        {truncate(product?.upc?.join(", "))}
                      </td>
                    );
                  }
                  if (value === "category") {
                    return (
                      <td className={cellStyle}>
                        {product.category
                          ? productCategories?.find(
                              (i) => i.id === product.category,
                            )?.name || "-"
                          : "-"}
                      </td>
                    );
                  }
                  if (value === "description") {
                    return (
                      <td
                        className={cellStyle}
                        data-tip={product.description}
                        data-for={product.id}>
                        {truncate(product.description)}
                        {product.description &&
                          product.description.length > 40 && (
                            <ReactTooltip
                              id={product.id}
                              place="top"
                              type="info"
                              effect="solid">
                              <div className="h-fit max-w-[400px] whitespace-pre-line">
                                {product.description
                                  ? product.description
                                  : null}
                              </div>
                            </ReactTooltip>
                          )}
                      </td>
                    );
                  }

                  if (value === "marketplace") {
                    return (
                      <td className={cellStyle}>
                        {
                          product.marketplaceAttributes?.attributes
                            ?.marketplaceCountryCode
                        }
                      </td>
                    );
                  }

                  if (value === "seller") {
                    return (
                      <td className={cellStyle}>
                        {product.marketplaceAttributes?.attributes?.sellerId}
                      </td>
                    );
                  }

                  if (value === "source") {
                    return <td className={cellStyle}>{product.source}</td>;
                  }
                  if (value === "client") {
                    return (
                      <td className={cellStyle}>
                        {customers &&
                          customers.find((item) => item.id === product.customer)
                            ?.name}
                      </td>
                    );
                  }
                  if (value === "action") {
                    const isLastRow = rowIndex === perPage - 1;
                    const isLastColumn = columnIndex === showHeaders.length - 1;

                    return (
                      <td className={cellStyle}>
                        {writable && (
                          <Menu as="div" className="inline-block text-left">
                            <div>
                              <Menu.Button>
                                <DotsVerticalIcon className="h-8 w-8 rounded bg-actionMenuBG p-2 text-base" />
                              </Menu.Button>
                            </div>
                            <Menu.Items
                              className={`${isLastRow && "bottom-0"} ${isLastColumn && "right-0"} absolute z-10 divide-y divide-gray-100 rounded-md bg-primaryAccent shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}>
                              <div className="">
                                {menuItems(product).map((menuItem, idx) => (
                                  <Menu.Item key={idx}>
                                    {({ active }) => (
                                      <button
                                        className={`relative flex w-full cursor-pointer select-none items-center border-b border-50BFC3 py-4 pl-4 pr-4 text-white hover:bg-EBEBEB hover:text-2C7695`}
                                        onClick={menuItem["onClick"]}>
                                        {menuItem.icon && (
                                          <menuItem.icon className="h-8 w-8 pr-2" />
                                        )}
                                        {menuItem.title}
                                      </button>
                                    )}
                                  </Menu.Item>
                                ))}
                              </div>
                            </Menu.Items>
                          </Menu>
                        )}
                      </td>
                    );
                  }
                })}
              </tr>
            ))}
        </tbody>
        <SlideOverModal
          width="w-1/2"
          open={!!productBeingLinked}
          onClose={() => setProductBeingLinked(null)}
          title={"Link/ De Link Product"}
          slideOverPaddingClass="p-4 pb-2"
          staticBackdrop={true}>
          <LinkProduct
            product={productBeingLinked}
            customers={customers}
            onClose={() => {
              setProductBeingLinked(null);
            }}
          />
        </SlideOverModal>
      </CustomTableWithTheme>
    );
  };

  const productSearchDropdownChoices = [
    {
      name: "Added Date",
      value: "createdAt",
    },
    {
      name: "SKU",
      value: "sku",
    },
    {
      name: "Name",
      value: "name",
    },
  ];

  if (selectedParentProduct) {
    return (
      <div>
        {/* Add a button to go back to the parent product */}
        <button
          className="mb-4 text-xl font-bold text-primaryAccent transition-transform duration-300 ease-in-out hover:scale-105"
          onClick={() => setSelectedParentProduct(null)}>
          {"< Back"}
        </button>
        <div className="min-w-max rounded-xl border border-gray-300 bg-E2E2E2 px-4 pb-20 transition-all duration-300 ease-in-out">
          {renderVariantsTable(showHeaders, selectedParentProduct.variants)}
        </div>
      </div>
    );
  }

  return (
    <div className="h-full w-full rounded-xl border border-gray-300 bg-E2E2E2 px-4 pb-20">
      {customers.length > 1 && (
        <QuickFilters
          customerFilterName={"customer"}
          customers={customers}
          filters={filters}
          onChangeFilter={onChangeFilter}
        />
      )}

      <div className="relative" ref={searchRef}>
        <SearchSortFilter
          onChangeSearchKeyword={
            isEnhancedSearchEnabled
              ? onChangeEnhancedSearchKeyword
              : onChangeSearchKeyword
          }
          value={
            isEnhancedSearchEnabled ? enhancedSearchText : filters["keyword"]
          }
          filters={filters}
          submitFilters={isEnhancedSearchEnabled ? null : submitFilters}
          clearKeyword={
            isEnhancedSearchEnabled ? clearEnhancedSearchKeyword : clearKeyword
          }
          onFocus={() => setShowEnhancedSearch(true)}
          setSort={setSort}
          sort={sort}
          setShowFilters={setShowFilters}
          clearFilters={clearFilters}
          tableName={PRODUCTS_TABLE_NAME}
          headers={headers}
          setShowHeaders={setShowHeaders}
          choices={productSearchDropdownChoices}
        />
        {isEnhancedSearchEnabled && showEnhancedSearch ? (
          <div className="absolute top-14 z-10 w-full rounded-md border-2 border-gray-300 bg-[#FEFEFE] p-5 pb-0">
            <SearchPanel
              filters={[
                ...searchFilters
                  .filter((filter) => filter?.data?.length > 0)
                  .map((filter) => ({
                    ...filter,
                    ...filterProps[filter.displayLabel],
                  })),
              ]}
              tabs={searchTabs}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              onChangeFilter={handleSearchFilterChange}
              dataObject={{
                exactMatches,
                nonExactMatches,
              }}
              rowRender={(order) => getTableRow(order)}
              isLoading={searchProductsLoading}
              totalResults={searchResults?.total}
              setCurrentPage={setCurrentPage}
              nextCursor={
                searchResults.entities?.find((item) => item?.score === 0)
                  ? null
                  : searchResults?.nextCursor
              }
              emptySearchText={
                <>
                  <p className="font-semibold">
                    {!searchProductsLoading &&
                    enhancedSearchText &&
                    !validScoreResults?.length
                      ? "No results found"
                      : "Search Criteria Not Entered"}
                  </p>
                  <p className="text-sm text-gray-400">
                    Please type SKU, ASIN, LPN or more to search
                  </p>
                </>
              }
            />
          </div>
        ) : null}
      </div>

      <div className="relative w-full overflow-x-auto">
        <div className="inline-block min-w-full align-middle">
          {renderVariantsTable(showHeaders, products)}
        </div>
      </div>
    </div>
  );
};

const truncate = (str) => {
  return str && str.length > 40 ? str.substring(0, 40) + "..." : str;
};

export const BundleCubeIcon = ({ color }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <title>Bundle</title>
      <path
        d="M20 7L12 3L4 7M20 7L12 11M20 7V17L12 21M12 11L4 7M12 11V21M4 7V17L12 21"
        stroke={color || "#224E72"}
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ProductsList;
