import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from "react";
import CheckBox from "./CheckBox";
import { twMerge } from "tailwind-merge";
import {
  ChevronUpIcon,
  ChevronDownIcon,
  XIcon,
} from "@heroicons/react/outline";
import { Transition } from "@headlessui/react";
import PropTypes from "prop-types";

const AutoCompleteMultiSelect = ({
  label,
  options,
  selectedValues,
  setSelectedValues = () => {},
  placeholder,
  labelClasses,
  selectClasses,
  optionClasses,
  multiSelect = "tag",
  maxTagCount = 1,
  dropdownOpen,
  setDropdownOpen,
  onChange,
  fetchOptions,
  transformData = (data) => data,
  placeholderDropdown = "relative mb-5",
  placeholderBackground = "",
  placeholderText = "text-gray-400",
  noOptionsFoundText = "No options found",
}) => {
  const dropdownRef = useRef(null);
  const [searchValue, setSearchValue] = useState("");
  const [dynamicOptions, setDynamicOptions] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const filteredOptions = useMemo(() => {
    if (searchValue) {
      return options.filter((option) => {
        const nameValue = React.isValidElement(option?.name)
          ? option.uniqueIdentifier
          : option.name;

        return nameValue?.toLowerCase().includes(searchValue?.toLowerCase());
      });
    }
    return options;
  }, [searchValue, options]);
  const mergedOptions = useMemo(
    () => [...filteredOptions, ...dynamicOptions],
    [filteredOptions, dynamicOptions],
  );
  const handleOptionClick = useCallback(
    (option) => {
      if (onChange) onChange(option);
      else {
        const exists = selectedValues.find((item) => item.id === option.id);
        if (exists) {
          setSelectedValues((prev) =>
            prev.filter((item) => item.id !== option.id),
          );
        } else {
          setSelectedValues((prev) => [
            ...prev,
            {
              name: option.name,
              id: option.id,
              uniqueIdentifier: option.uniqueIdentifier,
            },
          ]);
        }
      }
    },
    [selectedValues, setSelectedValues],
  );
  const handleDeselect = useCallback(
    (id) => {
      setSelectedValues((prev) => prev.filter((item) => item?.id !== id));
    },
    [setSelectedValues],
  );
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    const fetchDynamicOptions = async () => {
      if (!fetchOptions || !searchValue) {
        setDynamicOptions([]);
        return;
      }
      setIsFetching(true);
      try {
        const fetchedData = await fetchOptions(searchValue);

        const transformedData = transformData(fetchedData);
        setDynamicOptions(transformedData);
      } catch (error) {
        console.error("Error fetching dynamic options:", error);
        setDynamicOptions([]);
      } finally {
        setIsFetching(false);
      }
    };
    fetchDynamicOptions();
  }, [searchValue]);
  const toggleDropdown = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setDropdownOpen((prev) => !prev);
  };

  const renderSelectedValues = () => {
    if (multiSelect === "count") {
      return (
        <span className="text-gray-600">{`${selectedValues.length} selected`}</span>
      );
    }

    if (multiSelect === "tag") {
      if (!maxTagCount || selectedValues.length <= maxTagCount) {
        return selectedValues.map((item) => (
          <span
            key={item.id}
            className="flex items-center gap-1 rounded-full bg-primaryAccent px-2 py-1 text-sm text-white">
            {item.name}
            <button
              className="text-xs"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                handleDeselect(item.id);
              }}>
              <XIcon className="h-5 w-5 text-white" />
            </button>
          </span>
        ));
      } else {
        const visibleTags = selectedValues.slice(0, maxTagCount);
        const hiddenTagCount = selectedValues.length - maxTagCount;
        return (
          <>
            {visibleTags.map((item) => (
              <span
                key={item.id}
                className="flex items-center gap-1 rounded-full bg-primaryAccent px-2 py-1 text-sm text-white">
                {item.name}
                <button
                  className="text-xs"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    handleDeselect(item.id);
                  }}>
                  <XIcon className="h-5 w-5 text-white" />
                </button>
              </span>
            ))}
            <span className="rounded-full bg-primaryAccent px-2 py-1 text-sm text-white">{`+${hiddenTagCount}`}</span>
          </>
        );
      }
    }

    return null;
  };

  return (
    <div className={placeholderDropdown} ref={dropdownRef}>
      {label && (
        <p
          className={twMerge(
            `mb-3 text-xs font-medium text-primaryAccent ${labelClasses}`,
          )}>
          {label}
        </p>
      )}

      <div
        className={twMerge(
          "relative flex cursor-pointer items-center justify-between rounded border border-gray-300 px-3 py-2 focus:border-primaryAccent focus:outline-none focus:ring-primaryAccent",
          selectClasses,
          placeholderBackground,
        )}
        tabIndex={0}
        onClick={toggleDropdown}>
        <div className="flex flex-wrap items-center gap-2">
          {selectedValues?.length > 0 ? (
            renderSelectedValues()
          ) : (
            <span className={placeholderText}>{placeholder}</span>
          )}
        </div>
        <span>
          {dropdownOpen ? (
            <ChevronUpIcon className="h-5 w-5 text-gray-500" />
          ) : (
            <ChevronDownIcon className="h-5 w-5 text-gray-500" />
          )}
        </span>
      </div>

      <Transition
        show={dropdownOpen}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
        className="absolute z-10 mt-2 w-full rounded border border-gray-300 bg-white shadow-lg">
        <div className="relative px-2 py-4">
          <div className="relative">
            <input
              type="text"
              placeholder="Search..."
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              className="w-full rounded border border-gray-300 p-2 pr-8"
            />
            {searchValue && (
              <button
                type="button"
                onClick={() => setSearchValue("")}
                className="absolute right-2 top-1/2 -translate-y-1/2 transform text-gray-500 hover:text-gray-700">
                <XIcon className="h-5 w-5" />
              </button>
            )}
          </div>
        </div>
        <div className="max-h-60 overflow-auto">
          {isFetching ? (
            <div className="px-4 py-2 text-gray-500">Loading...</div>
          ) : mergedOptions.length > 0 ? (
            mergedOptions.map((option) => {
              if (!option.id || !option.name) return;
              return (
                <div
                  key={option.id}
                  className="flex cursor-pointer items-center px-4 py-2 hover:bg-gray-100"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!React.isValidElement(option?.name)) {
                      e.preventDefault();
                    }
                    handleOptionClick(option);
                  }}>
                  <CheckBox
                    checked={
                      !!selectedValues?.find((item) => item?.id === option?.id)
                    }
                    readOnly={true}
                    style={{ marginRight: "10px" }}
                  />
                  <span className={optionClasses}>{option?.name}</span>
                </div>
              );
            })
          ) : (
            <div className="px-4 py-2 text-gray-500">{noOptionsFoundText}</div>
          )}
        </div>
      </Transition>
    </div>
  );
};

export default AutoCompleteMultiSelect;

AutoCompleteMultiSelect.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  selectedValues: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  setSelectedValues: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  labelClasses: PropTypes.string,
  selectClasses: PropTypes.string,
  optionClasses: PropTypes.string,
  multiSelect: PropTypes.oneOf(["tag", "count"]),
  maxTagCount: PropTypes.number,
  dropdownOpen: PropTypes.bool.isRequired,
  setDropdownOpen: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  fetchOptions: PropTypes.func,
  transformData: PropTypes.func,
};
// Default props
AutoCompleteMultiSelect.defaultProps = {
  placeholder: "Select an option",
  labelClasses: "",
  selectClasses: "",
  optionClasses: "",
  multiSelect: "tag",
  maxTagCount: 1,
};
